import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {  
  const { pathname } = useLocation();
  console.log('In hook', pathname);
   
  useEffect(() => {        
        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        //   });                        
        let el = document.querySelector('.contentClass');        
        el.scrollLeft = 0;
        el.scrollTop = 0;                
  }, [pathname]);

  return null;
}
  