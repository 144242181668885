import React from 'react';

const PrivacyContent = () => {    
        return(            
        <section className="section-footer-pages">
            <div className="row mt-20">
                <h3 className="brandon-medium text-left">Privacy Policy</h3>
                <p>This policy is one way of sustaining your trust in Loanpal, LLC, our products and services.  Our privacy policy describes how we collect, protect, and use information about you during your visit on our website.</p>
                <p>At all times, we reserve the right to disclose any information, including personally identifiable information, to comply with any applicable law, regulation, legal process or governmental request; to protect Loanpal rights or property (including without limitation in the event of a transfer of control of a Loanpal company, affiliate, or brand, or substantially all of its assets), or during an emergency when safety is at risk, or for credit fraud protection and risk reduction purposes.</p>
                <h3 className="brandon-medium mt-20">California Privacy Rights Notice</h3>
                <p>This California Privacy Rights Notice supplements the information contained in this Privacy Policy and applies to California residents ("consumers” or "you”).   If you are a California resident, then you have certain additional rights under the California Consumer Privacy Act ("CCPA”) regarding Personal Information.</p>
                <div className="brandon-reular mt-20 text-gray button" style={{width: '400px', margin: '0 auto'}}>
                    <a className="btn btn-full uppercase view-more" 
                        href='/privacy-policy-california'
                        data-height="540"
                        data-width="960">California Privacy Rights</a>
                </div> 
                <p className="brandon-medium mt-20">Information Collection and Use</p>
                <p>We collect information about you to help us serve your financial needs, to provide you with quality products and services, and to fulfill legal and regulatory requirements.  We consider all information about you in our possession to be personal information, even if you cease to be a customer.  Typically, we collect this information on applications and other forms you complete, through conversations you may have with our loan professionals, with other representatives, and in some cases, over our website.  We may also collect information from a wide range of other sources in order to process and/or underwrite your loan application.  These sources may include among others, employers, attorneys, banks, title insurers, insurance companies, and credit reporting agencies.  This information may include your name, address, phone number, email address, social security number, and account numbers.</p>
                <p className="mt-10">We do not sell or rent customer information.  We share customer information with certain employees, and with companies providing services on our behalf in order to service your needs.  Our policy is to require all employees and companies providing services on our behalf, to keep customer information confidential.  Our privacy policy applies to potential customers, as well as current and former customers.</p>
                <p className="mt-10">We may also collect, store or accumulate certain non-personally identifiable information concerning your use of this website, such as information regarding which of our pages are most popular.  Information gathered may be used in aggregate form for internal business purposes, such as generating statistics and developing marketing plans.  We may share or transfer such aggregate, non-personally identifiable information with or to our affiliates.</p>
                <p className="mt-10">When you fill-out a form on our website, we capture your IP-address.  This enables us to track and prevent fraudulent attacks against our website as a security precaution and may be used to prevent fraudulent applications.  We do not use your IP-address to contact you.</p>
                <p className="brandon-medium mt-20">Special Note to Parents</p>
                <p>This website is intended for adults.  We do not knowingly collect personal information from children under the age of 13.  However, if the parent or guardian of a child under 13 believes that the child has provided us with personally identifiable information, the parent or guardian of that child should contact us immediately at <a href="mailto:privacy@loanpal.com">privacy@loanpal.com</a> if they want this information deleted from our files, so that it is not in retrievable form.  If we otherwise obtain knowledge that we have personally identifiable information about a child under 13 in retrievable form in our files, we will delete the information from our existing files so that it is not retrievable.</p>
                <p className="brandon-medium mt-20">Ensuring Your Security</p>
                <p>We have adopted policies and procedures designed to protect your personal information from unauthorized use or disclosure.</p>
                <p>We have implemented physical, electronic, and procedural safeguards to maintain confidentiality and integrity of the personal information in our possession, and to guard against unauthorized access.  These include among other things, procedures for controlling access to customer files, building security programs, and information technology security measures such as the use of passwords, firewall, plus virus and use detection software.  We continue to access new technology as it becomes available, and to upgrade our physical and electronic security systems as appropriate.</p>
                <p>Our policy is to permit employees to access your personal information only if they have a business purpose for using such information, such as administering, providing or developing our products or services.  Our policy governs the conduct of all of our employees and third-party vendors, to safeguard personal information about consumers, and customers we serve or have served in the past.</p>
                <p className="brandon-medium mt-20">Sharing Information With Companies That Provide Services For Us</p>
                <p>We share personal information about you, as required or permitted by law, with affiliates and third-parties such as, service providers who assist us in the day-to-day operations of our company in the administration, processing, servicing, and sale of your loan.  These affiliates and third-parties include among others, title companies, appraisers, insurance companies, underwriting services, processing services, printing companies, software providers, marketing services, and purchasers of loans.  Our policy is to require affiliates and third-party service providers to enter into confidentiality agreements with us, prohibiting them from using any personal information they obtain for any other purpose, other than those for which they were retained or as required by law.  We may also disclose information about you, when necessary or required, in legal and arbitration proceedings, and to government agencies.</p>
                <p className="brandon-medium mt-20">Technology Utilized</p>
                <p>There are two commonly used technologies used on this site, clear gifs and cookies.  We use these to monitor the performance and effectiveness of the site.  "Cookies” are small pieces of information stored by our browser on your computer’s hard drive.  We do not use cookies to obtain any personally identifying information.  Most web browsers automatically accept cookies, but you can usually change your browser settings to prevent this.  Please be assured that accepting a cookie does not give us access to your computer or personal information under any circumstances.  If you disable cookies, you may be unable to use some features of our site.  If you experience any problems with our website, please click here "<a href="bugs.html">Report a Bug</a>”, and report the issue encountered.</p>
                <p>Clear gifs are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users.  Clear gifs are not tied to users’ personally identifiable information.</p>
                <p className="brandon-medium mt-20">Notification of Changes</p>
                <p>We may revise this Privacy Policy from time to time.  If we decide to change our Privacy Policy, we will post the revised policy here.  As we may make changes at any time without notifying you, we suggest that you periodically consult this Privacy Policy.</p>
                <p className="brandon-medium mt-20">Your Consent</p>
                <p>Your continued participation on this website indicates your acceptance of this Privacy Policy, and of the collection, use, disclosure, management, and storage of your personal information as described above.  We may from time-to-time, transfer or merge any personal information collected offline to our online databases or store offline information in an electronic format.  We may also combine personal information we collect online with information available from other sources.</p>
                <p className="brandon-medium mt-20">Contacting Us</p>
                <p>If you have any questions about this privacy policy, please contact us by mail at:</p>
                <p className="mt-20">Loanpal<br />
                    Attn: Compliance Department<br />
                    8781 Sierra College Blvd.<br />
                    Roseville, CA 95661<br />
                </p>
                <p className="brandon-medium mt-20">Effective Date of this Policy:</p>
                <p className="mb-40">This policy was last revised on 5/11/2020 and takes effect immediately, and is in effect until further revised.</p>
            </div>
        </section>            
    )    
}

export default PrivacyContent;