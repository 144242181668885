import React from 'react';
import BioRow from './components/bio-row';

class BiosMobile extends React.Component {
  render() {
    const { data: { bios } } = this.props;
    console.log(bios);
    return (
      <div id="bios-mobile" className="mb-30">
        <BioRow
          bio={bios[0]}
        />
        <BioRow
          bio={bios[2]}
        />
        <BioRow
          bio={bios[3]}
        />
        <BioRow
          bio={bios[10]}
        />
        <BioRow
          bio={bios[6]}
        />
        <BioRow
          bio={bios[11]}
        />
        <BioRow
          bio={bios[5]}
        />
        <BioRow
          bio={bios[4]}
        />
        <BioRow
          bio={bios[7]}
        />
        <BioRow
          bio={bios[9]}
        />
        <BioRow
          bio={bios[8]}
        />
      </div>
    );
  }
}

export default BiosMobile;
