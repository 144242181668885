import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import backgroundImage from "images/warriors-of-light/WoL_web_checker_banner_image_logo2-min.jpg";
import backgroundImageT from "images/warriors-of-light/WoL_web_checker_banner_image_logo2-min.jpg";
import backgroundImageM from "images/warriors-of-light/WoL_web_checker_banner_image_logo2@640-min.jpg";
import smiley from "images/smiley-text-gray.svg";

import { Typography, Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";

import { isBrowser, isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  mainContainerRoot: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-110px",
      paddingTop: "110px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "-150px",
      paddingTop: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "-150px",
      paddingTop: "150px",
    },
  },
  gridRoot: {
    backgroundColor: "#ebebeb",
  },
  root: {
    position: "relative",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0px",
    fontFamily: "brandon-grotesque",
    backgroundColor: "#ebebeb",
    [theme.breakpoints.down("sm")]: {
      minHeight: "225px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "463px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  image: {
    background: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundColor: "#ffffff",
    backgroundRepeat: "no-repeat",
    height: "578px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${backgroundImageM})`,
      height: "225px",
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundImageT})`,
      height: "463px",
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      background: `url(${backgroundImage})`,
      height: "578px",
      marginBottom: "0",
    },
  },
  intro: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "14px",
    },
  },
  borderLeft: {
    width: "30%",
    borderBottom: "3px solid #717271",
    position: "relative",
    top: "-28px",
    left: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "0%",
      borderBottom: "1px solid transparent",
    },
  },
  borderRight: {
    width: "30%",
    borderBottom: "3px solid #717271",
    position: "relative",
    top: "-28px",
    left: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "0%",
      borderBottom: "1px solid transparent",
    },
  },
  text: {
    fontSize: "100%",
    color: "#717271",
    position: "relative",
    top: "-10%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  smileyText: {
    height: "auto",
    width: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "18px",
      margin: "0 3px 0 0px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "36px",
      margin: "0 2px 0 0px",
    },
    [theme.breakpoints.up("md")]: {
      width: "30px",
      margin: "0 9px 0 0px",
    },
  },
  introText: {
    font: "normal normal 700 24px/20px brandon-grotesque",
    wordSpacing: "2px",
    textAlign: "center",
    letterSpacing: "2px",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 700 20px/29px brandon-grotesque",
      borderBottom: "1px solid #717271",
      marginBottom: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 700 22px/28px brandon-grotesque",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 700 24px/20px brandon-grotesque",
    },
  },
  gridText: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "400px",
    },
  },
  paragraph: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    // top: '-50px'
    margin: "10px 0 60px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0 10px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "24px 0 16px 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "24px 0 16px 0",
    },
  },
  paragraphTextShow: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  paragraphText: {
    padding: "40px 12%",
    // textShadow: '3px 0px 10px rgba(0, 0, 0, 0.3)',
    color: "#717271",
    font: "normal normal 400 24px/35px brandon-grotesque",
    wordSpacing: "1px",
    textAlign: "justify",
    letterSpacing: ".5px",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 400 20px/26px brandon-grotesque",
      borderBottom: "none",
      marginBottom: "10px",
      display: "block",
      padding: "0 20px",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 400 22px/28px brandon-grotesque",
      borderBottom: "none",
      display: "block",
      padding: "10px 5% 10px 5%",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 400 24px/35px brandon-grotesque",
      display: "block",
      padding: "10px 12% 20px 12%",
    },
  },
  bold: {
    font: "normal normal 900 24px/35px brandon-grotesque",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 900 18px/20px brandon-grotesque",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 900 22px/26px brandon-grotesque",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 900 24px/35px brandon-grotesque",
    },
  },
  header: {
    color: "#717271",
    font: "normal normal 700 42px/20px brandon-grotesque",
    letterSpacing: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 700 25px/30px brandon-grotesque",
      textAlign: "center",
      width: "100%",
      margin: "0 auto",
      letterSpacing: "3px",
      paddingTop: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 700 50px/28px brandon-grotesque",
      textAlign: "center",
      width: "670px",
      margin: "0 auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 700 42px/20px brandon-grotesque",
      width: "100%",
      paddingTop: "60px",
    },
  },
  space: {
    color: "#ffffff",
    font: "normal normal normal 70px/80px brandon-grotesque",
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 42px/40px brandon-grotesque",
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 70px/80px brandon-grotesque",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal normal 70px/80px brandon-grotesque",
    },
  },
  buttonContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
    maxWidth: "600px",
    margin: "0 auto",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px",
      paddingBottom: "64px",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "0px",
      paddingBottom: "64px",
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  button: {
    flexDirection: "column",
    color: "#ffffff",
    background: "linear-gradient(#F7B334, #f58320) !important",
    borderRadius: "10px",
    padding: "6px 30px",
    margin: "8px",
    "&:hover": {
      background: "linear-gradient(#fda90c, #ffab61) !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 13px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "6px 26px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px 30px",
    },
  },
  button2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    background: "linear-gradient(#F7B334, #f58320) !important",
    borderRadius: "10px",
    padding: "17px 17px",
    margin: "8px",
    textDecoration: "none",
    "&:hover": {
      background: "linear-gradient(#fda90c, #ffab61) !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 13px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "6px 26px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px 30px",
    },
    span: {
      padding: "4px 8px",
    },
    svg: {},
  },
  buttonTop: {
    font: "normal normal 500 20px/24px brandon-grotesque",
    clear: "both",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 14px/18px brandon-grotesque",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 500 18px/23px brandon-grotesque",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 500 20px/24px brandon-grotesque",
    },
  },
  buttonBottom: {
    font: "normal normal 900 20px/24px brandon-grotesque",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 900 16px/20px brandon-grotesque",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 900 18px/23px brandon-grotesque",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 900 20px/24px brandon-grotesque",
    },
  },
  videoLink: {
    textDecoration: "none",
    padding: "0 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0",
    },
    "&:focus": {
      outline: 0,
    },
  },
  desktop: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  mobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const WarriorOfLight = ({ ...props }) => {
  const classes = useStyles();
  // const width = window.innerWidth || document.documentElement.clientWidth;

  return (
    <>
      <div
        id="wofl"
        style={{ position: "relative" }}
        className={classes.mainContainerRoot}
      >
        <div
          id="warriors-of-light"
          style={{ position: "absolute", top: "-120px", left: "0" }}
        ></div>
      </div>
      <div className={classes.mainContainerRoot}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.gridRoot}
        >
          <Grid item xs={12} sm={12} md={6} className={classes.gridText}>
            {isMobile && (
              <div id="warriors-of-light-root" className={classes.root}>
                <div>
                  <div className={classes.text}>
                    <div className={classes.desktop}>
                      <h1 className={classes.header}>
                        WARRI
                        <span className="smiley">
                          <img
                            src={smiley}
                            onError={(e) => (e.target.style.display = "none")}
                            alt="O"
                            className={classes.smileyText}
                          />
                        </span>
                        RS OF LIGHT
                      </h1>
                    </div>
                    <div className={classes.mobile}>
                      <h1 className={classes.header}>
                        WARRI
                        <span className="smiley">
                          <img
                            src={smiley}
                            onError={(e) => (e.target.style.display = "none")}
                            alt="O"
                            className={classes.smileyText}
                          />
                        </span>
                        RS OF LIGHT
                      </h1>
                    </div>
                    <div className={classes.intro}>
                      <div className={classes.borderLeft}>&nbsp;</div>
                      <div className={classes.introText}>VIDEO SERIES</div>
                      <div className={classes.borderRight}>&nbsp;</div>
                    </div>
                  </div>
                  <div className={classes.paragraphTextShow}>
                    <div className={classes.paragraph}>
                      <div className={classes.paragraphText}>
                        <span className={classes.bold}>
                          On the frontlines of the solar revolution
                        </span>{" "}
                        are some of the most{" "}
                        <span className={classes.bold}>driven</span>{" "}
                        individuals,{" "}
                        <span className={classes.bold}>dedicated</span> to the
                        cause of{" "}
                        <span className={classes.bold}>
                          caring for the planet
                        </span>{" "}
                        by helping people convert to a sustainable lifestyle.{" "}
                        <span className={classes.bold}>
                          These are the warriors
                        </span>{" "}
                        Loanpal seeks to spotlight in this video series.
                      </div>
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Link
                      to="/warriors-of-light"
                      className="naked-link centered"
                    >
                      <Button className={classes.button2}>
                        <Typography className={classes.buttonTop}>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "10px 8px 10px 0px",
                            }}
                          >
                            WATCH EPISODES
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="25"
                            viewBox="0 0 20 25"
                            style={{
                              position: "relative",
                              top: "7px",
                            }}
                          >
                            <g transform="translate(-15.822 -9.834)">
                              <g transform="translate(0.273 -0.665)">
                                <path
                                  d="M12.5,0,25,20H0Z"
                                  transform="translate(35.549 10.499) rotate(90)"
                                  fill="#fff"
                                  opacity="0.7"
                                />
                              </g>
                            </g>
                          </svg>
                        </Typography>
                      </Button>
                    </Link>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            )}
            {isBrowser && (
              <div className={classes.root}>
                <div className={classes.image}>
                  <div className="clearfix"></div>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {isMobile && (
              <div className={classes.root}>
                <div className={classes.image}>
                  <div className="clearfix"></div>
                </div>
              </div>
            )}
            {isBrowser && (
              <div id="warriors-of-light-root" className={classes.root}>
                <div>
                  <div className={classes.text}>
                    <div className={classes.desktop}>
                      <h1 className={classes.header}>
                        WARRI
                        <span className="smiley">
                          <img
                            src={smiley}
                            onError={(e) => (e.target.style.display = "none")}
                            alt="O"
                            className={classes.smileyText}
                          />
                        </span>
                        RS OF LIGHT
                      </h1>
                    </div>
                    <div className={classes.mobile}>
                      <h1 className={classes.header}>WARRIORS OF LIGHT</h1>
                    </div>
                    <div className={classes.intro}>
                      <div className={classes.borderLeft}>&nbsp;</div>
                      <div className={classes.introText}>VIDEO SERIES</div>
                      <div className={classes.borderRight}>&nbsp;</div>
                    </div>
                  </div>
                  <div className={classes.desktop}>
                    <div className={classes.paragraph}>
                      <div className={classes.paragraphText}>
                        <span className={classes.bold}>
                          On the frontlines of the solar revolution
                        </span>{" "}
                        are some of the most{" "}
                        <span className={classes.bold}>driven</span>{" "}
                        individuals,{" "}
                        <span className={classes.bold}>dedicated</span> to the
                        cause of{" "}
                        <span className={classes.bold}>
                          caring for the planet
                        </span>{" "}
                        by helping people convert to a sustainable lifestyle.{" "}
                        <span className={classes.bold}>
                          These are the warriors
                        </span>{" "}
                        Loanpal seeks to spotlight in this video series.
                      </div>
                    </div>
                  </div>
                  <div className={classes.mobile}>
                    <div className={classes.paragraph}>
                      <div className={classes.paragraphText}>
                        <span className={classes.bold}>
                          On the frontlines of the solar revolution
                        </span>{" "}
                        are some of the most{" "}
                        <span className={classes.bold}>driven</span>{" "}
                        individuals,{" "}
                        <span className={classes.bold}>dedicated</span> to the
                        cause of{" "}
                        <span className={classes.bold}>
                          caring for the planet
                        </span>{" "}
                        by helping people convert to a sustainable lifestyle.{" "}
                        <span className={classes.bold}>
                          These are the warriors
                        </span>{" "}
                        Loanpal seeks to spotlight in this video series.
                      </div>
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Link
                      to="/warriors-of-light"
                      className="naked-link centered"
                    >
                      <Button className={classes.button2}>
                        <Typography className={classes.buttonTop}>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "10px 8px 10px 0px",
                            }}
                          >
                            WATCH EPISODES
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="25"
                            viewBox="0 0 20 25"
                            style={{
                              position: "relative",
                              top: "5px",
                            }}
                          >
                            <g transform="translate(-15.822 -9.834)">
                              <g transform="translate(0.273 -0.665)">
                                <path
                                  d="M12.5,0,25,20H0Z"
                                  transform="translate(35.549 10.499) rotate(90)"
                                  fill="#fff"
                                  opacity="0.7"
                                />
                              </g>
                            </g>
                          </svg>
                        </Typography>
                      </Button>
                    </Link>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default WarriorOfLight;
