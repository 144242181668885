import React from 'react';

class BioRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupBkImage: '',
      imageRightUrl: this.props.bior !== undefined ? props.bior.photoUrl : '',
      imageLeftUrl: this.props.biol.photoUrl
    };    
    this.imageRightHover = this.imageRightHover.bind(this);
    this.imageLeftHover = this.imageLeftHover.bind(this);
  }      
  imageRightHover(url) {
    this.setState({
      imageRightUrl: url
    });
  }
  imageLeftHover(url) {
    this.setState({
      imageLeftUrl: url
    });
  }
  render() {    
    let biorphoto = '';
    let biordetails = '';    
    if (this.props.bior !== undefined) {
      biorphoto = (
        <div className="profile-pic">
          <a
            className="no-border hand"
            data-fancybox="images"
            data-src={`${this.props.bior.popupImg}`}
            href=":;"
          >
            <img              
              alt={this.props.bior.name}
              src={this.state.imageRightUrl}
              onMouseOver={e => this.imageRightHover(this.props.bior.overUrl)}
              onMouseOut={e => this.imageRightHover(this.props.bior.photoUrl)}
              className="photo"
            />
            <div
              className="profile-name"
              onMouseOver={e => this.imageRightHover(this.props.bior.overUrl)}
            >
              {this.props.bior.name}
            </div>
          </a>
        </div>
      );
      biordetails = (
        <div className="grid-item-text">
          <div className="brandon-medium bio-title">{this.props.bior.name}</div>
          <div className="brandon-regular-italic bio-title">
            {this.props.bior.title}
          </div>
          <div className="brandon-regular bio-description">
            {this.props.bior.description}
          </div>
          <a
            className="no-border hand brandon-regular-italic bio-description"
            data-fancybox="images"
            data-src={`${this.props.bior.popupImg}`}
            href=":;"
          >
            (read more)
          </a>
        </div>
      );    
    }
    return (
      <div className="row">
        <div className="container">
          <div className="grid-item a">
            <div className="profile-pic">
              <a
                className="no-border hand"
                data-fancybox="images"
                data-src={`${this.props.biol.popupImg}`}
                href=":;"
              >
                <img
                  alt={this.props.biol.name}
                  src={this.state.imageLeftUrl}
                  onMouseOver={e =>
                    this.imageLeftHover(this.props.biol.overUrl)
                  }
                  onMouseOut={e =>
                    this.imageLeftHover(this.props.biol.photoUrl)
                  }
                  className="photo"
                />
                <div
                  className="profile-name"
                  onMouseOver={e =>
                    this.imageLeftHover(this.props.biol.overUrl)
                  }
                >
                  {this.props.biol.name}
                </div>
              </a>
            </div>
          </div>
          <div className="grid-item b">
            <div className="grid-item-text">
              <div className="brandon-medium bio-title">
                {this.props.biol.name}
              </div>
              <div className="brandon-regular-italic bio-title">
                {this.props.biol.title}
              </div>
              <div className="brandon-regular bio-description">
                {this.props.biol.description}
              </div>
              <a
                className="no-border hand brandon-regular-italic bio-description"
                data-fancybox
                data-src={`${this.props.biol.popupImg}`}
                href=":;"
              >
                (read more)
              </a>
            </div>
          </div>        
          <div className="grid-item c">{biorphoto}</div>
          <div className="grid-item d">{biordetails}</div>          
        </div>
      </div>
    );
  }
}

export default BioRow;