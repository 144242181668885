import React from 'react';

import Trek from './components/trek';
import Head from '../../../shared/head';

const GivePowerTrek = () => { 
    return(  
        <>
        <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />           
        <Trek />                                                        
        </>
    )
}

export default GivePowerTrek;