import React from 'react';

const SingleInput = (props) => (   
    <div className="form-group">
      <label className="form-label mr-1">{props.title}{props.required ? <span style={{color: '#c20000', marginLeft: '2px'}}>*</span> : ''}</label>
      {props.inputType === 'checkbox' && 
        <input
          className="form-input"
          name={props.name}
          type={props.inputType}
          checked={props.checked}  
          value={props.checked}    
          onChange={props.controlFunc}
          placeholder={props.placeholder} />
      }
      {props.inputType !== 'checkbox' && 
        <input
          className="form-input"
          name={props.name}
          type={props.inputType}
          value={props.content}        
          onChange={props.controlFunc}
          placeholder={props.placeholder} />
      }
    </div>
);

// SingleInput.propTypes = {  
//     inputType: React.PropTypes.oneOf(['text', 'number']).isRequired,
//     title: React.PropTypes.string.isRequired,
//     name: React.PropTypes.string.isRequired,
//     controlFunc: React.PropTypes.func.isRequired,
//     content: React.PropTypes.oneOfType([
//       React.PropTypes.string,
//       React.PropTypes.number,
//     ]).isRequired,
//     placeholder: React.PropTypes.string,
//   };

  export default SingleInput;