import React from 'react';
import Modal from '../../../shared/modal-form';
import useModal from '../../../shared/modal-form/useModal'

const FriendForLife = () => {
    const { isShowing, toggle } = useModal();
    return (
        <section className="section-two benefits-section-two">                                
            <div className="title text-orange mt-40 brandon-black">when you're a friend,<br/>you're a loanpal for life!</div>                 
            <div className="title-2">returning customer? in addition to our <span className="brandon-medium">secure closing guarantee</span>, you're eligible for an exclusive discounted rate. plus, you pay <span className="brandon-medium">NO out-of-pocket expenses</span> on a refinance.</div>
            <button className="btn btn-full" onClick={toggle}>get started</button>                                
            <div className="clearfix"></div>
            <Modal                
                form="inquiry.mortgage"
                isShowing={isShowing}
                hide={toggle}
                paragraph="Simply enter your information below and one of our friendly team members will be in touch shortly."
            />
        </section>
    )
}

export default FriendForLife;