const accessiblityStatement = (callback) => {
    const existingScript = document.getElementById('accessibilityScript');
  
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://loanpal.usablenet.com/pt/accessibilitystatement.js?l=1';
      script.id = 'accessibilityScript';
      document.body.appendChild(script);
  
      script.onload = () => {
        if (callback) callback();
      };
    }
  
    if (existingScript && callback) callback();
  };

  export default accessiblityStatement;