import React from 'react';

const ControlClarityConfidence = () =>  {    
    return(            
        <section className="section-mortgage-one js--section-mortgage-one"> 
            <div className="row">                               
                <div className="col span-1-of-8"></div>                
                <div className="col span-2-of-8 box">
                    <h2 className="text-orange text-uppercase text-left brandon-bolder">Control.</h2> 
                    <p className="text-left mb-30">as a direct lender, we can bypass all the red tape, <span className="brandon-bold-italic">giving you control</span> of the process. that means you can take it as fast or slow as you like!</p>
                    <h2 className="text-orange text-uppercase text-left brandon-bolder">Clarity.</h2> 
                    <p className="text-left mb-30">you'll know exactly how much home you can afford, and precisely what you'll be paying every month.</p>
                    <h2 className="text-orange text-uppercase text-left brandon-bolder">Confidence.</h2> 
                    <p className="text-left mb-30">we're committed to getting you a <span className="brandon-bold-italic">great rate, unbeatable terms</span> and an overall lending experience that's second to none!</p>
                </div>
                <div className="col span-5-of-8 text-left">
                    <h3 className="text-orange ml-55">Close your loan in <span className="brandon-bold-italic tex-underline">as little as 10 days!</span></h3>
                    <img className="cs-phone" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/customerserv_phone.png" alt="Loanpal Customer Service" />
                </div>                
                <div className="clearfix"></div>
            </div>
        </section>            
    )    
}

export default ControlClarityConfidence;