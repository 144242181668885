import React from "react";
import Pdf from "../documents/MO-CF-License-thru-6.1.2021.pdf";
import NMConsumerInfo from "../documents/NM_Consumer_Informational_Brochure__Amended_8_27_18_.pdf";
import NMRateAndFees from "../documents/NM_LOAN_RATE_AND_FEES_DISCLOSURE.pdf";
import NMFinanceLicense from "../documents/NW-20-SL_license_02214_04.24.20.pdf";
import AKLicense from "../documents/AK-CF-thru-12.31.2021-10000978.pdf";

const LicensesContent = () => {
  return (
    <div>
      <section className="section-footer-pages">
        <div className="row">
          <p>
            Loanpal, LLC is an Equal Housing Lender. As prohibited by federal
            law, we do not engage in business practices that discriminate on the
            basis of race, color, religion, national origin, sex, martial
            status, age (provided you have the capacity to enter into a binding
            contract), because all or part of your income may be derived from
            any public assistance program, or because you have, in good faith,
            exercised any right under the Consumer Credit Protection Act. The
            federal agency that administers our compliance with these federal
            laws is the Federal Trade Commission, Equal Credit Opportunity,
            Washington, DC, 20580.
          </p>
        </div>
        <div className="row mt-20">
          <h4>Loanpal Disclosures & Licenses</h4>
          <br />
          <p>
            Loanpal issues loans in the name of Loanpal’s LLC, dba Loanpal.
            Loanpal, LLC, Dba Loanpal &reg;{" "}
            <a
              href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/30336"
              target="_blank"
              rel="noopener noreferrer"
            >
              NMLS #30336
            </a>{" "}
            is licensed or registered in the following states:
          </p>
          <br />
          <ul className="ml-35">
            <li>Alabama -Alabama Consumer Credit License #22277</li>
            <li>
              Alaska -Small Loan Company License{" "}
              <a target="_blank" rel="noopener noreferrer" href={AKLicense}>
                #10000978
              </a>
            </li>
            <li>Arizona –Arizona Mortgage Banker License #0935960</li>
            <li>
              California –Licensed by the Department of Business Oversight under
              the California Residential Mortgage Lending Act License #4170047
            </li>
            <li>
              Colorado –Regulated by the Division of Real Estate. Mortgage
              Company Registration
            </li>
            <li>Connecticut –Connecticut Mortgage Lender License # ML-30336</li>
            <li>Delaware ¬ - Delaware Lender License 018071</li>
            <li>
              D.C. –Mortgage Lender License #MLB30336, Money Lender License
              #ML30336
            </li>
            <li>
              Georgia –Georgia Department of Banking and Finance Georgia
              Mortgage Lender License #42733
            </li>
            <li>Florida- Florida Mortgage Lender Servicer License # MLD898</li>
            <li>
              Hawaii –Hawaii Mortgage Servicer License #MS136, Mortgage Lender
              License #HI-30336
            </li>
            <li>Idaho –Idaho Mortgage Broker/Lender License # MLB-8279</li>
            <li>
              Indiana –Indiana-DFI First Lien Mortgage Lending License,
              Indiana-SOS Loan Broker License, License # 30336 License # 28067
            </li>
            <li>
              Kansas –Kansas licensed mortgage company License # MC.0025206
            </li>
            <li>Maine –Supervised Lender License # 30336</li>
            <li>Maryland –Mortgage Lender License # 21172</li>
            <li>
              Minnesota –Minnesota Residential Mortgage Originator License,
              License # MN-MO-30336, Minnesota Residential Mortgage Originator
              License Other Trade Name #30336.1, Minnesota Residential Mortgage
              Originator License Other Trade Name #30336.2
            </li>
            <li>
              Missouri –MO Company Registration #19-2269; NMLS #30336; 1201 NW
              Briarcliff Parkway, 2nd Floor, Office #243, Kansas City, MO 64116
              <br /> Missouri Consumer Credit Loan Company Registration{" "}
              <a target="_blank" rel="noopener noreferrer" href={Pdf}>
                367-20-8243
              </a>{" "}
              (see Maximum Rates and Fees below)
            </li>
            <p className="brandon-bold mt-20">Maximum Rates and Fees:</p>
            <p>Loanpal’s Maximum Interest Rate offered in Missouri is 5.99%.</p>
            <p className="mb-20">
              Other than an insufficient check charge and the $44 Official Fee
              (to cover lien termination costs), Loanpal does not charge
              origination fees.
            </p>
            <li>Nevada –Nevada Mortgage Company License #4260</li>
            <li>
              New Jersey –Licensed by the N.J department of Banking and
              Insurance NMLS# 30336; New Jersey Residential Mortgage Lender
              License NMLS# 30336
            </li>
            <li>
              New Mexico –New Mexico Mortgage Loan Company License NMLS# 30336
              <ul style={{ marginLeft: "20px" }}>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={NMFinanceLicense}
                  >
                    New Mexico Finance License
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={NMConsumerInfo}
                  >
                    New Mexico Consumer Informational Brochure
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={NMRateAndFees}
                  >
                    New Mexico Loan Rate and Fees Disclosure
                  </a>
                </li>
              </ul>
            </li>
            <li>
              North Carolina –North Carolina Mortgage Lender License NMLS#
              30336, License# L-165599
            </li>
            <li>
              Ohio –Ohio Mortgage Loan Act Certificate of Registration
              #SM.501942.000, Ohio Mortgage Broker Act Mortgage Banker Exemption
              MBMB.850271.000
            </li>
            <li>Oregon –Oregon Mortgage Lender License #ML-3256</li>
            <li>Pennsylvania –PA Mortgage Lender License #52769</li>
            <li>
              South Carolina –South Carolina Board of Financial Institutions
              Mortgage Lender/Servicer License #MLS-30336, Mortgage
              Lender/Servicer License #MLS-30336 OTN #1, Mortgage
              Lender/Servicer License #MLS-30336 OTN #2
            </li>
            <li>Tennessee –Tennessee Mortgage License #125485, NMLS# 30336</li>
            <li>
              Texas –Texas SML Residential Mortgage Loan Servicer Registration
              NMLS# 30336, Mortgage Banker Registration NMLS# 30336
              <br />
              <br />
              <span className="brandon-bold">
                Texas Mortgage Banker Disclosure:
              </span>{" "}
              Pursuant to the requirements of Section 157.007 of the Mortgage
              Banker Registration and Residential Mortgage Loan Originator
              License Act, Chapter 157, Texas Finance Code, you are hereby
              notified of the following:
              <br />
              <br />
              CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A MORTGAGE BANKER OR
              A LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATOR
              SHOULD COMPLETE AND SEND A COMPLAINT FORM TO THE TEXAS DEPARTMENT
              OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201,
              AUSTIN, TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS MAY BE
              OBTAINED FROM THE DEPARTMENT’S WEBSITE AT WWW.SML.TEXAS.GOV. A
              TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT 1-877-276-5550. THE
              DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN
              ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS
              OF LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATORS.
              A WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND
              MUST BE FILED WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE
              PAYMENT OF A CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND,
              PLEASE CONSULT THE DEPARTMENT’S WEBSITE AT WWW.SML.TEXAS.GOV.
              <br />
              <br />
            </li>
            <li>
              Utah – Utah DRE Mortgage Entity License 6967176, Utah DRE Mortgage
              Entity License Other Trade Name#1 #9572003, Utah DRE Mortgage
              Entity License Other Trade Name#2 #9573336
            </li>
            <li>
              Virginia –Virginia Broker License #MC-5267, Virginia Lender
              License #MC-5267
            </li>
            <li>
              Washington –LLC NMLS ID #30336 Washington Consumer Loan Company
              License #CL-30336
            </li>
            <li>
              Wisconsin –Wisconsin Mortgage Broker License #30336BR, Wisconsin
              Banker License 30336BA
            </li>
          </ul>
        </div>
        <div className="row mt-20">
          <p className="mt-10">
            <a
              href="http://www.nmlsconsumeraccess.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NMLS Consumer Access Link
            </a>
          </p>
          <p className="mt-10">FHA Direct Endorsement Lender # 2352300002</p>
          <p className="mt-10">VA Lender # 9015910000</p>
          <p className="mt-10">USDA Lender # 470912342</p>
        </div>
        <div className="row mt-20">
          <h4>USA Patriot Act Notice</h4>
          <p className="mt-10">
            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OBTAINING A MORTGAGE LOAN
          </p>
          <p className="mt-10">
            To help us prevent fraud and to help the government fight the
            funding of terrorism and money laundering activities, Federal law
            requires all financial institutions to obtain, verify, and record
            information that identifies each person who applies for and/or
            obtains a mortgage loan.
          </p>
          <p className="mt-10">
            What this means to you: When you apply for and/or obtain a mortgage
            loan, we will ask for your name, address, date of birth, and other
            information that will allow us to identify you. We may also ask to
            see your driver’s license or other identifying documents.
          </p>
          <p className="mt-10">
            The information being requested and observed is for compliance with
            the requirements of Section 326 of the USA Patriot Act implementing
            customer identification, and verification requirements.
          </p>
          <p className="mt-10">
            Our Privacy Policy and Federal law will protect your identification.
          </p>
        </div>
        <div className="row mt-20">
          <h4>Equal Housing Lender</h4>
          <p className="mt-10">
            Loanpal, LLC, Dba Loanpal is an Equal Housing Lender. As prohibited
            by federal law, we do not engage in business practices that
            discriminate on the basis of race, color, religion, national origin,
            sex, martial status, age (provided you have the capacity to enter
            into a binding contract), because all or part of your income may be
            derived from any public assistance program, or because you have, in
            good faith, exercised any right under the Consumer Credit Protection
            Act.{" "}
          </p>
        </div>
        <div className="row mt-20">
          <h5 className="mt-10 brandon-medium">
            If you believe you have been discriminated against, you should send
            a complaint to:
          </h5>
          <p className="mt-10">
            Assistant Secretary of Fair Housing and Equal Opportunity
            <br />
            Department of Housing and Urban Development
            <br />
            Washington, DC 20410
            <br />
            Or call (800) 669-9777 / (800) 927-9275 (TDD)
            <br />
          </p>
        </div>
      </section>
    </div>
  );
};

export default LicensesContent;
