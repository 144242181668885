import React from "react";
import InquiryForm from "../../../shared/inquiry-form-customer";
import environment from "environment";

const ContactUs = () => {
  return (
    <section className="section-home-improvement-form">
      <div className="row paragraph">
        <div className="title-2 brandon-bold">
          Looking to sustainably upgrade your home?
        </div>
        <div className="title-3 brandon-bold mt-30">
          Enter your information below and one of our Sustainable Home
          Improvement specialists will contact you.
        </div>
      </div>
      <div className="inquiry-form row mt-20 mb-20">
        <InquiryForm
          form="inquiry.home.improvement"
          email={environment.communications.homeImprovement}
          paragraph="none"
          disclaimerTextColor="text-white"
          closeButton={false}
        />
      </div>
    </section>
  );
};

export default ContactUs;
