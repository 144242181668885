import React from 'react';
import UnderHeader from '../../../shared/header/under-header';

import Faqs from './components/faqs';
import Head from '../../../shared/head';

const FAQ = () => {
    const heroImageContent = "";
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="img-container-hero-green-energy"                                
                bgColor=""
                border=""
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}                
            />   
            <Faqs />                                
        </>                
    )
}

export default FAQ;