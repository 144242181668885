import React from 'react';
import UnderHeader from '../../../shared/header/under-header';

import WrittenConsentContent from './components/written-consent-content';
import Head from '../../../shared/head';

const WrittenConsent = () => {
    const heroImageContent = (
    <div id="hero-footer" className="hero-footer">
        <div className="row">
        <h1 className="brandon-light">Written Consent</h1>                        
        </div>
    </div>); 
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="img-container-hero-footer-pages"                                
                bgColor="purple-header"
                border=""
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />   
            <WrittenConsentContent />                                
        </>                
    )
}

export default WrittenConsent;