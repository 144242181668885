import React from 'react';

const Address = props => {
  return (
    <div>
      <h3 className="brandon-bold">{props.location}</h3>
      <div className="brandon-light address">
        {props.address0 && <div>{props.address0}</div>}
        {props.address1}
        <br />
        {props.address2}
        <br />
        {props.address3}
      </div>
      <div className="brandon-light mt-10 address">
        Toll Free: {props.tollFreePhone}
        <br />
        Phone: {props.phone}
      </div>
      <div>&nbsp;</div>
    </div>
  );
};

export default Address;
