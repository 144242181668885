import React from 'react';
import UnderHeader from '../../shared/header/under-header';

import Articles from './components/articles';
import Head from '../../shared/head';

const Resources = () => {     
    const heroImageContent = "";    
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="section-resources-header"                                
                bgColor=""
                border=""
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />  
            <Articles />          
        </>                
    )
}

export default Resources;