import React from 'react';

class SolarPartners extends React.Component {
    render () {
        return(        
            <main className="section-4-squares section-4-squares-light-grey">                                            
                <figure className="solar-girl">
                    <img className="" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/solarGirl.jpg" alt="Solar" />
                </figure>
                <div className="info-solar">
                    <div className="box parent first-box">
                        <div className="outer-box">
                            <div className="text-gray brandon-black title clearfix">
                            solar power superhero,<br />
                            meet superhuman lender.
                            </div>
                            <div className="brandon-reular text-gray description">
                                we are the fastest growing solar lender in the nation!
                            </div>
                            <div className="brandon-reular text-gray button">
                            <a className="btn btn-full uppercase view-more" 
                                href='/green-energy'
                                data-height="540"
                                data-width="960">Learn more</a>
                            </div> 
                        </div>
                    </div>
                </div>                
                <div className="info2 box parent">
                    <div className="outer-box">
                        <div className="text-gray brandon-black title clearfix">
                            see why our partners love us.
                        </div>
                        <div className="brandon-reular text-gray button">
                            <a className="btn btn-full uppercase view-more" 
                                href={'https://res.cloudinary.com/loanpal/video/upload/q_auto,vc_auto/v1/loanpal.com/videos/Solar_partners_180921_CCd_MIXed_FINAL_smaller.mp4'}
                                data-fancybox="true"
                                data-height="540"
                                data-width="960">Watch video</a>
                        </div>                        
                    </div>
                </div>
                <figure className="partners-love">
                    <a className="no-border" href={'https://res.cloudinary.com/loanpal/video/upload/q_auto,vc_auto/v1/loanpal.com/videos/Solar_partners_180921_CCd_MIXed_FINAL_smaller.mp4'} data-fancybox="true" data-width="960" data-height="540">
                        <img className="wp-video" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/solarPartnerButtonDown.jpg" alt="Our partners love us" />                        
                    </a>
                    <p className="partner-love-title text-150 text-orange brandon-medium">see why our solar partners <span className="text-140 brandon-black">Love Us.</span></p>
                    <a className="no-border" href={'https://res.cloudinary.com/loanpal/video/upload/q_auto,vc_auto/v1/loanpal.com/videos/Solar_partners_180921_CCd_MIXed_FINAL_smaller.mp4'} data-fancybox="true" data-width="960" data-height="540">
                        <img className="wp-video-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/solarPartnerButtonDown.jpg" alt="Our partners love us" />                        
                    </a>
                </figure>                                           
                <div className="clearfix"></div>
                <div style={{position: 'relative', top: '-100px', visibility: 'hidden'}} id="loansThatChangeLives"></div>
            </main>                       
        )
    }
}

export default SolarPartners;
