import React from 'react';
import UnderHeader from '../../shared/header/under-header';
import FriendForLife from './components/friend-for-life';
import PieceOfMind from './components/piece-of-mind';
import Head from '../../shared/head';

const Benefits = () => {
    return (        
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader
            id="u474839" 
            url="/resources/assets/friendslideshow/Friendslideshow.html" 
            navigation="hidden" 
            showSlideshowMovie={true} 
            movie="benefits-video" 
            movieUrl="/resources/assets/friendslideshow/benefits_mobileVideo" 
            showDIYSlideshow={false}
            showSlideshow={true}                 
            />              
            <FriendForLife handleInquiryModal /> 
            <PieceOfMind />                   
        </>
    )
} 

export default Benefits;