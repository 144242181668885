import React from 'react';

import UnderHeader from '../../shared/header/under-header';
import Leadership from './components/leadership'
import Head from '../../shared/head';

class About extends React.Component {   
  render() {    
    let heroImageContent = <a className="no-border" data-fancybox="true" data-width="960" data-height="540" id="video-link" href="https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/videos/loanpal_values_2019_MIXd_FINAL_small.mp4">
                            <div id="hero-company" className="hero-company">
                            <div className="video-thumbnail"></div>                            
                            </div>
                            </a>;
    return (
      <>
        <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
        <UnderHeader 
          image="img-container-hero-company" 
          bgColor="" 
          border="" 
          heroImageContent={heroImageContent} 
          showDIYSlideshow={false} 
          showSlideshow={false} 
          />          
        <Leadership />        
      </>
    );
    }    
}

export default About;