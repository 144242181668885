import React from 'react';
import BioPopup from './bio-popup';

const BioRow = (props) => {    
    const { bio: { id }} = props;
    console.log('BioRow', props);    
    return (
        <div className="row">
          <div className="container">
            <div className="grid-item a">
              <div className="profile-pic">              
                {/* <a
                  className="no-border hand"
                  data-fancybox="images"                          
                  data-src={`#modal${id}`}                                                      
                  href=":;"                                 
                > */}
                  <img src={props.bio.photoUrl} className="photo" alt={props.bio.name} />
                  <div className="profile-name">{props.bio.name}</div>
                {/* </a> */}
              </div>
            </div>
            <div className="grid-item b">
              <div className="grid-item-text">
                <div className="brandon-medium bio-title">
                  {props.bio.name}
                </div>
                <div className="brandon-regular-italic bio-title">
                  {props.bio.title}
                </div>
                <div
                  className="brandon-regular bio-description more"
                  dangerouslySetInnerHTML={{ __html: props.bio.description }}
                /> 
                <a
                className="btn btn-orange brandon-black text-white float-right hand"  
                data-fancybox="images"                
                data-src={`#modal${id}`}
                href=":;"                                                                                                              
                >
                  read more
                </a>                
              </div>
            </div>            
            <BioPopup                         
              bkImg={props.bio.mobileImg}
              name={props.bio.name}
              background={props.bio.background}
              side={props.bio.side}
              title={props.bio.title}
              description={props.bio.full}
              position={props.bio.position}              
              id={props.bio.id}              
            />            
          </div>                  
        </div>
    )
}

export default BioRow;