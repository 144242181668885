import React from 'react';

class WorldPositiveDesktop extends React.Component {
    render () {
        return(                    
            <main className="section-4-squares section-4-squares-light-grey">                
                <figure className="world-positive">
                    <a className="no-border" href={'https://res.cloudinary.com/loanpal/video/upload/q_auto,vc_auto/v1/loanpal.com/videos/Loanpal_sizzle_WorldPositive_190709.mp4'} data-fancybox="true" data-width="960" data-height="540">
                        <img className="wp-video" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/sizzleVideoButtonDown.jpg" alt="World Positive Lender" />
                        <img className="wp-video-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/sizzleVideoButtonDown.jpg" alt="World Positive Lender" />
                    </a>
                    <div className="clearfix"></div>
                </figure>  
                <div className="info">
                    <div className="box parent first-box">
                        <div className="outer-box">
                            <div className="text-gray brandon-black title clearfix">
                                the world-positive lender <sup>&trade;</sup>
                            </div>
                            <div className="brandon-reular text-gray description">
                                we provide financing options for those who dream of living a more sustainable lifestyle. 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info2-mobile box parent">
                    <div className="outer-box">
                        <div className="text-gray brandon-black title clearfix">
                            our experience stands out
                        </div>
                        <div className="brandon-regular text-gray description">
                            we've been lending for over <span className="highlight text-orange brandon-medium">16 years</span>, operate in all <span className="highlight text-orange brandon-medium">50 states</span>, and have changed the lives of more than <span className="highlight text-orange brandon-medium">151,000</span> customers.
                        </div>
                    </div>
                </div>                                
                <div className="info2">
                    <div className="box parent">
                        <div className="outer-box">
                            <div className="text-gray brandon-black title clearfix">
                                our experience stands out
                            </div>
                            <div className="brandon-regular text-gray description">
                                we've been lending for over <span className="highlight brandon-medium">16 years</span>, operate in all <span className="highlight brandon-medium">50 states</span>, and have changed the lives of more than <span className="highlight brandon-medium">151,000</span> customers.
                            </div>
                        </div>
                    </div>
                </div>               
                <figure className="loans-funded">                    
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1591657844/loanpal.com/billions_Balloon.jpg" alt="Conscious Collective" />                    
                </figure>  
                {/* Conscious Collective section  */}
                {/* <div className="conscious-info-mobile">
                    <div className="box parent first-box">
                        <div className="outer-box">
                            <div className="text-gray brandon-regular title-conscious clearfix">
                                welcome to the
                            </div>
                            <div className="image-conscious">
                                <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1587160336/loanpal.com/CCollectivLogo_grey.png" alt="Conscious Collective" />
                            </div>
                            <div className="brandon-reular text-gray description-conscious">
                                Loanpal is the founding memeber of the conscious collective - a group of organizations and individuals committed to addressing the climate crisis. Watch the video to learn more. 
                            </div>
                        </div>
                    </div>
                </div>
                <figure className="conscious-video">
                    <a className="no-border" href={'https://res.cloudinary.com/loanpal/video/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1587159079/loanpal.com/videos/The_Conscious_Collective.mp4'} data-fancybox="true" data-width="960" data-height="540">
                        <img className="wp-video" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/consciouscollective_videothumbnail2_Over.jpg" alt="Conscious Collective" />                        
                        <img className="wp-video-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/consciouscollective_videothumbnail2_Over.jpg" alt="Conscious Collective" />
                    </a>
                    <div className="clearfix"></div>
                </figure>  
                <div className="conscious-info">
                    <div className="box parent first-box">
                        <div className="outer-box">
                            <div className="text-gray brandon-regular title-conscious clearfix">
                                welcome to the
                            </div>
                            <div className="image-conscious">
                                <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1587160336/loanpal.com/CCollectivLogo_grey.png" alt="Conscious Collective" />
                            </div>
                            <div className="brandon-reular text-gray description-conscious">
                                Loanpal is the founding memeber of the conscious collective - a group of organizations and individuals committed to addressing the climate crisis. Watch the video to learn more. 
                            </div>
                        </div>
                    </div>
                </div>  */}
                {/* End of Consciout collective section                             */}
            </main>       
        )
    }
}

export default WorldPositiveDesktop;
