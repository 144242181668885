import React from 'react';
import loadAccessibiltiyScript from './accessibilitystatement'

class AdaContent extends React.Component {
    componentWillMount() {
        loadAccessibiltiyScript();
    }
    render() {                
        return(        
            <section className="ml-2 mr-2">                        
                <div id="a40AccessibilityStatement"></div>            
            </section>        
        )            
    }          
}

export default AdaContent;
