import React from 'react';

const Faqs = () => {    
    return(
        <section className="section-footer-pages" id="works">            
        <div className="row">                                
            <div className="works-step clearfix">                
                <p className="brandon-bold">
                    <span className="smiley">
                        <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Orange_Smiley.svg" alt="Loanpal" />
                    </span>
                    Solar
                </p>
            </div>
            <div className="works-question">
                <ul>
                    <li>Will I still have an Electricity Bill from my Utility after going solar?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">Yes, most of our customers will still pay a vastly reduced Electricity Bill to their Utility in addition to their monthly loan payment to Loanpal. Typically, homeowners consume more electricity than the solar system generates, therefore, you will still owe your utility for that excess consumption. However, if the solar system generates more electricity than you use, you may be eligible for credits from your Utility Provider.</p></li>
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>How does the Federal Investment Tax Credit (ITC) work?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">The ITC in 2020 is a 26% federal tax credit for solar systems on residential properties. As the owner of the solar energy system, you are eligible to apply the credit as a dollar-for-dollar reduction in the federal income taxes that you owe in the year that you purchased your solar system. If the ITC granted for your solar system is greater than your tax liabilities in the year that you purchased your solar energy system, you may be able to apply the remaining ITC in the subsequent year. To determine your eligibility for any federal solar investment tax credit, you should make an independent assessment or consult with your tax advisor. 

</p></li>
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>How exactly are my loan payments structured?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">The loan is structured to give you the benefit of paying down the 26% Federal Investment Tax Credit into your loan, so that your initial monthly payments are kept low. After month 18, your loan will re-amortize, and your new monthly payment will be adjusted so your loan is fully repaid by the end of your term. There are 3 scenarios for your new monthly payment: 
</p>
                    </li>
                </ul>
            </div>
            <div className="works-sub-answer">
                <ul>
                    <li><p className="long-copy">If you pay down your loan 26% by month 18, your monthly payment will remain approximately the same throughout the life of your loan.</p>
                    </li>
                    <li><p className="long-copy">If you do not pay down your loan 26% by month 18, your new monthly payment will be higher than your initial monthly payment.</p>
                    </li>
                    <li><p className="long-copy">If you pay down your loan by more than 26% by month 18, your new monthly payment will be less than your initial monthly payment.</p>
                    </li>
                </ul>
            </div>
            <div className="works-step clearfix">                
                <p className="brandon-bold">
                    <span className="smiley">
                        <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Orange_Smiley.svg" alt="Loanpal" />
                    </span>
                    Payments
                </p>
            </div>
            <div className="works-question">
                <ul>
                    <li>When is my first payment due?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">Your first payment date is indicated in your Closing Certificate. To have us resend your Closing Certificate, please email us at <a href="mailto:closingcertificates@loanpal.com">closingcertificates@loanpal.com</a>. Or, you can review all your loan details on your Portal.</p></li>
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>How do I make a payment on my loan?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">If you are paying by check, please include your Loan Agreement Number on your check and mail it to Loanpal, PO BOX 4387, Portland, OR 97208.</p></li>
                    <li><p className="long-copy">If you have set up your payments to be made by ACH, your account will be withdrawn monthly for the exact amount of your solar loan payment. To view your current ACH settings, please visit your online portal.</p></li>
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>How do I enroll in ACH?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">To enroll in ACH, please enter your Portal and follow the instructions on the ACH tab.</p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>When is my Target Balance Date and what is my Target balance?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">To view your Target Balance Date and Target Balance, please log in to your Portal.</p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>How do I make a pay back the Federal Investment Tax Credit into my loan?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">You may make any prepayment, including your ITC prepayment, by either sending a check or logging into your personal payment portal and doing a 1 time ACH.</p></li>
                    <li><p className="long-copy">If you are paying by check, please include your Loan Agreement Number on your check and mail it to Loanpal, PO BOX 4387, Portland, OR 97208.</p></li>						
                </ul>
            </div>				
            <div className="works-question">
                <ul>
                    <li>Will I be charged any prepayment penalty?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">No, Loanpal does not assess any fee or penalty for prepayments.</p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>When does interest begin to accrue on my loan?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">Interest begins accruing from the date that Loanpal funds your loan.</p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>Once approved for credit, how long is it valid?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">Our credit approval is valid for 180 days from when we initially run your credit. Your loan must fund within 180 days of running your credit, or we will simply need to revalidate your credit.</p></li>						
                </ul>
            </div>
            <div className="works-step clearfix">                
                <p className="brandon-bold">
                    <span className="smiley">
                        <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Orange_Smiley.svg" alt="Loanpal" />
                    </span>
                    Home Sale/Refinance
                </p>
            </div>
            <div className="works-question">
                <ul>
                    <li>What is a UCC-1 financing statement and is it a lien on my home?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">We secure your Solar Loan through a lien on the solar equipment itself by filing a UCC-1, or Uniform Commercial Code Financing Statement, and county fixture filing. The filings are not a lien against your home, so we do not hold any formal position in your home (first, second, or otherwise). You will, however, find a UCC-1 fixture filing on the title of your property. We file UCC-1 and county fixture filings to protect our rights as the financier of the system. If for whatever reason your mortgage on the real property forecloses on your home, the UCC-1 filing protects our security interest in the system, while preventing your mortgage lender from taking ownership of it. </p>
                    <p className="long-copy">If you have any questions about the UCC-1 or county fixture filings, please contact us at <a href="mailto:solarinfo@loanpal.com">solarinfo@loanpal.com</a></p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>I want to sell my home. What do I do about my Loanpal loan?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">You have two options:</p></li>
                </ul>
            </div>
            <div className="works-sub-answer">
                <ul>
                    <li><p className="long-copy">In the vast majority of cases, homeowners choose to pay off the remaining balance of the Loanpal loan through the sale of their home.</p></li>
                    <li><p className="long-copy">However, if the new homeowner wishes to assume the loan, he/she must apply to qualify for a Loanpal loan. If the new homeowner is approved, then he/she assumes full responsibility of the loan. If he/she is not approved, then you must pay off the remaining balance of the Loanpal loan.</p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>What are the benefits to rolling my solar loan into my mortgage?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">Through our Mortgage Division, we can offer you very competitive interest rates on your mortgage. Because mortgage interest is tax deductible, most of our customers can see significant savings by refinancing and paying off their solar loans with their mortgage. To understand the benefits of rolling your solar loan into your mortgage, please call one of our specialists at: 844-562-6725 or visit us at <a href="http://www.loanpal.com">www.loanpal.com</a></p></li>						
                </ul>
            </div>
            <div className="works-question">
                <ul>
                    <li>What happens to the UCC-1 during a refinance?</li>
                </ul>
            </div>
            <div className="works-answer">
                <ul>
                    <li><p className="long-copy">We may agree to lift our UCC-1 and county fixture filing on the Solar Equipment for a limited period provided we will be able to refile upon closing of the mortgage refinancing.</p></li>						
                </ul>
            </div>
        </div>
    </section> 
    )
}

export default Faqs;
