import React from 'react';
import UnderHeader from './components/under-header';
import DirectPayContent from './components/direct-pay-content';
import Head from '../../../components/shared/head';

const DirectPay = () => {
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader
                image="img-container-hero-direct-pay"
            />
            <DirectPayContent />
        </>
    )
}

export default DirectPay;