import React from 'react';
import { NavLink } from "react-router-dom";
// import { FormattedMessage } from 'react-intl';

class KnowYourLoan extends React.Component {  
  render() {    
    return (      
        <section className="section-two home-section-two js--section-know-your-loan">
          <div className="col span-4-of-4">
            <div
              className="title text-gray brandon-black"
              style={{ marginBottom: '10px', marginTop: '20px' }}
            >
              {/* <FormattedMessage id="general.knowYourLoan.header" /> */}
              know your loan. love your lender.
            </div>
            <div className="brandon-medium text-gray mb-20 financier-paragraph">
              {/* <FormattedMessage id="general.knowYourLoan.paragraph" /> */}
              from home ownership to home innovation, we drive modern lending forward with fast, tech-enabled financing solutions.
            </div>
            <div
              className="title-1 text-orange brandon-medium bright-future-title"
              style={{ marginBottom: '-15px' }}
            >
              let's innovate
            </div>
            <div className="slideshow-nav">
              <li>                
                <NavLink
                    to="/green-energy"                
                    data-tip="Green Energy">
                    <span className="clean-energy" />
                </NavLink>
              </li>
              <li>                
                <NavLink
                    to="/home-improvement"                
                    data-tip="Home Improvement">
                  <span className="home-improvement-2" />
                </NavLink>
              </li>
              <li>                
                <NavLink
                    to="/mortgage"                
                    data-tip="Mortgage">
                  <span className="home-loans" />
                </NavLink>
              </li>
              <li>                
                <NavLink
                    to="/refinance"                
                    data-tip="Refinance">
                  <span className="home-refinance" />
                </NavLink>
              </li>
            </div>           
          </div>  
          <div className="clearfix" />              
        </section>        
    );
  }
}

export default KnowYourLoan;
