import React from 'react';

const ProvenLeadership = () => {
    return (
      <section className="section-green-energy section-proven-leadership">
        <div className="row">
          <div className="leadership-title">
            Proven Leadership{' '}
            <img
              src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/orangeArrow.svg"
              style={{ width: '20px' }}
              alt="Proven Leadership"
            />
          </div>
          <p className="leadership-description">
            Our executive team has an unparalleled level of understanding and
            experience in the clean energy, technology and finance industries.{' '}
          </p>
          <div className="bios">
            <div className="bioa">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/hayes.jpg" alt="Hayes Barnard" />
              <br />
              <span className="brandon-bold uppercase">Hayes Barnard</span>
              <br />
              <span className="bio-text-title brandon-medium">
                Founder, Chairman and CEO
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>CRO, Solar City</li>
                <li>Founder, Paramount Solar</li>
              </ul>
            </div>
            <div className="biob">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/dawson.jpg" alt="Matt Dawson" />
              <br />
              <span className="brandon-bold uppercase">Matt Dawson</span>
              <br />
              <span className="bio-text-title brandon-medium">
                Chief Revenue Officer
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>SVP Market Development, SolarCity</li>
                <li>Co-Founder, Paramount Solar</li>
              </ul>
            </div>
            <div className="bioc">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/serra.jpg" alt="Tanguy Serra" />
              <br />
              <span className="brandon-bold uppercase">Tanguy Serra</span>
              <br />
              <span className="bio-text-title brandon-medium">
                President & Chief Investment Officer
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>President SolarCity</li>
                <li>Co-Founder Vivint Solar </li>
              </ul>
            </div>
            <div className="biod">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/ming.png" alt="Ming Wu" />
              <br />
              <span className="brandon-bold uppercase">Ming Wu</span>
              <br />
              <span className="bio-text-title brandon-medium">
                Chief Technology Officer
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>VP of Data & Analytics at Okta</li>
                <li>SVP of Information Systems at SolarCity</li>
              </ul>
            </div>
            <div className="bioe">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/stephan.jpg" alt="Paul Stephan" />
              <br />
              <span className="brandon-bold uppercase">Paul Stephan</span>
              <br />
              <span className="bio-text-title brandon-medium">
                EVP, Business Development
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>SVP of Res. Sales, SolarCity</li>
                <li>VP of Sales, Paramount Solar</li>
              </ul>
            </div>
            <div className="biof">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/merkley.jpg" alt="Brendon Merkley" />
              <br />
              <span className="brandon-bold uppercase">Brendon Merkley</span>
              <br />
              <span className="bio-text-title brandon-medium">
                Chief Operating Officer
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>EVP Customer Ops, SolarCity</li>
                <li>Co-Founder/COO, Vivint Solar</li>
              </ul>
            </div>
            <div className="biog">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/alice.jpg" alt="Alice Cathcart" />
              <br />
              <span className="brandon-bold uppercase">Alice Cathcart</span>
              <br />
              <span className="bio-text-title brandon-medium">
                Chief Information Officer
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>VP Customer Operations, Solar City</li>
                <li>Sr. Project Developer, SunPower</li>
              </ul>
            </div>
            <div className="bioh">
              <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/leadership/green-energy/coletta.jpg" alt="Sean Coletta" />
              <br />
              <span className="brandon-bold uppercase">Sean Coletta</span>
              <br />
              <span className="bio-text-title brandon-medium">
                SVP, Capital Markets
              </span>
              <br />
              <ul className="arrow bio-text-list">
                <li>Head of Finance, Spruce</li>
                <li>Director, FRV</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
}

export default ProvenLeadership;
