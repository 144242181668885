import React from "react";

import UnderHeader from "../../shared/header/under-header";
import KnowYourLoan from "./components/know-your-loan";
import WorldPositive from "./components/world-positive";
import Testimonials from "./components/testimonials";
import SolarPartners from "./components/solarpartners";
import GivePower from "./components/givepower";
import TogetherMoreThanEver from "./components/together-more-than-ever";
import BusinessJournal from "./components/business-journal";
import Employees from "./components/employees";
import WarriorsOfLightEp2 from "./components/warriors-of-light/wol";
import Head from "../../shared/head";

import LoanpalPros from "components/shared/LoanpalPros/LoanpalPros.js";

class Home extends React.Component {
  componentDidMount() {
    if (this.props.location.hash) {
      setTimeout(() => {
        window.scrollBy({
          top: -105,
          left: 0,
          behavior: "smooth",
        });
      }, 1500);
    } else {
      setTimeout(() => {
        window.scrollBy({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 1500);
    }
    console.log("Home loaded");
  }
  render() {
    if (this.props.location.hash) {
      setTimeout(() => {
        window.scrollBy({
          top: -105,
          left: 0,
          behavior: "smooth",
        });
      }, 1500);
    }
    return (
      <>
        <Head title={`Loanpal Home`} />
        <UnderHeader
          id="U356592"
          url="/resources/assets/mainheader_march_2019/MainPageHeaderMarch2019.html"
          navigation="show"
          showSlideshowMovie={true}
          movie="home-video"
          movieUrl="/resources/assets/mainheader_march_2019/HeaderSlideshow_720_oct2018"
          showDIYSlideshow={false}
          showSlideshow={true}
          // eslint-disable-next-line
          style="slideshow-home"
        />
        {false && <KnowYourLoan />}
        <LoanpalPros />
        <WorldPositive />
        <WarriorsOfLightEp2 />
        <Testimonials />
        <SolarPartners />
        <GivePower />
        <TogetherMoreThanEver />
        <BusinessJournal />
        <Employees />
      </>
    );
  }
}

export default Home;
