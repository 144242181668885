import React from 'react';
import { HashLink } from 'react-router-hash-link';

const PrivacyContentCA = () => {    
        return(            
        <section className="section-footer-pages">
            <div className="row mt-20 mb-20">
                <h3 className="brandon-medium text-left mt-20">California Privacy Rights Notice</h3>
                <p>This California Privacy Rights Notice supplements the information contained in Loanpal's Privacy Policy (<a href="/privacy" >loanpal.com/privacy</a>) and applies to California residents ("consumers" or "you").   If you are a California resident, then you have certain rights under the California Consumer Privacy Act ("CCPA") regarding Personal Information.</p>
                <p className="brandon-medium mt-20">Right to Know.</p>
                <p>You have the right to know and see what data we have collected about you over the past 12 months, including:</p>
                <ul style={{marginLeft: '40px'}}>
                <li>The categories of Personal Information we have collected about you (see{' '} 
                    <HashLink 
                  smooth={true}
                  to="/privacy-policy-california#categoriesOfInformationWeCollect"                  
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
                >
                  Categories of Information We Collect
                </HashLink> below)</li>
                <li>The categories of sources from which the Personal Information is collected (see{' '}
                <HashLink 
                  smooth={true}
                  to="/privacy-policy-california#categoriesOfSourcesForPersonalInformation"                  
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
                >
                  Categories of Sources for Personal Information
                </HashLink> below)
                </li>
                <li>Our business or commercial purpose for collecting your Personal Information (see{' '}
                <HashLink 
                  smooth={true}
                  to="/privacy-policy-california#purposesForCollectionAndUseOfPersonalInformation"                  
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
                >
                  Purposes for Collection and Use of Personal Information
                </HashLink> below) </li>
                <li>The categories of third parties with whom we have shared your Personal Information (see{' '}
                <HashLink 
                  smooth={true}
                  to="/privacy-policy-california#sharingOfInformation"                  
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
                >
                  Sharing of Information
                </HashLink> below) and </li>
                <li>The Personal Information we have collected specifically about you (see{' '}
                <HashLink 
                  smooth={true}
                  to="/privacy-policy-california#exercisingYourRights"                  
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
                >
                  Exercising Your Rights
                </HashLink> below).</li>
                </ul>
                <p className="brandon-medium mt-20">Right to Delete</p>
                <p>You have the right to request that we delete the Personal Information we have collected from you (and direct our Service Providers to do the same). There are a number of exceptions, however, that include, but are not limited to, when the information is necessary for us to do any of the following:</p>
                <ul style={{marginLeft: '40px'}}>
                <li>Complete your transaction;</li> 
                <li>Provide services to you;</li> 
                <li>Perform a contract between us and you;</li> 
                <li>Protect your security and prosecute those responsible for breaching it;</li> 
                <li>Fix our systems in the case of a bug;</li>
                <li>Comply with a legal obligation; or</li> 
                <li>Make other internal and lawful uses of the information that are compatible with the context in which you provided it.</li>
                </ul>                
                <p className="brandon-medium mt-20">Right to Prohibit Sales of Your Information</p>
                <p>California law requires that we provide transparency about personal information we "sell," defined as Loanpal sharing personal information with third parties in exchange for valuable consideration.</p> 
                <div id="exercisingYourRights"></div>
                <p className="brandon-black mt-20">In the preceding twelve (12) months, Loanpal has not sold your Personal information.</p>  
                <p className="mt-20">We will provide notice to you prior to any change to our "No Sales" policy.  In addition, you may pro-actively prohibit any such future sales of your Personal Information by us, see "Exercising Your Rights" below.</p>
                
                <p className="brandon-medium text-120 mt-20">Exercising Your Rights</p>
                <p>To exercise your specific <span className="brandon-medium">Right to Know</span>, or your <span className="brandon-medium">Right to Delete</span>, please submit a verifiable consumer request to us by email to CustomerCare@loanpal.com or by calling us at 844-LOANPAL (844.562.5725).</p> 

                <p className="mt-20">To exercise your <span className="brandon-medium">Right to Prohibit the Sales of your Personal Information</span> (including future sales) please (a) click the link "Do Not Sell My Personal Information" below or (b) submit a verifiable consumer request to us by email to CustomerCare@loanpal.com or by calling us at 844-LOANPAL (844-562-5725).</p> 

                <p className="mt-20">Only you, or someone legally authorized to act on your behalf, may exercise these rights related to your Personal Information. Such a request must:</p>
                <ul style={{marginLeft: '40px'}}>
                <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.</li>
                <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                </ul>
                <div id="categoriesOfInformationWeCollect"></div>
                <p>We cannot respond to your request or provide you with the specific Personal Information we have if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you.  We will verify your request(s) by having you provide your name, address and contact information. We will then request additional identification verification to confirm your identity and/or the identity and authority of your authorized representative.</p>                
                <p className="mt-20">We will not discriminate against you for exercising any of your CCPA rights described above. Unless permitted by the CCPA, we will not deny you goods or services; charge you different prices or rates for goods or services; provide you a different level or quality of goods or services; or suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>
                                   
                <h4 className="brandon-medium text-120 mt-20">Categories of Information We Collect</h4>
                <p>Loanpal collects information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household ("Personal Information").</p>

                <p className="mt-20">Specifically, we directly and/or through our website, mobile applications, or other online systems ("Systems") have collected the following categories of Personal Information from consumers within the last twelve (12) months:</p>
                <ul style={{marginLeft: '40px'}}>
                <li>Personal unique identifiers (SSN, name, drivers license, passport number)</li>
                <li>Personal information, including contact details, financial information</li>
                <li>Protected classification characteristics under California or federal law, such as sex or marital status</li>
                <li>Purchase or other commercial information</li>
                <li>Biometric information; Geo-location information, Sensory information</li>
                <li>Internet or online information, information regarding interactions with our websites, applications, or advertisements</li>
                <li>Employment or education-related information</li>
                <li>Inferences from drawn from personal information collected</li>
                </ul>
                <div id="categoriesOfSourcesForPersonalInformation"></div>
                <p className="mt-20">Personal Information does not include:</p>
                <ul style={{marginLeft: '40px'}}>                    
                <li>Publicly available information from government records.</li>
                <li>De-identified, anonymized or aggregated consumer information.</li>
                <li>Information excluded from the CCPA's scope</li>
                </ul>
                o	(e.g., information covered by the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994).
                                
                <p className="brandon-medium text-120 mt-20">Categories of Sources for Personal Information</p>
                <p className="mb-20">Loanpal obtains the Personal Information from the following categories of sources:</p>
                <div id="purposesForCollectionAndUseOfPersonalInformation"></div>
                <ul style={{marginLeft: '40px'}}>
                <li>Directly from you. For example, from your communications to us and through the forms you complete and information you provide to request our financial products.</li>
                <li>Indirectly from you. For example, when you navigate through the Systems and such information is collected automatically. Information collected automatically may include usage details, IP addresses and information collected through cookies and other tracking technologies.</li>
                <li>From third-party sources.  For example, consumer reporting agencies to process and approve your loan application, to verify identity, and to offer you financial products and services of interest to you.</li>
                </ul>


                <p className="brandon-medium text-120 mt-20">Purposes for Collection and Use of Personal Information</p>
                <p>We may use or disclose the Personal Information we collect for one or more of the following business purposes:</p>
                <ul style={{marginLeft: '40px'}}>
                <li>To fulfill or meet the reason you provided the information.</li>  
                <li>To provide, support, personalize, and develop our Systems and related services.</li>
                <li>To create, maintain, customize, and secure your account information and to support everyday operations including risk, legal, and compliance requirements.</li>
                <li>To prevent transactional fraud.</li>
                <li>To respond to your customer support requests and/or consumer complaints.</li> 
                <li>To personalize your experience using our Systems and related services to deliver content and offerings of interest to you.</li>
                <li>To help maintain the safety, security, and integrity of our Systems, services, databases, related technology assets, and our overall business.</li>
                <li>For testing, research, analysis, and product development, including to develop and improve our business, our Systems and related services.</li>
                <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                <li>As described to you when collecting your personal information.</li>
                <li>To evaluate or conduct a transaction involving some or all of Loanpal's business or assets in which such information is among the assets to be included.</li>
                </ul>
                <div id="sharingOfInformation"></div>
                <p className="brandon-medium text-120 mt-20">Sharing Personal Information</p>
                <p>We share your personal information with the following categories of third parties:</p>
                <ul style={{marginLeft: '40px'}}>
                <li>Parties involved in the loan application, underwriting, and financing process.</li>
                <li>Parties who service your account.</li>
                <li>Parties that perform services on Loanpal's behalf.</li>
                <li>Parties specifically authorized by you.</li>
                <li>Loanpal's financing partners, investors, related agents and advisors.</li>
                <li>As required by law.</li>
                </ul>
            </div>
        </section>            
    )    
}

export default PrivacyContentCA;
 

 

