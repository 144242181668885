import React from 'react';
import Slideshow from '../slideshow';
import DIYSlideshow from '../diy-slideshow';
import HeroImage from '../hero-image';
import SlideshowMovie from '../slideshow-movie'

const UnderHeader = ({...props}) => {   
    return (
        <>
        {props.showSlideshow ? (
            <Slideshow
              id={props.id}
              url={props.url}
              navigation={props.navigation}
              style={props.style}
            />
          ) : props.showDIYSlideshow ? (
            <DIYSlideshow
              openPartnerModal={props.openPartnerModal}
              image={props.image}
              bgColor={props.bgColor}
              border={props.border}
            >
              {props.heroImageContent}
            </DIYSlideshow>
          ) : (
            <HeroImage
              image={props.image}
              bgColor={props.bgColor}
              border={props.border}
            >
              {props.heroImageContent}
            </HeroImage>
          )}
          {props.showSlideshowMovie ? (
            <SlideshowMovie
              id={props.movie}
              movieUrl={props.movieUrl}
            />
          ) : (
            ''
          )}
          </>
    )
}

export default UnderHeader;