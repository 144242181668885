import React from 'react';
import ReactDOM from 'react-dom';
import Drawer from 'react-drag-drawer'
import InquiryForm from '../inquiry-form-customer';
import environment from 'environment';
import './style.css';

console.log(environment);

const paragraphSolarPartner =  <>With a platform customized for your business, we make the lending process fast and friendly for you <span className="brandon-bold-italic text-underline">and</span> your customers. Tell us a little bit about you.</>;
const paragraphDefault = "Simply enter your information below and one of our friendly team members will be in touch shortly."

const ModalForm = ({ isShowing, hide, paragraph, pathname, form, email, directPay }) => isShowing ? ReactDOM.createPortal(    
    <React.Fragment>     
        <Drawer
            open={isShowing}
            inRequestClose={hide}
            parentElement={document.body} // element to be appended to
            modalElementClass="modal-drawer"
            >
            <InquiryForm
                form={form}
                pathname={pathname}                 
                email={form === 'inquiry.partner' ? environment.communications.partnerInquiry
                    : !email && pathname === '/green-energy' ? environment.communications.solarInquiry 
                    : !email && pathname !== '/green-energy' ? environment.communications.mortgageInquiry 
                    : email} 
                closeModal={hide}                 
                paragraph= {paragraph ? paragraph : !paragraph && form === 'inquiry.partner' ? paragraphSolarPartner : paragraphDefault }
                disclaimerTextColor="text-gray"
                closeButton={true}
                directPay={directPay}
            />
        </Drawer>

    </React.Fragment>, document.body
) : null;    

export default ModalForm;