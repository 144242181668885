import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Head = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

Head.defaultProps = {
  title: 'Loanpal'
};

Head.propTypes = {
  title: PropTypes.string
};

export default Head;