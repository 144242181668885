import axios from 'axios';

const http = async ({ method, endpoint = '', data, params, url = `https://api.loanpal.com` }) => {
  try {    
    const { data: response } = await axios({
      method,
      url: `${url}${endpoint}`,
      data,
      params      
    });
    console.log('response', response);
    return response;
  } catch (e) {
    console.error('HTTP error', e, url, endpoint);
    throw e;
  }
};

export const put = ({ endpoint, data, url }) => http({ method: 'PUT', endpoint, data, url });
export const post = ({ endpoint, data, url }) => http({ method: 'POST', endpoint, data, url });
export const get = ({ endpoint, data, url }) => http({ method: 'GET', endpoint, params: data, url });
export const del = ({ endpoint, data, url }) => http({ method: 'DELETE', endpoint, data, url });

export default {
  get,
  post,
  put,
  del
};
