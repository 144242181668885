import React from 'react';

var DATA = {
    "friends": [{
            "id": 1,            
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/devanteNikki-min.jpg",
            "photoUrl-480" : "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/devanteNikki480.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Devante_Nikki_012318_FINAL_1.mp4",      
            "name": "nikki & devante",
            "quote": "\"Our loan officer was there for us every step of the way. No matter what time of day. She made sure the process was as fluid as possible.\"",
            "signature": "- Devante"            
        },
        {
            "id": 2,
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/jenHector-min.jpg",
            "photoUrl-480": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/jenHector480.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Hector_Jen_012318_FINAL_1.mp4",   
            "name": "the sanchez family",
            "quote": "\"With Loanpal, it was quick and easy. I was amazed how stress-free and straight-forward the process was.\"",
            "signature": "- Hector"
        },
        {
            "id": 3,
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/cattBo-min.jpg",
            "photoUrl-480": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/cattBo_480.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Catt_Bo_012318_FINAL_1.mp4", 
            "name": "catt & bo",
            "quote": "\"I put all my trust in Loanpal. The process was flawless.\"",
            "signature": "- Catt"
        },
        {
            "id": 4,
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/soniaBianca-min.jpg",
            "photoUrl-480": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/soniaBianca480.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Sonia_Bianca_012318_FINAL_1.mp4",     
            "name": "sonia & bianca",
            "quote": "\"Being a first-time home buyer, I was really nervous, but working with Loanpal put me at ease. I felt like I was talking to a friend.\"",
            "signature": "- Bianca"
        },
        {
            "id": 5,
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/Robin-min.jpg",
            "photoUrl-480": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/Robin_480.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Robin_012318_FINAL_1.mp4",          
            "name": "robin",
            "quote": "\"Loanpal made the process feel very personal. They were always straight-forward. They were always transparent.\"",
            "signature": "- Robin"
        },
        {
            "id": 6,
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/Freda-min.jpg",
            "photoUrl-480": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/Freda_480.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Catt_Freda_012318_FINAL_1.mp4",    
            "name": "freda",
            "quote": "\"I always look for honesty and I got that from Loanpal.\"",
            "signature": "- Freda"
        },
        {
            "id": 7,
            "photoUrl": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/Mo-min.jpg",
            "photoUrl-480": "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/testimonials/Mo-480-min.jpg",
            "videoUrl": "https://res.cloudinary.com/loanpal/video/upload/vc_auto,q_auto/v1/loanpal.com/testimonials/videos/LP_Testimonial_Mo_012318_FINAL_1.mp4",             
            "name": "Mo",
            "quote": "\"Loanpal made it all easy. I was a little scared, but the team was behind me through the whole process.\"",
            "signature": "- Mo"
        }

    ]    
};

class FriendsRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupBkImage: ''
        }
        this.playVideo = this.playVideo.bind(this);
    }
    playVideo(e, id) {
        e.preventDefault();
        console.log("playVideo: ", id);
        var videoId = "video" + id;
        var vid = document.getElementById(videoId); 
        console.log("Video: ", vid)
        vid.play(); 
    }   
    render() {        
        return (
            <div className="row mb-20">
                <div className="container">
                    <div className="grid-item a">
                        <div className="friend-text">
                            <div className="brandon-bold friend-title">{this.props.friend.name}</div>
                            <div className="brandon-regular-italic friend-quote">{this.props.friend.quote}</div>
                            <div className="brandon-regular-italic friend-quote">{this.props.friend.signature}</div>
                        </div>
                    </div>    
                    <div className="grid-item b">
                        <div className="friend-pic">                        
                            <a className="no-border" href={this.props.friend.videoUrl} data-fancybox="true"  data-width="960" data-height="540">
                                <div className="video-thumbnail">
                                    <img src={this.props.friend.photoUrl} className="photo" alt={this.props.friend.name} />
                                </div>    
                            </a>                           
                        </div>
                    </div>        
                </div>                
                <FriendsMovie id={"video" + this.props.friend.id} videoUrl={this.props.friend.videoUrl} />
            </div>
        )
    }
}

class FriendsMovie extends React.Component {
    render() {
        return (
            <div className="testimonials-movie">
               <video className="myVideo" id={this.props.id} controls controlsList="nodownload">
                    <source src={this.props.videoUrl} type="video/mp4" />
                    {/* <source src="/resources/assets/HeaderSlideshow_720.webmhd.webm" type="video/webm" />
                    <source src="/resources/assets/HeaderSlideshow_720.oggtheora.ogv" type="video/ogg" />                     */}
               </video>
            </div>
        )
    }
}

class Friends extends React.Component {        
    render () {
        const data = DATA;             
        return(
            <div className="mt-40"> 
                <FriendsRow friend={data.friends[0]} />
                <FriendsRow friend={data.friends[1]} />
                <FriendsRow friend={data.friends[2]} />
                <FriendsRow friend={data.friends[5]} /> 
                <FriendsRow friend={data.friends[3]} />
                <FriendsRow friend={data.friends[4]} />
                <FriendsRow friend={data.friends[6]} />                               
            </div>    
        )
    }
}

export default Friends;
