import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import Modal from 'components/shared/modal-form';
import useModal from 'components/shared/modal-form/useModal.js';
import environment from 'environment';

import buttonOff from 'images/direct-pay/getStartedOff.png';
import buttonOn from 'images/direct-pay/getStartedOn.png';
import backgroundImage from 'images/direct-pay/panels-min.png';
import backgroundImageM from 'images/direct-pay/panelsM@2x-min.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        height: '345px',
        display: 'flex',
        flexWrap: 'nowrap',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${backgroundImageM})`,
            backgroundSize: 'cover',
            height: '175px',
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${backgroundImageM})`,
            height: '300px',
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${backgroundImage})`,
            height: '345px',
        }
    },
    title: {
        font: 'normal normal 900 60px/72px brandon-grotesque',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 900 36px/43px brandon-grotesque',
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 900 60px/72px brandon-grotesque',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 900 60px/72px brandon-grotesque',
        }
    },
    titleContainer: {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
        textAlign: 'center'
    },
    button: {
        cursor: 'pointer',
        marginTop: '30px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            width: '150px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '220px'
        },
        [theme.breakpoints.up('md')]: {
            width: '220px'
        }
    },
    desktop: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));

const ApplyToday = () => {
    const classes = useStyles();
    const { isShowing, toggle } = useModal();

    return (
        <section className={classes.container}>
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                <Grid item xs={12}>
                        <Box className={classes.desktop}>
                            <Box className={classes.title}>
                                <Box>
                                    apply today!
                                </Box>
                                <Box>
                                <img src={buttonOff} alt="Get Started" className={classes.button}
                                onMouseOver={e => e.currentTarget.src = buttonOn}
                                onMouseOut={e => e.currentTarget.src = buttonOff}
                                onClick={toggle} />
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.mobile}>
                            <Box className={classes.title}>
                                apply today!
                            </Box>
                            <Box>
                                <img src={buttonOff} alt="Get Started" className={classes.button}
                                onMouseOver={e => e.currentTarget.src = buttonOn}
                                onMouseOut={e => e.currentTarget.src = buttonOff}
                                onClick={toggle} />
                            </Box>
                        </Box>
                </Grid>
            </Grid>
            <div className="clearfix" />
            <Modal
                form="inquiry.partner"
                email={environment.communications.partnerInquiry}
                isShowing={isShowing}
                hide={toggle}
                directPay={true}
            />
        </section>
    );
}

export default ApplyToday;
