import React from "react";
import data from "./data";
import WarriorLogo from "images/warriors-of-light/warrior-logo.svg";
import Background from "images/warriors-of-light/background-orange.svg";
import PlayIcon from "images/warriors-of-light/play_icon.png";
import "./WarriorsOfLight.css";

function VideoPopupWrapper({ link, className = "", children }) {
  return link ? (
    <a
      className={className}
      href={link}
      data-fancybox="true"
      data-height="540"
      data-width="960"
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  );
}

function VideoCard({ id, title, description, picture, pictureAlt, videoLink }) {
  return (
    <div className="WL_VideoCard">
      <VideoPopupWrapper link={videoLink} className="WL_VideoLink">
        <div className="WL_PictureWrapper">
          <img src={picture} alt={pictureAlt} className="WL_Picture" />

          {videoLink !== "" && (
            <img src={PlayIcon} alt={pictureAlt} className="WL_PlayIcon" />
          )}
        </div>
        <div
          className="WL_TextWrapper"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <h2 className="WL_VideoId">Watch Episode {id}: </h2>
          <h3 className="WL_VideoTitle">"{title}"</h3>
          <p className="WL_VideoDescription">{description}</p>
        </div>
      </VideoPopupWrapper>
    </div>
  );
}

export default function index() {
  return (
    <div className="WL_Page">
      <div className="WL_Header">
        <img className="WL_Logo" src={WarriorLogo} alt="" />
      </div>
      <div className="WL_Content">
        <div className="WL_Grid">
          {data.chapters.map((chapter) => (
            <VideoCard key={chapter.title} {...chapter} />
          ))}
        </div>
      </div>
    </div>
  );
}
