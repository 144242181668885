import React from 'react';
import BugsForm from '../../../../shared/inquiry-form-customer';
import environment from 'environment';

const BugsContent = () => {    
    return(        
        <section className="section-home-improvement-form">            
            <div className="row paragraph">                     
                <p className="title-customers brandon-bold text-120 mt-40">Thank you for helping us make our website better. </p>
            </div>   
            <div className="inquiry-form row mt-20 mb-20">                    
                <BugsForm     
                    form="inquiry.bug"                                       
                    email={environment.communications.webmaster}                            
                    paragraph="none" 
                    disclaimerTextColor="text-white"
                    closeButton="false"                       
                />                              
            </div>                
        </section>        
    )    
}

export default BugsContent;
