import React from 'react';

class SlideshowMovie extends React.Component {
    render() {
        let sources = ""        
        if (this.props.movieUrl + "mp4.mp4" !== undefined) {
            sources = <video id={this.props.id} playsInline autoPlay muted loop poster={this.props.movieUrl + ".jpg"} >
            <source src={this.props.movieUrl + ".mp4.mp4"} type="video/mp4" />
            <source src={this.props.movieUrl + ".webmhd.webm"} type="video/webm" />
            <source src={this.props.movieUrl + ".oggtheora.ogv"} type="video/ogg" />                    
            </video>;
        } else {
            sources = <img alt="Loanpal" src={this.props.movieUrl + ".jpg"} />
        }
        return (
            <div className="slideshow-movie">
               {sources}
            </div>
        )
    }
}


export default SlideshowMovie;