import React from 'react';

class Slideshow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframe: ""
        };
    }
    createMarkup(html) { return {__html: html}; };
    render() {
        return (
            <div className={`slideshow ${this.props.style}`}>
                <div id={this.props.id} className="iframe-wrapper shared_content" data-content-guid={this.props.id + "_content"}>
                    <iframe title="loanpal" id={this.props.id + "_animation"} src={this.props.url} scrolling="no" frameBorder="0">
                        <div dangerouslySetInnerHTML={this.createMarkup(this.state.iframe)}></div>
                    </iframe>
                </div>
                <div className="clearfix"></div>
            </div>
        )
    }
}


export default Slideshow;