import React from "react";
import http from "../../../services/http_client";
import SingleInput from "../single-input";
import Snackbar from "../snackbar";

class InquiryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      address: "",
      company: "",
      systems: "",
      statesThatYouOperateIn: "",
      complaintInvolves: "",
      statementOfProblem: "",
      desiredResolution: "",
      bug: "",
      rightToKnowInformationYouHaveCollectedAboutMe: false,
      rightToDeleteNonExemptPersonalInformation: false,
      rightToProhibitSalesOfMyPersonalInformation: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.snackBarRef = React.createRef();
    this.getSubject = this.getSubject.bind(this);
  }
  clickSnackbar(snackBarMessage, variant) {
    this.snackBarRef.current.openSnackBar(snackBarMessage, variant);
  }
  validateForm() {
    if (this.props.form && this.props.form === "inquiry.bug") {
      if (this.state.email !== "" && this.state.name !== "") {
        return true;
      } else {
        return false;
      }
    } else if (this.props.form && this.props.form === "inquiry.partner") {
      if (
        this.state.email !== "" &&
        this.state.name !== "" &&
        this.state.phone !== ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.state.email !== "" &&
        this.state.name !== "" &&
        this.state.phone !== "" &&
        this.state.address !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  getSubject() {
    if (
      (!this.props.form && this.props.pathname === "/green-energy") ||
      this.props.form === "inquiry.solar"
    )
      return "Loanpal solar inquiry submitted by";
    if (this.props.form === "inquiry.homeImprovement")
      return "Loanpal home improvement inquiry from";
    if (this.props.form === "inquiry.partner")
      return this.props.directPay
        ? "Loanpal solar partner - direct pay inquiry from"
        : "Loanpal solar partner inquiry from";
    if (this.props.form === "inquiry.consumer.doNotSellInfo")
      return "Loanpal consumer do not sell my information from";
    if (this.props.form === "inquiry.consumer.complaints")
      return "Loanpal consumer complaint from";
    if (this.props.form === "inquiry.bug") return "Loanpal bug found from";
    return "Loanpal mortgage inquiry from";
  }
  async handleFormSubmit(e) {
    e.preventDefault();
    if (!this.validateForm()) {
      this.clickSnackbar(
        "Oh Snap! Please enter the required fields!",
        "warning"
      );
      return false;
    } else {
      let subject = this.getSubject();
      let jsonBody = {};
      if (this.props.form && this.props.form === "inquiry.partner") {
        jsonBody = {
          emailTo: this.props.email,
          emailFrom: this.state.email,
          subject: subject + " " + this.state.name,
          name: this.state.name,
          phone: this.state.phone,
          company: this.state.company,
          installs: this.state.systems,
          statesOfOperation: this.state.statesThatYouOperateIn,
        };
      } else if (
        this.props.form &&
        this.props.form === "inquiry.consumer.complaints"
      ) {
        jsonBody = {
          emailTo: this.props.email,
          emailFrom: this.state.email,
          subject: subject + " " + this.state.name,
          name: this.state.name,
          phone: this.state.phone,
          address: this.state.address,
          complaintInvolves: this.state.complaintInvolves,
          statementOfProblem: this.state.statementOfProblem,
          desiredResolution: this.state.desiredResolution,
        };
      } else if (
        this.props.form &&
        this.props.form === "inquiry.consumer.doNotSellInfo"
      ) {
        jsonBody = {
          emailTo: this.props.email,
          emailFrom: this.state.email,
          subject: subject + " " + this.state.name,
          name: this.state.name,
          phone: this.state.phone,
          address: this.state.address,
          rightToKnowInformationYouHaveCollectedAboutMe: this.state
            .rightToKnowInformationYouHaveCollectedAboutMe
            ? "[x]"
            : "[]",
          rightToDeleteNonExemptPersonalInformation: this.state
            .rightToDeleteNonExemptPersonalInformation
            ? "[x]"
            : "[]",
          desiredResrightToProhibitSalesOfMyPersonalInformationolution: this
            .state.rightToProhibitSalesOfMyPersonalInformation
            ? "[x]"
            : "[]",
        };
      } else if (this.props.form && this.props.form === "inquiry.bug") {
        jsonBody = {
          emailTo: this.props.email,
          emailFrom: this.state.email,
          subject: subject + " " + this.state.name,
          name: this.state.name,
          bug: this.state.bug,
        };
      } else {
        jsonBody = {
          emailTo: this.props.email,
          emailFrom: this.state.email,
          subject: subject + " " + this.state.name,
          name: this.state.name,
          phone: this.state.phone,
          address: this.state.address,
        };
      }
      console.log("Send this request POST: ", jsonBody);

      const result = await http.post({
        endpoint: "/support/sendEmail",
        data: jsonBody,
      });
      console.log(result);
      if (result && result.message === "Message Sent") {
        this.clickSnackbar("Thank you! Your message has been sent.", "success");
        this.handleClearForm();
      } else {
        this.clickSnackbar(
          "Oh Snap! And error occurred, please try again.",
          "error"
        );
      }
    }
  }
  handleClearForm() {
    this.setState({
      name: "",
      phone: "",
      email: "",
      address: "",
      company: "",
      systems: "",
      statesThatYouOperateIn: "",
      complaintInvolves: "",
      statementOfProblem: "",
      desiredResolution: "",
      bug: "",
      rightToKnowInformationYouHaveCollectedAboutMe: false,
      rightToDeleteNonExemptPersonalInformation: false,
      rightToProhibitSalesOfMyPersonalInformation: false,
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox"
          ? !this.state[e.target.name]
          : e.target.value,
    });
  }
  render() {
    const { form, paragraph, disclaimerTextColor, closeButton } = this.props;
    return (
      <div id="inquiry-form-partner" style={{ margin: "20px" }}>
        {closeButton && closeButton === true && (
          <div
            className="close-button float-right pointer"
            onClick={this.props.closeModal}
          >
            <span className="fa-stack fa-sm">
              <i className="far fa-circle-o fa-stack-2x"></i>
              <i className="fa fa-times fa-stack-1x"></i>
            </span>
          </div>
        )}
        {paragraph && paragraph !== "none" && (
          <div className="row">
            <div className="title-partners brandon-bold">
              {this.props.paragraph}
            </div>
          </div>
        )}
        {!paragraph && (
          <div className="row">
            <div className="title-partners brandon-bold">
              Interested in learning about the benefits of a solar solution for
              your home?
            </div>
            <div className="title-partners brandon-bold">
              Simply enter your information, and one of our certified solar
              partners will contact you.
            </div>
          </div>
        )}
        <form
          id="email_form_solar"
          onSubmit={this.handleFormSubmit}
          method="post"
        >
          <div className="mb-1" style={{ fontSize: "80%", textAlign: "left" }}>
            <span style={{ color: "#c20000" }}>*</span>Required fields
          </div>
          <SingleInput
            inputType={"text"}
            title={"Name"}
            required={true}
            name={"name"}
            controlFunc={this.handleChange}
            content={this.state.name}
            placeholder={"Enter Name"}
          />{" "}
          {/* Full name text input */}
          {form === "inquiry.partner" && (
            <SingleInput
              inputType={"text"}
              title={"Company Name"}
              required={true}
              name={"company"}
              controlFunc={this.handleChange}
              content={this.state.company}
              placeholder={"Enter Company Name"}
            />
          )}
          {form !== "inquiry.bug" && form !== "inquiry.partner" && (
            <SingleInput
              inputType={"text"}
              title={"Home Phone"}
              required={true}
              name={"phone"}
              controlFunc={this.handleChange}
              content={this.state.phone}
              placeholder={"Enter Phone Number"}
            />
          )}
          <SingleInput
            inputType={"text"}
            title={"Email"}
            required={true}
            name={"email"}
            controlFunc={this.handleChange}
            content={this.state.email}
            placeholder={"Enter Email"}
          />{" "}
          {/* Email text input */}
          {form !== "inquiry.bug" && form !== "inquiry.partner" && (
            <SingleInput
              inputType={"text"}
              title={"Home Address"}
              required={true}
              name={"address"}
              controlFunc={this.handleChange}
              content={this.state.address}
              placeholder={"Enter Address"}
            />
          )}
          {form === "inquiry.partner" && (
            <SingleInput
              inputType={"text"}
              title={"Phone"}
              required={true}
              name={"phone"}
              controlFunc={this.handleChange}
              content={this.state.phone}
              placeholder={"Enter Phone Number"}
            />
          )}
          {form === "inquiry.partner" && (
            <SingleInput
              inputType={"text"}
              title={"Average Installs per month"}
              required={true}
              name={"systems"}
              controlFunc={this.handleChange}
              content={this.state.systems}
              placeholder={"Enter the average system installs per month"}
            />
          )}
          {form === "inquiry.partner" && (
            <SingleInput
              inputType={"text"}
              title={"States that you operate in"}
              required={true}
              name={"statesThatYouOperateIn"}
              controlFunc={this.handleChange}
              content={this.state.statesThatYouOperateIn}
              placeholder={"States of Operation"}
            />
          )}
          {form === "inquiry.bug" && (
            <div className="form-group">
              <label className="form-label">
                Please describe the bug. (required)
              </label>
              <textarea
                className="form-input"
                name="bug"
                value={this.state.bug}
                onChange={this.handleChange}
                placeholder="Type explanation"
              ></textarea>
            </div>
          )}
          {form === "inquiry.consumer.complaints" && (
            <>
              <div className="form-group">
                <label className="form-label">
                  Complaint Involves{" "}
                  <span style={{ color: "#c20000", marginLeft: "2px" }}>*</span>
                </label>
                <textarea
                  className="form-input"
                  name="complaintInvolves"
                  value={this.state.complaintInvolves}
                  onChange={this.handleChange}
                  placeholder="Type explanation"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="form-label">Statement of Problem</label>
                <textarea
                  className="form-input"
                  name="statementOfProblem"
                  value={this.state.statementOfProblem}
                  onChange={this.handleChange}
                  placeholder="Type explanation"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="form-label">Desired Resolution</label>
                <textarea
                  className="form-input"
                  name="desiredResolution"
                  value={this.state.desiredResolution}
                  onChange={this.handleChange}
                  placeholder="Type explanation"
                ></textarea>
              </div>
            </>
          )}
          {form === "inquiry.consumer.doNotSellInfo" && (
            <div className="mt-50 mb-50">
              <div className="input-checkbox">
                <SingleInput
                  title={""}
                  required={false}
                  inputType="checkbox"
                  name={"rightToKnowInformationYouHaveCollectedAboutMe"}
                  controlFunc={this.handleChange}
                  checked={
                    this.state.rightToKnowInformationYouHaveCollectedAboutMe
                  }
                />
                <p className="input-checkbox-label">
                  Right to Know Personal Information You’ve collected about me
                </p>
              </div>
              <div className="input-checkbox">
                <SingleInput
                  title={""}
                  required={false}
                  inputType="checkbox"
                  name={"rightToDeleteNonExemptPersonalInformation"}
                  controlFunc={this.handleChange}
                  checked={this.state.rightToDeleteNonExemptPersonalInformation}
                />
                <p className="input-checkbox-label">
                  Right to Delete non-exempt Personal Information
                </p>
              </div>
              <div className="input-checkbox">
                <SingleInput
                  title={""}
                  required={false}
                  inputType="checkbox"
                  name={"rightToProhibitSalesOfMyPersonalInformation"}
                  controlFunc={this.handleChange}
                  checked={
                    this.state.rightToProhibitSalesOfMyPersonalInformation
                  }
                />
                <p className="input-checkbox-label">
                  Right to Prohibit Sales of My Personal Information
                </p>
              </div>
            </div>
          )}
          {form === "inquiry.consumer.doNotSellInfo" && (
            <div
              className={`brandon-light ${disclaimerTextColor} text-left text-90 clearfix disclaimer mt-20`}
            >
              <p>
                By clicking "SUBMIT" you are confirming your specific selections
                above and, as applicable, directing Loanpal to:
              </p>
              <ul className="mt-20 mb-20" style={{ marginLeft: "40px" }}>
                <li>provide me with my collected Personal Information, or</li>
                <li>
                  delete any non-exempt Personal Information it has collected,
                  or
                </li>
                <li>not sell my Personal Information to third parties</li>
              </ul>
              <p>
                I understand that Loanpal will need to verify my identity before
                responding to this request. This request will not have any
                effect on the price or quality of any services or financing that
                I may obtain from Loanpal.
              </p>
            </div>
          )}
          {form !== "inquiry.consumer.doNotSellInfo" && (
            <div
              className={`brandon-light ${disclaimerTextColor} text-left text-90 clearfix disclaimer mt-20`}
            >
              By clicking "submit", you are authorizing Loanpal, LLC and its
              corporate parent, affiliates and partners to deliver or cause to
              be delivered to you (including through agents and authorized
              third-parties) telemarketing promotions for products or services
              in addition to those about which you are applying, but that may be
              of interest to you using an automatic telephone dialing system or
              an artificial or prerecorded voice and text messages to the phone
              numbers you provided above. You are not required to sign this
              agreement as a condition of purchasing any property, goods, or
              services.
            </div>
          )}
          <input
            type="submit"
            className={this.props.btn + " mt-20 text-uppercase"}
            value="Submit"
          />
          <Snackbar ref={this.snackBarRef} />
        </form>
      </div>
    );
  }
}

export default InquiryForm;
