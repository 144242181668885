import React from "react";
import UnderHeader from "../../shared/header/under-header";
import FastFrictionlessFriendly from "./components/fast-frictionless-friendly";
import PartnersVideo from "./components/partners-video";
import BannerStrip from "./components/banner-strip";
import HowItWorks from "./components/how-it-works";
import PartnersAndCustomers from "./components/partners-and-customers";
import ProvenLeadership from "./components/proven-leadership";
import environment from "environment";
import Head from "../../shared/head";
import LoanpalPros from "../../shared/LoanpalPros/LoanpalPros.js";

let slideshow = null //require("@loanpal/slideshow");

const heroImageContent = (
  <div id="hero-green-energy" className="hero-green-energy">
    <div className="row">
      <h1 className="brandon-black">SAVE MONEY.</h1>
      <h1 className="brandon-black">SAVE THE PLANET.</h1>
      <h4 className="brandon-medium">
        be a solar power superhero with a faster friendlier lending partner!
      </h4>
    </div>
  </div>
);

class GreenEnergy extends React.Component {
  componentDidMount() {
    let opts = {
      //auto-advancing slides? accepts boolean (true/false) or object
      auto: {
        // speed to advance slides at. accepts number of milliseconds
        speed: 9000,
        // pause advancing on mouseover? accepts boolean
        pauseOnHover: false,
      },
      // show fullscreen toggle? accepts boolean
      fullScreen: false,
      // support swiping on touch devices? accepts boolean, requires hammer.js
      swipe: false,
    };
    //slideshow.makeBSS(".diy-slideshow", opts);
  }
  render() {
    return (
      <>
        <Head title={`Loanpal ${window.location.pathname.split("/")[1]}`} />
        <UnderHeader
          image="img-container-hero-green-energy"
          getStarted="solar"
          phoneNumber="1-844-910-0111"
          bgColor=""
          border=""
          heroImageContent={heroImageContent}
          showDIYSlideshow={true}
          showSlideshowMovie={true}
          movie="solar-video"
          movieUrl="/resources/assets/green_energy/SolarPageSlideShow720Mobile_v3"
        />
        {false && <FastFrictionlessFriendly />}

        <LoanpalPros />

        <PartnersVideo bannerImage="banner-partners-video" />
        <BannerStrip
          button={true}
          form="inquiry.partner"
          email={environment.communications.partnerInquiry}
          bannerButtonSrc="/resources/img/green-energy/slideshow/learn-more-button.png"
          bannerButtonHoverSrc="/resources/img/green-energy/slideshow/learn-more-button-hover.png"
          bannerImage="banner-working-capital"
          alt="Working Capital"
          class="img-container-green-energy-slideshow"
        />
        <HowItWorks />
        <BannerStrip
          button={true}
          form="inquiry.partner"
          email={environment.communications.partnerInquiry}
          bannerButtonSrc="/resources/img/green-energy/letsGoButton.png"
          bannerButtonHoverSrc="/resources/img/green-energy/letsGoButtonHover.png"
          bannerImage="banner-chameleon"
          class="img-container-green-energy-slideshow"
        />
        <PartnersAndCustomers />
        <BannerStrip
          button={true}
          form="inquiry.solar"
          bannerButtonSrc="/resources/img/green-energy/letsGoButton.png"
          bannerButtonHoverSrc="/resources/img/green-energy/letsGoButtonHover.png"
          bannerImage="banner-giraffe"
          class="img-container-green-energy-slideshow"
        />
        <ProvenLeadership />
      </>
    );
  }
}

export default GreenEnergy;
