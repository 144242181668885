import React from 'react';
import UnderHeader from '../../../shared/header/under-header';

import Covid19Contents from './components/covid-19-contents';
import Head from '../../../shared/head';

const Covid19 = () => {
    const heroImageContent = (
    <div id="hero-footer" className="hero-footer hero-footer-center">        
        <h2 className="brandon-light">Covid-19 Resource Guide</h2>        
    </div>); 
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="img-container-hero-footer-pages-2"                                
                bgColor=""
                border=""
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />   
            <Covid19Contents />                                
        </>                
    )
}

export default Covid19;