import React from 'react';
import UnderHeader from '../../shared/header/under-header';
import Modal from '../../shared/modal-form';
import useModal from '../../shared/modal-form/useModal.js';
import environment from 'environment';

import FiveMinutesOrLess from './components/five-minutes-or-less';
import PutTheHammerDown from './components/put-the-hammer-down';
import WhatOurCustomersSay from './components/what-our-customers-say';
import OurExperienceStandsOut from './components/our-experience-stands-out';
import PieceOfMindPromise from '../mortgage/components/piece-of-mind-promise';
import Head from '../../shared/head';

const Refinance = () => {    
    const { isShowing, toggle } = useModal();
    const heroImageContent = (    
        <div id="hero-refinance" className="hero-refinance">
            <div className="row">
                <h2>a refinance that's</h2>
                <h1 className="brandon-black">so fast &amp; friendly,</h1>
                <h2>you'll be sittin' pretty in no time.</h2>
            </div>
        </div>
    );    
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="img-container-hero-refinance"                
                getStarted="solar"
                phoneNumber="1-844-910-0111"
                bgColor=""
                border="hero-border"
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />
            <FiveMinutesOrLess toggle={toggle} />
            <PutTheHammerDown />
            <WhatOurCustomersSay />
            <OurExperienceStandsOut />
            <PieceOfMindPromise toggle={toggle} />
            <Modal                   
                form="inquiry.mortgage"
                email={environment.communications.mortgageInquiry}
                isShowing={isShowing}
                hide={toggle}            
            />                       
        </>                
    )
}

export default Refinance;