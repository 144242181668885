import React from 'react';
import UnderHeader from '../../shared/header/under-header';
import Modal from '../../shared/modal-form';
import useModal from '../../shared/modal-form/useModal.js';
import environment from 'environment';

import ControlClarityConfidence from './components/control-clarity-confidence';
import HowItWorks from './components/how-it-works';
import PeaceOfMindPromise from './components/piece-of-mind-promise';
import Head from '../../shared/head';


const Mortgage = () => {  
    const { isShowing, toggle } = useModal();
    const heroImageContent = (    
        <div id="hero-mortgage" className="hero-mortgage">
            <h1 className="brandon-black">NO hidden costs. ZERO lender fees.</h1>                            
            <h1 className="brandon-black">AWESOME RATES that won't break your bank.</h1>                            
            <h4>(so you can put the hammer down)</h4>
            <button className="btn btn-full uppercase mt-30" onClick={toggle}>Get Approved!</button>
        </div>
    );
    const promiseHeader = (        
        <div className="title brandon-black">
            <span className="text-gray">like a </span> 
            <span className="text-orange">good friend</span>
            <span className="text-gray">, we have your back.</span>            
        </div>
    )
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="img-container-hero-mortgage"                
                getStarted="solar"
                phoneNumber="1-844-910-0111"
                bgColor=""
                border="hero-border"
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />
            <ControlClarityConfidence /> 
            <HowItWorks />
            <PeaceOfMindPromise toggle={toggle} header={promiseHeader} />
            <Modal                   
                form="inquiry.mortgage"
                email={environment.communications.mortgageInquiry}
                isShowing={isShowing}
                hide={toggle}            
            />                       
        </>                
    )
}

export default Mortgage;