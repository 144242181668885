import React from 'react';
import { Link } from 'react-router-dom';

class Testimonials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframe: ""
        };
    }
    createMarkup(html) { return {__html: html}; };
    render () {
        return(
            <section className="section-testimonials js--section-testimonials" id="testimonials">
                <div className="title mt-20 brandon-black">
                    what our customers say
                </div>
                <div className="row">
                    <img alt="Customer Survey Stats" className="claims" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/CustomerSurvey.svg" />
                    <img alt="Customer Survey Stats" className="claims-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/CustomerSurvey.svg" />
                </div>
                <div className="testimonial_slideshow">
                    <iframe title="customertestimonials" id="testimonial_animation" src="/resources/assets/SlideShow_Testimonials/SlideshowMainPageTestimonials.html" scrolling="no" frameBorder="0">
                        <div dangerouslySetInnerHTML={this.createMarkup(this.state.iframe)}></div>
                    </iframe>
                    <div className="clearfix"></div>
                </div>
                <div className="row mb-40 view-customers-stories">
                    <Link className="btn btn-full uppercase view-more" to="/testimonials.html">View customer stories <i className="fa fa-2x fa-arrow-circle-right ml-10" aria-hidden="true"></i></Link>
                </div>
        </section>
        )
    }
}

export default Testimonials;
