import React from "react";

import "./LoanpalPro.css";

import badgeApple from "images/loanpalpros/badge_apple.png";
import badgeGoogle from "images/loanpalpros/badge_google.png";
import app_store from "images/loanpalpros/loanpal_pro_app_store@2x.png";
import background_smile from "images/loanpalpros/loanpal_pro_background_smile.png";
import phone from "images/loanpalpros/loanpal_pro_phone@2x.png";
import screen from "images/loanpalpros/loanpal_pro_screen@2x.png";
import video_desktop from "images/loanpalpros/loanpal_pro_video_desktop@2x.png";

export default function LoanpalPros() {
  return (
    <div
      className="LoanpalPro"
      style={{ backgroundImage: `url(${background_smile})` }}
    >
      <div className="LoanpalPro__PictureGroup LoanpalPro__PictureGroup--first LoanpalPro__PictureGroup--desktop">
        <div className="PictureContainer PictureContainer--video-desktop">
          <a className="no-border" href={'https://youtu.be/zDUAc0xi5B8?autoplay=1'} data-width="960" data-height="540" data-fancybox="true">
            <img
              src={video_desktop}
              alt="Watch the video featuring Tony Gonzales Pro Footbal Hall of Fame"
            />
          </a>
        </div>
        <div className="PictureContainer PictureContainer--appstore">
          <img src={app_store} alt="Loanpal Pros on the Appstore" />
        </div>
      </div>
      <div className="LoanpalPro__Content">
        <p className="LoanpalPro__Eyebrow">New</p>
        <h2 className="LoanpalPro__Title">Introducing Loanpal Pros</h2>
        <p className="LoanpalPro__Paragraph">
          The <strong>Loanpal Pros app is a powerful sales tool</strong> for
          Loanpal partners and dealers that offer solar & battery financing to
          customers.
        </p>
        <ul className="LoanpalPro__List">
          <li>
            <span>
              Get a complete pipeline view for every client on the Loanpal
              platform
            </span>
          </li>
          <li>
            <span>Compare rates and loan options</span>
          </li>
          <li>
            <span>Submit documents</span>
          </li>
          <li>
            <span>Complete a loan application</span>
          </li>
          <li>
            <span>Get borrower approval in seconds</span>
          </li>
        </ul>
        <p className="LoanpalPro__Paragraph LoanpalPro__Paragraph--padded-right LoanpalPro__PictureGroup--desktop">
          All with the touch of a button on your mobile device!
        </p>
        <p className="LoanpalPro__Paragraph LoanpalPro__Paragraph--padded-right LoanpalPro__PictureGroup--mobile">
          Compare rates, submit documents, complete an application and get approval in seconds – all with the touch of a button on your mobile device!
        </p>
      </div>
      <div className="LoanpalPro__PictureGroup LoanpalPro__PictureGroup--second LoanpalPro__PictureGroup--desktop">
        <div className="PictureContainer PictureContainer--screen">
          <img src={screen} alt="Loanpal Pros on your smartphone" />
        </div>
        <div className="LoanpalPro__Stores">
          <span className="LoanpalPro__Stores_Title">Available at:</span>
          <div className="LoanpalPro__Stores_Links">
            <a
              href="https://apps.apple.com/us/app/loanpal-pros/id1494023388"
              target="_blank"
              rel="noopener noreferrer"
              alt="Download Loanpal Pros on the App Store"
              name="Download Loanpal Pros on the App Store"
            >
              <img
                src={badgeApple}
                alt="Download Loanpal Pros on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.loanpal.loanpal_pros"
              target="_blank"
              rel="noopener noreferrer"
              alt="Get Loanpal Pros on Google Play"
              name="Get Loanpal Pros on Google Play"
            >
              <img src={badgeGoogle} alt="Get Loanpal Pros on Google Play" />
            </a>
          </div>
        </div>
      </div>
      <div className="LoanpalPro__PictureGroup LoanpalPro__PictureGroup--third LoanpalPro__PictureGroup--mobile">
        <div className="PictureContainer">
        <a className="no-border" href={'https://youtu.be/zDUAc0xi5B8?autoplay=1'} data-width="960" data-height="540" data-fancybox="true">
            <img
              src={video_desktop}
              alt="Watch the video featuring Tony Gonzales Pro Footbal Hall of Fame"
            />
          </a>
        </div>
        <div className="PictureContainer">
          <img src={phone} alt="Loanpal Pros on your smartphone" />
        </div>
        <div className="LoanpalPro__Stores">
          <span className="LoanpalPro__Stores_Title">Available at:</span>
          <div className="LoanpalPro__Stores_Links">
            <a
              href="https://apps.apple.com/us/app/loanpal-pros/id1494023388"
              target="_blank"
              rel="noopener noreferrer"
              alt="Download Loanpal Pros on the App Store"
              name="Download Loanpal Pros on the App Store"
            >
              <img
                src={badgeApple}
                alt="Download Loanpal Pros on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.loanpal.loanpal_pros"
              target="_blank"
              rel="noopener noreferrer"
              alt="Get Loanpal Pros on Google Play"
              name="Get Loanpal Pros on Google Play"
            >
              <img src={badgeGoogle} alt="Get Loanpal Pros on Google Play" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
