import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

import aeesolar from 'images/direct-pay/partners/aee-solar.png';
import capital from 'images/direct-pay/partners/capital-electric.png';
import ced from 'images/direct-pay/partners/ced-greentech.png';
import ces from 'images/direct-pay/partners/city-electric-supply.png';
import codale from 'images/direct-pay/partners/codale-logo-bw.png';
import cooper from 'images/direct-pay/partners/cooper-electric.png';
import crescent from 'images/direct-pay/partners/crescent-electric.png';
import eco from 'images/direct-pay/partners/eco.png';
import ecopro from 'images/direct-pay/partners/ecopro.png';
import finalmile from 'images/direct-pay/partners/FinalMileSolar.png';
import fortune from 'images/direct-pay/partners/fortune-energy.png';
import gexpro from 'images/direct-pay/partners/gexpro.png';
// import graybar from 'images/direct-pay/partners/graybar.png';
import independent from 'images/direct-pay/partners/ies.png';
import krannich from 'images/direct-pay/partners/krannich.png';
import leveredge from 'images/direct-pay/partners/leveredge.png';
import mcmc from 'images/direct-pay/partners/mcmc-vertical.png';
import next from 'images/direct-pay/partners/next-energy.png';
import onesource from 'images/direct-pay/partners/one-source.png';
import rexel from 'images/direct-pay/partners/Rexel.png';
import sonepar from 'images/direct-pay/partners/sonepar.png';
import soligent from 'images/direct-pay/partners/soligent.png';
import sunrgy from 'images/direct-pay/partners/sunrgy.png';
import uma from 'images/direct-pay/partners/uma.png';
import usrs from 'images/direct-pay/partners/USRenewableSolutions.png';
import wesco from 'images/direct-pay/partners/wesco-renewables.png';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        padding: '50px 90px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 0'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '20px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '20px'
        }
    },
    title: {
        font: 'normal normal 900 42px/66px brandon-grotesque',
        color: '#00838F',
        marginBottom: '64px',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 900 30px/43px brandon-grotesque',
            padding: '20px',
            marginBottom: '10px'
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 900 42px/66px brandon-grotesque',
            padding: '20px',
            marginBottom: '10px'
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 900 42px/66px brandon-grotesque',
            padding: '20px',
            marginBottom: '10px'
        }
    },
    workflowGraphic: {
        width: '100%'
    },
    partner: {
        height: '220px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '110px'
        },
        [theme.breakpoints.up('sm')]: {
            height: '130px',
            minHeight: '165px'
        },
        [theme.breakpoints.up('md')]: {
            height: '220px',
            minHeight: '185px'
        }
    },
    partnerImg: {
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '60%'
        },
        [theme.breakpoints.up('md')]: {
            width: '70%'
        }
    },
    partnerImg2: {
        width: '11%',
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '22%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '22%',
            margin: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            width: '11%',
            margin: theme.spacing(6),
        }
    },
    logosWrap: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        },
        [theme.breakpoints.up('sm')]: {
            margin: 0
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 85px'
        }
    }
}));

const Partners = () => {
    const classes = useStyles();
    return (
        <section>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Box m={1}
                    className={classes.title}>
                    our partners
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.logosWrap}>
                        <img src={aeesolar} alt="AEE Solar" className={classes.partnerImg2} />
                        <img src={capital} alt="Capital Electirc - A Sonepar Company" className={classes.partnerImg2} />
                        <img src={ced} alt="CED Greentech Riverside" className={classes.partnerImg2} />
                        <img src={ces} alt="City Electric Supply" className={classes.partnerImg2} />
                        <img src={codale} alt="Codale" className={classes.partnerImg2} />
                        <img src={cooper} alt="Cooper Electric" className={classes.partnerImg2} />
                        <img src={crescent} alt="Crescent Electric Supply Company" className={classes.partnerImg2} />
                        <img src={eco} alt="Eco Distributing" className={classes.partnerImg2} />
                        <img src={ecopro} alt="Ecopro Solar Supply" className={classes.partnerImg2} />
                        <img src={finalmile} alt="Final Mile Solar" className={classes.partnerImg2} />
                        <img src={fortune} alt="Fortune Energy" className={classes.partnerImg2} />
                        <img src={gexpro} alt="Gexpro" className={classes.partnerImg2} />
                        {/* <img src={graybar} alt="GraybaR" className={classes.partnerImg2} /> */}
                        <img src={independent} alt="Independent Electric - A Sonepar Company" className={classes.partnerImg2} />
                        <img src={krannich} alt="Krannich" className={classes.partnerImg2} />
                        <img src={leveredge} alt="Leveredge" className={classes.partnerImg2} />
                        <img src={mcmc} alt="McNaughton-McKay" className={classes.partnerImg2} />
                        <img src={next} alt="Next Energy Alliance" className={classes.partnerImg2} />
                        <img src={onesource} alt="OneSource - A Sonepar Company" className={classes.partnerImg2} />
                        <img src={rexel} alt="Rexel" className={classes.partnerImg2} />
                        <img src={sonepar} alt="Sonepar" className={classes.partnerImg2} />
                        <img src={soligent} alt="Soligent" className={classes.partnerImg2} />
                        <img src={sunrgy} alt="Sunrgy Solar Distribution" className={classes.partnerImg2} />
                        <img src={uma} alt="UMA Solar" className={classes.partnerImg2} />
                        <img src={usrs} alt="U.S. Renewable Solutions" className={classes.partnerImg2} />
                        <img src={wesco} alt="Wesco" className={classes.partnerImg2} />
                    </Box>
                </Grid>
            </Grid>
            <div className="clearfix" />
        </section>
    );
}

export default Partners;
