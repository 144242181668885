import React from 'react';

const HowItWorks = () => {    
    return(
        <div>
        <section className="section-mortgage-two border-top-orange">
        <div className="top-orange-bar text-uppercase text-120">
            how it works
        </div>
        <div className="row">
            <img className="text-center hide-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/howitworks.svg" alt="How it works" />
            <img className="text-center show-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/howitworks_poster_mobile.png" alt="how it works" />
        </div>            
    </section>
        </div>
    )    
}

export default HowItWorks;