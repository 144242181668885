import React from 'react';
import UnderHeader from '../../../shared/header/under-header';
import { useMediaQuery } from 'react-responsive';

import CalculatorDesktop from './components/desktop';
import CalculatorMobile from './components/mobile';
import Head from '../../../shared/head';

const Calculator = () => {    
    const heroImageContent = "";
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })      
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })    
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image=""                                
                bgColor=""
                border=""
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />                                                   
        { isDesktopOrLaptop && <>
            <CalculatorDesktop />
            </>
        }
        { isTabletOrMobileDevice && <>
            <CalculatorMobile />
          </>
        }          
        </>                
    )
}

export default Calculator;