import React from 'react';
import HeroImage from '../../../shared/hero-image';

const PutTheHammerDown = () => {                
    const heroImageContent = (
        <div id="hero-mortgage" className="hero-mortgage">
            <h1 className="brandon-black">NO hidden costs. ZERO lender fees.</h1>                            
            <h1 className="brandon-black">AWESOME RATES that won't break your bank.</h1>
            <br />
            <h4>(so you can put the hammer down)</h4>                                
        </div>
        );
        return(            
            <section className="section-refinance-two">            
             <HeroImage image="img-container-hero-mortgage" border="hero-border">
                  {heroImageContent}
              </HeroImage>
            </section>            
        )    
}

export default PutTheHammerDown;