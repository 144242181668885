import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper } from '@material-ui/core';

import cog from 'images/direct-pay/cog.svg';
import bullseye from 'images/direct-pay/bullseye.svg';
import board from 'images/direct-pay/board.svg';
import cash from 'images/direct-pay/cash.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    container: {
        paddingTop: '20px',
        paddingBottom: 0
    },
    title: {
        font: 'normal normal 900 42px/66px brandon-grotesque',
        color: '#00838F',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 900 30px/30px brandon-grotesque',
            margin: '0 45px',
            marginBottom: '20px'
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 900 42px/42px brandon-grotesque',
            maxWidth: '65%',
            margin: '0 auto',
            marginBottom: '20px'
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 900 42px/66px brandon-grotesque',
        }
    },
    paragraph: {
        font: 'normal normal 500 24px/32px brandon-grotesque',
        letterSpacing: 0,
        margin: '40px 22%',
        color: '#00838F',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 500 18px/23px brandon-grotesque',
            margin: '0 20px 50px 20px'
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 500 22px/30px brandon-grotesque',
            margin: '0 20px 50px 20px'
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 500 24px/32px brandon-grotesque',
            margin: '20px 22%',
        }
    },
    fourSquares: {
        padding: '20px 20%',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#00838F',
            padding: '20px 10px',
            '& img': {
                width: '60px'
            }
        },
        [theme.breakpoints.up('sm')]: {
            backgroundColor: '#00838F',
            padding: '0 12%',
            '& img': {
                width: '98px'
            }
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#ffffff',
            padding: '20px 20%',
            '& img': {
                width: '98px'
            }
        }
    },
    fourSquaresItem: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: '0 !important'
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
        }
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '10px',
        minHeight:  '500px',
        [theme.breakpoints.down('sm')]: {
            minHeight: 0
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: '350px'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '350px'
        }
    },
    boxTitle:{
        font: 'normal normal 900 30px/36px brandon-grotesque',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 900 20px/29px brandon-grotesque',
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 900 30px/36px brandon-grotesque',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 900 30px/36px brandon-grotesque',
        }
    },
    boxDescription: {
        font: 'normal normal 500 24px/32px brandon-grotesque',
        [theme.breakpoints.down('sm')]: {
            margin: '0 30px',
            font: 'normal normal 500 14px/20px brandon-grotesque',
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0 30px',
            font: 'normal normal 500 24px/32px brandon-grotesque',
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 30px',
            font: 'normal normal 500 24px/32px brandon-grotesque',
        }
    },
    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));

const SectionOne = () => {
    const classes = useStyles();
    return (
        <section className={classes.container}>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.title}>
                    <div className={classes.mobile}>
                        we’ll handle the bill.<br />you grow your business.
                    </div>
                    <div className={classes.desktop}>
                        we’ll handle the bill. you grow your business.
                    </div>
                    </Box>
                    <Box className={classes.paragraph}>
                    We’ve partnered with top distributors across the country to create a program where we pay them directly for equipment, so you can unleash more capital to grow your business. Plus, you’ll be able to join Loanpal’s industry-leading platform, and enjoy the benefits of fast, frictionless financing.
                    </Box>
                </Grid>
            </Grid>
            <div className={classes.root}>
                <Grid container className={classes.fourSquares}>
                    <Grid item xs={12} sm={12} md={6} className={classes.fourSquaresItem}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={cash} alt="Open Your Credit Lines"  />
                            <Box m={2} className={classes.boxTitle}>open your credit lines</Box>
                            <div className={classes.boxDescription}>Get the working capital you need to grow sales and marketing, business operations and scale your install pipeline.</div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.fourSquaresItem}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={board} alt="Grow Your Business" />
                            <Box m={2} className={classes.boxTitle}>grow your business</Box>
                            <div className={classes.boxDescription}>Increase your conversion rates and grow even more - we can help you improve your conversion rate by as much as 30%.</div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.fourSquaresItem}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={bullseye} alt="Expand Your Market" />
                            <Box m={2} className={classes.boxTitle}>expand your market</Box>
                            <div className={classes.boxDescription}>Help more people go solar by offering best-in-class financing.</div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.fourSquaresItem}>
                        <Paper elevation={0} className={classes.paper}>
                            <img src={cog} alt="Equipment Delivered" />
                            <Box m={2} className={classes.boxTitle}>equipment delivered</Box>
                            <div className={classes.boxDescription}>Get equipment delivered on-site with no out-of-pocket expenses.</div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className="clearfix" />
        </section>
    );
}

export default SectionOne;
