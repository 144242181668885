import React from "react";

import $ from "jquery";

const footerlinks = [
  [
    { text: "home", url: "/" },
    { text: "faq", url: "/faq/" },
  ],
  [
    { text: "state licenses", url: "/licenses/" },
    {
      text: "consumer complaints",
      url: "/complaints/",
    },
  ],
  [
    { text: "jobs", url: "https://jobs.jobvite.com/loanpal/" },
    { text: "about us", url: "/about-us/" },
  ],
  [
    {
      text: "warriors of light",
      url: "/warriors-of-light/",
    },
    { text: "givepower", url: "https://givepower.org/" },
  ],
  [
    { text: "privacy policy", url: "/privacy/" },
    {
      text: "privacy policy ca",
      url: "/privacy-policy-california/",
    },
    { text: "contact us", url: "/contact-us/" },
  ],
  [
    { text: "report a bug", url: "/bugs/" },
    { text: "terms & conditions", url: "/terms/" },
  ],
  [
    {
      text: "do not sell my information",
      url: "/do-not-sell-my-information/",
    },
    {
      text: "accessibility statement",
      url: "/accessibility-statement/",
    },
  ],
];

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.onClickSliiider = this.onClickSliiider.bind(this);
  }
  onClickSliiider() {
    $("body")
      .css("top", -document.documentElement.scrollTop + "px")
      .addClass("noscroll");
  }
  render() {
    return (
      <>
        <div className="container orange-banner">
          <div className="text-center brandon-regular banner-desktop">
            <span className="brandon-regular">Covid-19 Resource Guide</span> –
            Up-to-date assistance and information can be found{" "}
            <a href="/covid-19">here</a>.
          </div>
          <div className="text-center brandon-regular banner-mobile">
            <span className="brandon-regular">Covid-19 Resource Guide</span> –
            For information, <a href="/covid-19">click here</a>.
          </div>
        </div>
        <footer>
          <div id="footer-nav-container" className="row">
            {footerlinks.map((col) => (
              <div className="col span-1-of-7">
                <ul className="footer-nav">
                  {col.map((row) => (
                    <li>
                      <a href={row.url} data-tip={row.text}>
                        {row.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div id="footer-nav-mobile" className="row mb-40">
            <div className="footer-nav-mobile-col box">
              <table className="footer-nav-mobile-table">
                <tbody>
                  {footerlinks.map((col) => (
                    <tr>
                      <td>
                        <a href={col[0].url} data-tip={col[0].text}>
                          {col[0].text}
                        </a>
                      </td>
                      <td>
                        <a href={col[1].url} data-tip={col[1].text}>
                          {col[1].text}
                        </a>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <a
                        href={footerlinks[4][2].url}
                        data-tip={footerlinks[4][2].text}
                      >
                        {footerlinks[4][2].text}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <p>Loanpal, LLC.</p>
            <p>
              This site is not authorized by the New York State Department of
              Financial Services. No mortgage solicitation activity or loan
              applications for properties located in the State of New York can
              be facilitated through this site.
            </p>
            <p>
              ** Results based on an internal survey of closed loan customers
              from 11/1/2017 to 12/31/2017
            </p>
            <p>
              *** Loanpal will pay these fees to the extent incurred. If not
              incurred you are not entitled to a credit for that amount. If your
              loan does not include an appraisal, no credit will be given. Rate
              discount Benefits available for the same or a new property. Rate
              discount is equal to .125% off the interest rate. For the better
              rate guarantee, borrower must provide a Loan Estimate from the
              competing lender for the same loan product and loan terms on the
              same day. If Loanpal® is unable to match the offer, Loanpal® will
              send a check for $1,000 within 6 weeks. The Set and Secure
              Guarantee is not valid in the event that there is an unforeseen
              change with the loan that includes, but is not limited to, the
              appraised value of the property coming in lower than disclosed,
              undisclosed debt was discovered, a change in income due to the
              loss of a job or other circumstances etc. This guarantee is not
              valid if you withdraw your application or request a change to the
              terms of your loan such as adding an additional borrower, changing
              the loan type from an adjustable rate loan to a fixed rate loan
              etc.
            </p>
            <p>
              Loanpal, LLC. NMLS #30336. 8781 Sierra College Blvd. Roseville, CA
              95661
            </p>
            <p>
              LOANPAL is a registered trademark of Loanpal, LLC. Copyright
              {` ${new Date().getFullYear()} `}
              Loanpal, LLC – All rights reserved.
            </p>
          </div>
          <div id="footer-banners" className="row mt-60">
            <div className="col span-4-of-6 float-left">
              <ul className="credits-images">
                <li className="aicpa-soc">
                  <a
                    href="http://www.aicpa.org/soc4so"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="aicpa-soc"
                      src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1583965401/loanpal.com/21972-312_SOC_NonCPA_Blk_PNG.png"
                      alt="AICPA SOC FOR SERVICE ORGANIZATIONS"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.nmlsconsumeraccess.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/credits_image.png"
                      alt="Credits to Loanpals"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col span-2-of-6 mt-20">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.facebook.com/1Loanpal/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ion-social-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Loanpal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ion-social-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/18433786/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ion-social-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/loanpal/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ion-social-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.zillow.com/lender-profile/1loanpal/#reviews"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="img-social-zillow" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
