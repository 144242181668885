// src/environments/production.ts
export default {
    communications: {    
        webmaster: 'hsanchez@loanpal.com',            
        solarInquiry: 'solarinfo@loanpal.com',  // Carley Praml, Denis Rico
        partnerInquiry: 'partnerapproval@loanpal.com', // Andrew Mills, Chris Kennedy, Dustin Mansell, Eric Howarth, Jordan Bierstock, Leandra Righos, Matt Dawson, Paul Stephan, Sean Coletta, Jessica Markland
        homeImprovement: 'customercare@loanpal.com',
        mortgageInquiry: 'mmurray@loanpal.com',
        consumerComplaints: 'consumerComplaints@loanpal.com',
        trekNomination: 'mmurray@loanpal.com',
        doNotSellMyInformation: 'consumerComplaints@loanpal.com'
    },    
    isProduction: true,
    isDevelopment: false,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  };