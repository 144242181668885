import React from 'react';
import UnderHeader from '../../shared/header/under-header';
import { Link } from 'react-router-dom';
import Logo from '../../../images/logoWhite.png';
import Head from '../../shared/head';

const HomeImprovement = () => { 
    const heroImageContent = (
        <div id="hero-footer" className="hero-footer">
            <div className="row">
            <h1 className="brandon-light">Page not found</h1>                        
            </div>
        </div>);  
    return(
        <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />
            <UnderHeader                
                image="img-container-hero-no-match-pages"                                
                bgColor="orange-header-surprised-girl"
                border=""
                heroImageContent={heroImageContent}
                showDIYSlideshow={false}
                showSlideshowMovie={false}
            />
            <section className="section-home-improvement-form spacer-160">            
            <div className="row paragraph">                     
                <div className="title brandon-bold">Can't find what you are looking for?</div>     
                <div className="title-2 brandon-bold mt-30">Click the button below to go to our home page or call us at 1-844-loanpal for assistance.</div>                
                <div className="row mt-20 mb-60">                    
                    <Link className="btn btn-full-orange uppercase mt-20 go-home-button" to="/">
                        <img alt="Loanpal" className="logo" src={Logo} />
                    </Link>  
                </div> 
            </div>                          
        </section>       
        </>                
    )
}

export default HomeImprovement;