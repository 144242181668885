import React from 'react';

class GivePower extends React.Component {
    render () {
        return(                   
            <main className="section-4-squares section-4-squares-light-grey">  
                <div className="info-mobile">
                    <div className="box parent first-box darker-grey">
                        <div className="outer-box" style={{textAlign: 'center', marginLeft: '5%', marginRight: '5%', lineHeight: '50px'}}>
                            <div className="text-gray brandon-black title clearfix" >
                            we are the official partner of
                            </div>                            
                            <div>
                                <img className="givepower-logo"src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale,w_340/v1/loanpal.com/GP_logo_grey.png" alt="GivePower" />                                                
                            </div> 
                        </div>
                    </div>
                </div>                                                          
                <figure className="trek-video">
                    <a className="no-border" href={'https://youtu.be/-8RAu3gQAgk?autoplay=1'} data-width="960" data-height="540" data-fancybox="true">                          
                        <img className="wp-video" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Lending-With-PurposeDrone-Group-Shot--Nepal-Frame-Button.jpg" alt="Lending With Purpose" />                                                
                    </a>                    
                    <a className="no-border" href={'https://youtu.be/-8RAu3gQAgk?autoplay=1'} data-width="960" data-height="540" data-fancybox="true">
                        <img className="wp-video-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Lending-With-PurposeDrone-Group-Shot--Nepal-Frame-Button.jpg" alt="Our partners love us" />                        
                    </a>
                </figure>            
                <div className="info">
                    <div className="box parent first-box darker-grey">
                        <div className="outer-box" style={{textAlign: 'center', marginLeft: '5%', marginRight: '5%', lineHeight: '50px'}}>
                            <div className="text-gray brandon-black title clearfix" >
                            we are the official partner of
                            </div>                            
                            <div>
                                <img className="givepower-logo"src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale,w_340/v1/loanpal.com/GP_logo_grey.png" alt="GivePower" />                                                
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="info2">
                    <div className="box parent">
                        <div className="outer-box">
                            <div className="text-gray brandon-black title clearfix">
                                how we give
                            </div>
                            <div className="brandon-regular text-gray description">
                                for every home loan we fund, we'll provide someone in need with access to clean drinking water for 20 years.
                            </div>
                        </div>
                    </div>
                </div> 
                 {/* Water man picture */}
                {/* <figure className="water-man">
                    <img className="" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Lending_With_PurposeB46I1201b0_Frame.jpg" alt="Clean water" />                         
                </figure>             
                 */}
                 {/* End of water man picture */}
                 <div className="info2-mobile">
                    <div className="box parent">
                        <div className="outer-box">
                            <div className="text-gray brandon-black title clearfix">
                                how we give
                            </div>
                            <div className="brandon-regular text-gray description">
                                for every home loan we fund, we'll provide someone in need with access to clean drinking water for 20 years.
                            </div>
                        </div>
                    </div>
                </div> 
                <figure className="tamir">
                    <img className="" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/Lending_With_PurposeIMG_4858_Frame.jpg" alt="Clean water" />                    
                </figure>                
                <div className="clearfix"></div>                  
            </main>                       
        )
    }
}

export default GivePower;
