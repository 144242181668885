import React from "react";

const TermsContent = () => {
  return (
    <section className="section-footer-pages">
      <div className="row mt-20 mb-40">
        <p>
          Please read this agreement carefully before accessing or using this
          web site (Site). By accessing or using the Site, you agree to be bound
          by this agreement. Loanpal, LLC, provides the information and services
          on this site to you, the user, conditioned on your acceptance without
          modification of the terms, conditions and notices contained herein.
          Your use of this site constitutes your agreement to all such terms,
          conditions, and notices.
        </p>
        <p>
          The information and services offered on this site are provided with
          the understanding that Loanpal is not engaged in rendering legal or
          other professional services or advice. Your use of the Site is subject
          to the additional disclaimers and caveats that may appear throughout
          the Site.
        </p>
        <p>
          Loanpal and its agents assume no responsibility for any consequence
          relating directly or indirectly to any action or inaction that you
          take based on the information, services or other material on this
          Site. While Loanpal and its suppliers, strive to keep the information
          on this Site accurate, complete and up-to-date, Loanpal, and its
          suppliers cannot guarantee, and will not be responsible for any damage
          or loss related to, the accuracy, completeness or timeliness of the
          information.
        </p>
        <p className="mt-20 brandon-medium">
          PERSONAL AND NONCOMMERCIAL USE LIMITATION: PROHIBITED USES
        </p>
        <p>
          You may access, download and print materials on this Site for your
          personal and non-commercial use. You may not modify, copy, distribute,
          transmit, display, perform, reproduce, publish, license, create
          derivative works from, frame in another Web page, use on any other web
          site, transfer or sell any information, software, lists of users,
          databases or other lists, products or services obtained from this
          Site. The foregoing prohibition expressly includes, but is not limited
          to, the practices of “screen scraping” or “database scraping” to
          obtain lists of users or other information. If and when requested by
          Loanpal, you agree to provide true, accurate and complete user
          information and to refrain from impersonating or falsely representing
          your affiliation with any person or entity. Except with the written
          permission of Loanpal, you agree to refrain from accessing or
          attempting to access password protected, secure or non-public areas of
          this Site. Unauthorized individuals attempting to access prohibited
          areas of this Site may be subject to prosecution.
        </p>
        <p className="mt-20 brandon-medium">NO UNLAWFUL OR PROHIBITED USE</p>
        <p>
          As a condition of your use of this Site, you warrant to Loanpal, that
          you will not use this Site for any purpose that is unlawful or
          prohibited by these terms, conditions, and notices. If you violate any
          of these terms, your permission to use the Site automatically
          terminates.
        </p>
        <p className="mt-20 brandon-medium">LIABILITY DISCLAIMER</p>
        <p>
          You use this Site at your own risk. If your use of this Site or the
          materials therein results in the need for servicing or replacing
          property, material, equipment or data, Loanpal, is not responsible for
          those costs.
        </p>
        <p>
          The information, software, products, and services published on this
          site may include inaccuracies or typographical errors. Changes are
          periodically added to the information herein. Loanpal may without
          prior notice make improvements and/or changes in this site including
          but not limited to the information, services, products or other
          material at any time. All information, products, and services are
          provided "as is" without warranty of any kind. In no event shall
          Loanpal be liable for any direct, indirect, special or consequential
          damages arising our of or in any way connected with the use of this
          site, or for any information, products, material and/or service
          obtained through this site, whether, based on contract, tort, strict
          liability or otherwise.
        </p>
        <p className="mt-20 brandon-medium">CHANGES TO AGREEMENT</p>
        <p>
          Loanpal may modify this Agreement at any time, and such modifications
          shall be effective immediately upon posting of the modified Agreement.
          Accordingly, you agree to review the Agreement periodically, and your
          continued access or use of this Site shall be deemed your acceptance
          of the modified Agreement.
        </p>
        <p className="mt-20 brandon-medium">
          LOANPAL SMS TERMS &amp; CONDITIONS
        </p>
        <p className="mb-15">
          Text <span className="brandon-bold">JOIN</span> to 98968 to join
          Loanpal Alerts. 3msgs/mo. Reply{" "}
          <span className="brandon-bold">HELP</span> for help, Reply{" "}
          <span className="brandon-bold">STOP</span> to cancel. Msg &amp; Data
          Rates May Apply.
        </p>
        <p>
          <span className="brandon-bold">Compatible carriers include:</span>{" "}
          AT&amp;T, T-Mobile®, Verizon Wireless, Sprint, Boost, Virgin Mobile,
          U.S. Cellular and MetroPCS.
        </p>
        <p className="mb-15">
          Alerts sent via SMS may not be delivered if the mobile phone is not in
          range of a transmission site, or if sufficient network capacity is not
          available at a particular time. T-Mobile® is not liable for delayed or
          undelivered messages.
        </p>
        <p className="mb-15">
          Consent to receive text messages is not required as a condition of
          purchasing any goods or services. Texts may be sent using an automatic
          telephone dialing system. To discontinue receiving SMS messages from
          Loanpal, text <span className="brandon-bold">STOP</span> to 98968. For
          additional help, text <span className="brandon-bold">HELP</span> to
          98968 or contact{" "}
          <a href="mailto:custcaredept@loanpal.com">custcaredept@loanpal.com</a>{" "}
          or call 1-844-LOANPAL.
        </p>
        <p>
          Loanpal respects your right to privacy. You can view our privacy
          policy <a href="/privacy">here</a>.
        </p>
        <p className="mt-20 brandon-medium">MISCELLANEOUS</p>
        <p>
          This website is applicable to and should only be used by persons 18
          years of age or older located within the United States of America.
          Access and use of this website by persons under the age of 18 or from
          locations other than the United States is prohibited.
        </p>
      </div>
    </section>
  );
};

export default TermsContent;
