import React from 'react';
import Carousel from "nuka-carousel";

class Employees extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {
            slideIndex: 0,            
            wrapAround: true,
            underlineHeader: false,
            slidesToShow: 1,
            cellAlign: "left",
            transitionMode: "scroll3d",
            heightMode: "max",
            withoutControls: true            
          };  
      }
    render() {        
        return (      
            <section className="section-employees section-two">
                <div className="col span-4-of-4">
                    <div className="title-250 mb-40 text-gray brandon-regular">
                        our <span className="brandon-black">experience</span> sets us apart, <span className="brandon-black">our</span> culture binds us together.
                    </div>
                    <div className="title-1 brandon-medium text-gray uppercase text-underline">
                        <a className="no-link" href="http://jobs.jobvite.com/loanpal" target="_blank" rel="noopener noreferrer"  alt="come join us">come join us</a>
                    </div>
                </div>    
                <div className="clearfix" />
                <div className="employees carousel-desktop">
                <Carousel
                withoutControls={this.state.withoutControls}
                transitionMode={this.state.transitionMode}
                cellAlign={this.state.cellAlign}
                slidesToShow={this.state.slidesToShow}
                speed={3000}
                wrapAround={this.state.wrapAround}
                slideIndex={this.state.slideIndex}
                heightMode={this.state.heightMode}
                autoplay={true}
                autoplayInterval={6000}
                pauseOnHover={false}
                >
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/employees_batch_1.jpg" alt="Employees" />
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/employees_batch_2.jpg" alt="Employees" />
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/employees_batch_3.jpg" alt="Employees" />
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/employees_batch_4.jpg" alt="Employees" />                    
                </Carousel>
                </div>
                <div className="employees carousel-mobile">
                <Carousel
                withoutControls={this.state.withoutControls}
                transitionMode={this.state.transitionMode}
                cellAlign={this.state.cellAlign}
                slidesToShow={this.state.slidesToShow}
                speed={3000}
                wrapAround={this.state.wrapAround}
                slideIndex={this.state.slideIndex}
                heightMode={this.state.heightMode}
                autoplay={true}
                autoplayInterval={6000}
                pauseOnHover={false}
                >
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/employees-batch1.jpg" alt="Employees"/>
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/employees-batch2.jpg" alt="Employees"/>
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/employees-batch3.jpg" alt="Employees"/>
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/employees-batch4.jpg" alt="Employees"/>
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/employees-batch5.jpg" alt="Employees"/>
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/employees-batch6.jpg" alt="Employees"/>
                </Carousel>
                </div>
            </section>
        )
    }
}

export default Employees;