import React from 'react';

class HeroImage extends React.Component {
    render() {
        return (
        <div className={this.props.image + " " + this.props.border + " " + this.props.bgColor}>	            
			{this.props.children}
            <div className="clearfix"></div>
		</div>
        )
    }
}


export default HeroImage;