import React from "react";
import { Link } from "react-router-dom";

class MobileMenu extends React.Component {
  enableUsableNetAssistive = () => {
    window.enableUsableNetAssistive();
  };
  render() {
    const { pathname } = window.location;
    return (
      <div id="menu">
        <ul className="slide-menu">
          <li>
            <a href="https://goodleap.com/">Home</a>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <a
              href={pathname}
              className="UsableNetAssistive"
              onClick={this.enableUsableNetAssistive}
              rel="noopener noreferrer"
              title="By activating this link you will enable accessibility for all the data and features of the site"
            >
              Enable Accessibility
            </a>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <a
              href="https://www.yourmortgageonline.com/?conn=B66F1218-E20C-4EDB-9C45-7B9DE96EFE9F"
              target="_blank"
              rel="noopener noreferrer"
            >
              Home Loan Payment
            </a>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <a
              href="https://www.billerpayments.com/app/cust/login.do?bsn=loanpalmain"
              target="_blank"
              rel="noopener noreferrer"
            >
              Solar Loan Payment
            </a>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <a
              href="https://partner-admin.loanpal.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Partner Portal
            </a>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <a
              href="https://investors.loanpal.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Investor Portal
            </a>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>

          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <Link onClick={this.props.closeSidebar} to="/direct-pay">
              Direct Pay
            </Link>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>

          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <Link onClick={this.props.closeSidebar} to="/home-improvement">
              Home Improvement
            </Link>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>

          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <Link onClick={this.props.closeSidebar} to="/benefits">
              Benefits
            </Link>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <Link onClick={this.props.closeSidebar} to="/about">
              About Us
            </Link>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <Link onClick={this.props.closeSidebar} to="/warriors-of-light">
              Warriors of Light
            </Link>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
          <li>
            <Link onClick={this.props.closeSidebar} to="/testimonials">
              Customers
            </Link>
          </li>
          <li style={{ listStyle: "none", display: "inline" }}>
            <hr />{" "}
          </li>
        </ul>
        <span
          onClick={this.props.closeSidebar}
          className="sliiider-exit exit left-exit"
        >
          <i className="ion-close-round" />
        </span>
      </div>
    );
  }
}

export default MobileMenu;
