import React from 'react';

import BioRow from './components/bio-row';

class DesktopBios extends React.Component {
  render() {
    const { data: { bios } } = this.props;
    return (
      <div id="bios-display" className="mb-30">
        <BioRow biol={bios[0]} bior={bios[2]} />
        <BioRow biol={bios[3]} bior={bios[10]} />
        <BioRow biol={bios[6]} bior={bios[11]} />
        <BioRow biol={bios[5]} bior={bios[4]} />
        <BioRow biol={bios[7]} bior={bios[9]} />
        <BioRow biol={bios[8]} />
      </div>
    );
  }
}

export default DesktopBios;
