import { useState } from 'react';

const useModal = (props) => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
      console.log('Toggle Modal', isShowing, {...props});
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
    ...props
  }
};

export default useModal;