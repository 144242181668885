import React from 'react';

const SectionFeedback = () => {    
    return(        
        <section className="section-green-energy-how-it-works">                             
                <div className="title-3 brandon-regular fancy"><span>how it works</span></div>
                <div className="container">
                <div className="grid-item a">
                    <div className="icon-pic">                        
                        <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/green-energy/mobileIcon.png" className="photo" alt="Customer Application" />
                        <p className="step"><span className="step-label">STEP 1:</span> Customer Application</p>
                        <p className="step-description">Customer can apply anywhere, anytime, with any device - directly through <span className="brandon-bold">your</span> platform with our custom integration.</p>
                    </div>
                </div>    
                <div className="grid-item b">
                    <div className="icon-pic">                        
                        <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/green-energy/stopwatchIcon.png" className="photo" alt="Customer Aproval" />
                        <p className="step"><span className="step-label">STEP 2:</span> Customer Approval</p>
                        <p className="step-description">Frictionless underwriting process delivers fast approval in <span className="brandon-bold">10 seconds</span> or less.</p>
                    </div>                        
                </div>
                <div className="grid-item c">
                    <div className="icon-pic">                        
                        <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/green-energy/moneyIcon.png" className="photo" alt="Transfer of Funds" />
                        <p className="step"><span className="step-label">STEP 3:</span> Transfer of Funds</p>
                        <p className="step-description">Payment goes directly to you. With same or <span className="brandon-bold">next-day funding</span>, funds are transferred quickly.</p>
                    </div>                        
                </div>   
            </div>                            
        </section>            
    )    
}

export default SectionFeedback;
