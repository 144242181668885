import React from "react";

import ContactUs from "./components/contact-us";
import Head from "../../shared/head";

const HomeImprovement = () => {
  return (
    <>
      <Head title={`Loanpal ${window.location.pathname.split("/")[1]}`} />
      <ContactUs />
    </>
  );
};

export default HomeImprovement;
