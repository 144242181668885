import React from 'react';

const WrittenConsentContent = () => {    
        return(            
            <section className="section-footer-pages">                
                <div className="row mt-20 mb-20">
				<h3 className="brandon-bold mb-20">Consent to Conduct Business Electronically (E-Sign Consent)</h3>
				<h5>CONSENT TO CONDUCT BUSINESS ELECTRONICALLY</h5>
                <p>The loan for which you are applying involves various disclosures, records, and documents ("Loan Documents"), including this Agreement. The purpose of this Agreement is to obtain Your consent to receive certain Loan Documents from Us (Loanpal, LLC) in electronic form rather than in paper form. With Your consent, you will also be able to sign and authorize these Loan Documents electronically, rather than on paper.  Before We can engage in this transaction electronically, it is important that You understand Your rights and responsibilities. Please read the following and affirm Your consent to conduct business with Us electronically.</p>
                <br />
                <h5>YOUR CONSENT</h5>
                <ul>
                <li>Your consent to participate in this transaction electronically will apply to all Loan Documents for the applicable loans for which You are applying. If You provide Your consent, we will conduct this transaction electronically, instead of providing You with the Loan Documents in paper form.  All Loan Documents we provide to you in electronic form will be considered "in writing."</li>
                <li>With your consent you agree and we both intend the Federal Electronic Signatures in Global and National Commerce Act to apply to both this Esign consent and our ability to conduct business with you electronically.</li>
                <li>If a document related to Your loan is not available in electronic form, a paper copy will be provided to You free of charge.</li>
                <li>Conducting this transaction electronically is an option. If You choose not to consent, paper Loan Documents will be mailed to You free of charge.</li>
                </ul>
                <br />
                <h5>WITHDRAWAL OF CONSENT</h5>
                <ul>
                <li>You have the right to withdraw Your consent at any time. If you decline or revoke Your consent, We will provide You with the Loan Documents in paper form free of charge.</li>
                <li>If You originally consent to receive Your Loan Documents electronically, but later decide to withdraw Your consent, please notify us at: (877)290-9991 or at Loanpal: 8781 Sierra College Blvd.,Roseville,CA 95661</li>
                </ul>
                <br />
                <h5>OBTAINING PAPER COPIES</h5>
                <ul>
                <li>After Your consent is given, You may request from Us paper copies of Your Loan Documents free of charge.<br />
                Please send this request to Us at:<br />
                Phone: (877) 290-9991 or at Loanpal: 8781 Sierra College Blvd.,Roseville,CA 95661</li>
                </ul>
                <br />
                <h5>SYSTEM REQUIREMENTS</h5>
                <ul>
                <li>In order to receive Your Electronic Loan Documents, You must have a computer with Internet access and an Internet email account and address; an Internet browser using 128-bit encryption or higher, Adobe Acrobat 7.0 or higher, SSL encryption and access to a printer or the ability to download information in order to keep copies of Your Electronic Loan Documents for Your records.</li>
                <li>If the software or hardware requirements change in the future, and You are unable to continue receiving Your Electronic Loan Documents, paper copies of such Loan Documents will be mailed to You once You notify Us that You are no longer able to access Your Electronic Loan Documents because of the changed requirements. We will use commercially reasonable efforts to notify You before such requirements change. If You choose to withdraw Your consent upon notification of the change, You will be able to do so without penalty.</li>
                </ul>
                <br />
                <h5>HOW WE CAN REACH YOU</h5>
                <ul>
                <li>You must promptly notify Us if there is a change in Your email address or in other information needed to contact You electronically. You can contact Us at: Phone: (877) 290-9991 or at Loanpal: 8781 Sierra College Blvd.,Roseville,CA 95661</li>
                <li>We will not assume liability for non-receipt of notification of the availability of Your Electronic Loan Documents in the event Your email address on file is invalid; Your email or Internet service provider filters the notification as "spam" or "junk mail"; there is a malfunction in Your computer, browser, Internet service and/or software; or for other reasons beyond Our control.</li>
                </ul>
            </div>
            </section>           
        )    
}

export default WrittenConsentContent;