import React from 'react';
import Snackbar from '../../../../shared/snackbar';
import http from '../../../../../services/http_client';
import environment from 'environment';

class SectionTrek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nominee: '',
      name: '',
      nomineename: '',
      email: '',
      why: ''
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clickSnackbar = this.clickSnackbar.bind(this);
    this.snackBarRef = React.createRef();
  }
  clickSnackbar(snackBarMessage, variant)  {
      this.snackBarRef.current.openSnackBar(snackBarMessage, variant);
  }
  validateForm() {
    if (
      this.state.nomineename !== '' &&
      this.state.name !== '' &&
      this.state.email !== '' &&
      this.state.why !== ''
    ) {
      return true;
    } else {
      return false;
    }
  }
  async handleFormSubmit(e) {
    e.preventDefault();
    if (!this.validateForm()) {
      this.setState({
        openSnackbar: true,
        snackBarMessage: 'Oh Snap! Please enter required fields!',
        snackBarVariant: 'warning'
      });
      return false;
    } else {
      var subject = 'GivePower Trek Nomination';
      const jsonBody = {
        emailTo: environment.communications.trekNomination,
        emailBcc: environment.communications.webmaster,
        emailFrom: this.state.email,
        name: this.state.name,
        nominee: this.state.nomineename,
        why: this.state.why,
        subject: subject
      };
      console.log(
        'Send this request POST: ',
        JSON.stringify(jsonBody, null, 2)
      );

        const result = await http.post({
            endpoint: '/support/sendEmail',
            data: jsonBody
        });
        console.log(result);
        if (result && result.message === 'Message Sent') {
            this.clickSnackbar('Thank you! Your message has been sent.', 'success');
            this.handleClearForm(e);
        } else {
            this.clickSnackbar('Oh Snap! And error occurred, please try again.', 'error');
        }
    }
  }
  handleClearForm() {
    this.setState({
      name: '',
      email: '',
      why: '',
      nomineename: '',
      description: ''
    });
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }
  render() {
    return (
        <div className="trek" style={{ marginTop: '115px' }}>
          <div className="land-full land-3-banner-full">
            <div className="land-container">
              <div className="land-3-banner">
                <div className="land-3-banner-conte">
                  <h1 className="brandon-black">JOIN THE CHARGE!</h1>
                  <p>
                    It's Trek time again! Who wants to go on a once-in-a-lifetime trek?  In April, we will be taking 11 team members from different parts of Loanpal on a trek to a remote village in Nepal.  It a special time to help those in need.  Are you ready to change someone life?
                  </p>
                  <div className="trek-video">
                    <a
                      className="no-border"
                      href="https://res.cloudinary.com/loanpal/video/upload/v1582060960/loanpal.com/videos/LoanpalGivePowerTrek.mp4"
                      data-fancybox="true"
                      data-width="960" data-height="540"
                    >
                      <div className="video-thumbnail">
                        <img
                          src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/trekThumbnail.jpg"
                          className="photo"
                          alt="GivePower trek"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="trek-collage">
                    <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1582059463/loanpal.com/givepower-trek.png"
                    alt="GivePower trekkers" />
                  </div>
                </div>

                <div className="land-3-banner-form">
                  <h4>How Can You Join A Trek? </h4>

                  <p style={{ marginBottom: '20px' }}>
                  Trekkers will be nominated by their own team members for displaying exceptional customer service and embodying the spirit of Loanpal’s “Finance Made Friendly” brand. This can be external with one of Loanpal’s customers or vendors, or internal with other team members.  And, make sure you use your nominations wisely as you only get 3! <span className="brandon-medium">NOMINATIONS CLOSE ON MARCH 6TH, 2020</span>
                  </p>
                  <div className="land-com-form">
                    <h2>Nominate A Team Member</h2>
                    <form
                      onSubmit={e => this.handleFormSubmit(e)}
                      method="post"
                    >
                      <div
                        className="alert alert-success contact__msg"
                        style={{ display: 'none' }}
                        role="alert"
                      >
                        Your message was sent successfully.
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            name="email"
                            placeholder="Your Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            name="nomineename"
                            placeholder="Nominee Name"
                            value={this.state.nomineename}
                            onChange={this.handleChange}
                          />
                        </li>
                        <li>
                          <textarea
                            name="why"
                            placeholder="Why They Are Deserving"
                            value={this.state.why}
                            onChange={this.handleChange}
                          />
                        </li>

                        <li>
                          <input type="submit" value="Nominate Now" />
                        </li>
                        <li>
                          <p>
                            Thank you for taking the time to recognize your team
                            member
                          </p>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Snackbar ref={this.snackBarRef} />
        </div>
    );
  }
}

export default SectionTrek;
