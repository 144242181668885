import React from 'react';

class BusinessJournal extends React.Component {
  render() {
    return (      
        <section className="section-business-journal">
          <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/bptw_and_5star_rating_bbb_2020-grey.jpg" className="section-hero-image hide-mobile" alt="Loanpal A+ BBB rating" />          
          <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/bptw_and_5star_rating_bbb_720_2020-grey.jpg" className="section-hero-image show-mobile" alt="Loanpal A+ BBB rating" />                    

        </section>              
    );
  }
}

export default BusinessJournal;