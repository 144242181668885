import React from 'react';

const FiveMinutesOrLess = (props) => {    
    return(            
        <section className="section-refinance-one">            
            <div className="row mt-20">
                <div className="title text-green brandon-black">in 5 minutes or less, you could be on your way to saving as much as $3500 per year.</div>              
            </div>  
            <div className="row mt-30">
                <div className="col span-1-of-2 text-right box">
                    <img className="cs-phone" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/ratecomparison.svg" alt="Rate comparison" />                        
                </div>
                <div className="col span-1-of-2 text-left pl-45 box">                                            
                    <ul className="mt-20">
                        <li>close in as few as <span className="brandon-regular-italic text-underline">21 days!</span></li>
                        <li>save big with our <span className="brandon-regular-italic text-underline">better rate guarantee!</span></li>
                        <li><span className="brandon-regular-italic text-underline">ZERO</span> lender fees!</li>                            
                    </ul>    
                </div>
            </div> 
            <div className="row mb-40">
                <button className="btn btn-full uppercase mt-20" onClick={props.toggle}>Get Approved!</button>
            </div>
        </section>            
    )    
}

export default FiveMinutesOrLess;
