import React from 'react';
import Address from './address';

const Addresses = () => {
    return (
      <section className="contactus contactus-locations">
        <div className="row">
          <div className="container">
            <div className="grid-header h2">
              <h1 className="brandon-black">LOCATIONS</h1>
            </div>
            <div className="grid-item a1">
              <Address
                location="SAN FRANCISCO, CA"
                address1="500 2nd Street"
                address2="San Francisco, CA 94107"
                tollFreePhone="877-290-9991"
                phone="916-290-9999"
              />
            </div>
            <div className="grid-item b1">
              <Address
                location="ROSEVILLE, CA"
                address1="8781 Sierra College Blvd."
                address2="Roseville, CA 95661"
                tollFreePhone="877-290-9991"
                phone="916-290-9999"
              />
            </div>
            <div className="grid-item c1">
              <Address
                location="IRVINE, CA"
                address1="22 Executive Park, Suite 100."
                address2="Irvine, CA 92614"
                tollFreePhone="877-290-9991"
                phone="916-746-8000"
              />
            </div>
            <div className="grid-item a2">
              <Address
                location="ROSEVILLE, CA"
                address1="1000 Enterprise Way, Suite 200."
                address2="Roseville, CA 95678"
                tollFreePhone="877-290-9991"
                phone="916-290-9999"
              />
            </div>
            <div className="grid-item b2">
              <Address
                location="PHOENIX, AZ"
                address1="8888 E. Raintree, Suite 100."
                address2="Scottsdale, AZ 85260"
                tollFreePhone="877-290-9991"
                phone="602-443-4000"
              />
            </div>
            <div className="grid-item c2">
              <Address
                location="KANSAS CITY, MO"
                address0="Regus Briarcliff"
                address1="1201 N.W. Briarcliff Parkway"
                address2="2nd Floor / Office 217"
                address3="Kansas City, Missouri 64116"
                tollFreePhone="877-290-9991"
                phone="916-262-8180"
              />
            </div>
            <div className="grid-item a3">
              <Address
                location="HAIKU, HI"
                address1="250 W. Kulaha Road"
                address2="Haiku, Hawaii 96708"
                tollFreePhone="877-290-9991"
                phone="916-290-9999"
              />
            </div>
          </div>
        </div>
      </section>
    );
}

export default Addresses;
