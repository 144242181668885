import React from 'react';

class Snackbar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        message: '',                        
        isActive: false,
        variant: 'warning'
    }    
  }    

  openSnackBar (message = 'Something went wrong...', variant = 'warning') {       
    this.setState({ isActive: true, message, variant }, () => {
      setTimeout(() => {
        this.setState({ isActive: false });
      }, 3000);
    });
  }

  render() {
    const { isActive, variant, message } = this.state;    
    let paint = "";
    if (isActive) {
        if (variant === 'info') paint = 'snackbar snackbar-info show';
        else if (variant === 'warning') paint = 'snackbar snackbar-warning show';
        else if (variant === 'error') paint = 'snackbar snackbar-error show'
        else if (variant === 'success') paint = 'snackbar snackbar-success show'
        else paint = 'snackbar show'
     } else {
        paint = 'snackbar'
    }     
    return (      
    <div className = {paint}>
        {message}
      </div>
    )
  }
}

export default Snackbar;