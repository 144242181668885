import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, List, ListItemText, ListItemAvatar, ListItem } from '@material-ui/core';

const Checkmark = (props) => (
    <svg className={props.checkIcon} xmlns="http://www.w3.org/2000/svg" width="107.732" height="108.095" viewBox="0 0 107.732 108.095" aria-labelledby="checkmark" role="img">
            <g transform="translate(33.164 -4.846) rotate(20)"><g transform="translate(0 0)"><path className={props.fill} d="M64.61,19.059,17.953,80.734a1.2,1.2,0,0,1-.977.5,1.225,1.225,0,0,1-.773-.277L.456,68.122a1.225,1.225,0,0,1-.175-1.735l4.943-5.905a1.181,1.181,0,0,1,.933-.437,1.239,1.239,0,0,1,.787.277l6.867,5.832a1.21,1.21,0,0,0,.773.292,1.2,1.2,0,0,0,1.079-.642L51.488.877A1.677,1.677,0,0,1,52.946,0,1.648,1.648,0,0,1,54.4.731l10.206,14.8a3,3,0,0,1,0,3.528Z" transform="translate(14.17 0)"/><path className={props.fill} d="M56.91,24.5A26.05,26.05,0,1,1,31.933,5.832a25.749,25.749,0,0,1,5.643.642l2.916-5.293A31.9,31.9,0,1,0,61.065,18.969Z" transform="translate(0 33.158)"/></g></g>
    </svg>
);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        font: 'normal normal 900 42px/66px brandon-grotesque',
        color: '#00838F',
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 900 24px/32px brandon-grotesque',
            margin: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 900 36px/45px brandon-grotesque',
            margin: theme.spacing(1),
            padding: '10px 20%'
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 900 42px/66px brandon-grotesque',
            margin: theme.spacing(2),
        }
    },
    greenIcon: {
        fill: '#00838F'
    },
    checksContainer: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        margin: '0 17%',
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0 5%',
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 17%',
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
        }
    },
    checks: {
        paddingTop: theme.spacing(0)
    },
    checkText: {
        font: 'normal normal 500 28px/35px brandon-grotesque',
        color: '#00838F',
        marginLeft: '20px',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 500 16px/22px brandon-grotesque',
            marginLeft: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 500 22px/28px brandon-grotesque',
            marginLeft: '20px',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 500 28px/35px brandon-grotesque',
            marginLeft: '20px',
        }
    },
    checkIcon: {
        width: '70px',
        height: '70px',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            height: '40px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '50px',
            height: '50px'
        },
        [theme.breakpoints.up('md')]: {
            width: '70px',
            height: '70px'
        }
    }
}));

const SectionFour = () => {
    const classes = useStyles();
    return (
        <section className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box m={4}
                    className={classes.title}>
                    we ask that our partners meet the following requirements:
                    </Box>
                    <List className={classes.checksContainer}>
                        <ListItem className={classes.checks}>
                            <ListItemAvatar>
                                <Checkmark fill={classes.greenIcon} checkIcon={classes.checkIcon}/>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box className={classes.checkText}>Minimum of 100 installations over company lifetime.</Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem className={classes.checks}>
                            <ListItemAvatar>
                                <Checkmark fill={classes.greenIcon} checkIcon={classes.checkIcon}/>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box className={classes.checkText}>Satisfactory business credit, online reputation, business financials, and Better Business Bureau Rating.</Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem className={classes.checks}>
                            <ListItemAvatar>
                                <Checkmark fill={classes.greenIcon} checkIcon={classes.checkIcon}/>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box className={classes.checkText}>Personal credit on ownership when the company has less than 500 installs to date.</Box>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <div className="clearfix" />
        </section>
    );
}

export default SectionFour;
