import React from 'react';

const BioPopup =  (props) => {   
  console.log("BioPopup", props.id, props);
    return(
      <div        
        className="profile-bio-mobile"
        id={`modal${props.id}`}  
        style={{
          backgroundImage: `url(${props.bkImg})`,
          opacity: '.8',
          backgroundPosition: props.position,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          lineHeight: '32px',
          width: '100%',
          height: '100%',
          maxHeight: '100%',
          backgroundColor: '#000000',
          paddingTop: props.padding
        }}      
      >
        <div className={`container-${props.side}`}>
          <div className="grid-item2 desc">
            <div className="brandon-bold profile-bio-name">
              {props.name}
            </div>
            <div className="brandon-regular-italic profile-bio-title">
              {props.title}
            </div>
            <div
              className="brandon-regular profile-bio-desc"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />            
          </div>
        </div>
      </div>
    );
  }

export default BioPopup;