import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

import workflowImg from 'images/direct-pay/workflowdiagram-02-min.png';
import workflowImgM from 'images/direct-pay/workflowdiagramMOBILE@300x@2x.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EAEBEC',
        padding: '20px 0',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        [theme.breakpoints.up('sm')]: {
            padding: '20px 0',
        },
        [theme.breakpoints.up('md')]: {
            padding: '20px 0',
        }
    },
    title: {
        font: 'normal normal 900 42px/66px brandon-grotesque',
        color: '#00838F',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 900 30px/56px brandon-grotesque',
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 900 42px/72px brandon-grotesque',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 900 42px/66px brandon-grotesque',
            width: '100%'
        }
    },
    workflowGraphic: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '65%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '45%'
        },
        [theme.breakpoints.up('md')]: {
            width: '70%'
        }

    },
    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));

const HowItWorks = () => {
    const classes = useStyles();
    return (
        <section className={classes.container}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box m={1}
                    className={classes.title}>
                    how it works.
                    </Box>
                    <Box className={classes.desktop}>
                        <img className={classes.workflowGraphic} src={workflowImg} alt="how it works workflow diagram" />
                    </Box>
                    <Box className={classes.mobile}>
                        <img className={classes.workflowGraphic} src={workflowImgM} alt="how it works workflow diagram" />
                    </Box>
                </Grid>
            </Grid>
            <div className="clearfix" />
        </section>
    );
}

export default HowItWorks;
