import React from 'react';

const PieceOfMind = () => {
    return (
        <section className="section-why-loanpal gray-background js--section-why-loanpal" id="why-loanpal">                            
            <div className="row">               
                <div className="pomp"><img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/benefits/POMP.svg" alt="Piece of Mind Promise" /></div>
            </div>
            <div className="row js--wp-1 benefits-smileys">
                <div className="col span-1-of-3 box">
                    <img className="smileys" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/benefits/rate_short.svg" alt="Better rate guarantee" />
                    <h3>BETTER RATE GUARANTEE</h3>
                    <p>
                        we're so confident in our incredible rates, if you find a lower rate, we'll match it or pay you $1000.
                    </p>
                </div>
                <div className="col span-1-of-3 box">
                    <img className="smileys" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/benefits/lock_short.svg" alt="Secure closing guarantee" />
                    <h3>SECURE CLOSING GUARANTEE</h3>
                    <p>
                        we will set, secure and guarantee the terms and rate we quote you at closing or we'll pay you $1000.
                    </p>
                </div>
                <div className="col span-1-of-3 box">
                    <img className="smileys" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/benefits/checkmark_short.svg" alt="Lifetime rewards guarantee" />
                    <h3>LIFETIME REWARDS GUARANTEE</h3>
                    <p>
                    we hook our friends up. returning customers are eligible for exclusive rate discounts and pay NO out-of-pocket expenses on a refinance.
                    </p>
                </div>                                
            </div>                
        </section>
    )
}

export default PieceOfMind;