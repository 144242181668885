import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

// Views
import Home from "./home";
import About from "./about-us";
import Solar from "./green-energy";
import Testimonials from "./testimonials";
import Benefits from "./benefits";
import Mortgage from "./mortgage";
import Refinance from "./refinance";
import HomeImprovement from "./home-improvement";
import Resources from "./resources";
import ContactUs from "./contact-us";
import Faq from "./footer-pages/faq";
import Licenses from "./footer-pages/licenses";
import Complaints from "./footer-pages/complaints";
import NotSellInfo from "./footer-pages/do-not-sell-my-information";
import Calculator from "./footer-pages/calculator";
import Terms from "./footer-pages/terms-of-use";
import Privacy from "./footer-pages/privacy";
import PrivacyCA from "./footer-pages/privacy-policy-california";
import Bugs from "./footer-pages/bugs";
import WrittenConsent from "./footer-pages/written-consent";
import ESignConsent from "./footer-pages/esign-consent";
import GivePowerTrek from "./footer-pages/givepower-trek";
import AccessibilityStatement from "./footer-pages/accessibility-statement";
import Covid19 from "./footer-pages/covid-19";
import DirectPay from "./direct-pay";
import WarriorsOfLight from "./warriors-of-light-new/index.js";
import NoMatchPage from "./no-match";

import ReactGA from "react-ga";

const Router = (props) => {
  ReactGA.initialize("UA-113302285-1", {
    gaOptions: {
      anonymizeIp: true,
    },
  });
  ReactGA.pageview(window.location.pathname);
  console.log("pageview", window.location.pathname);
  return (
    <div id="viewsRoot">
      <Switch>
        <Redirect from="/index.html" to="/" />
        <Redirect from="/company.html" to="/about" />
        <Redirect from="/green-energy.html" to="/green-energy" />
        <Redirect from="/testimonials.html" to="/testimonials" />
        <Redirect from="/benefits.html" to="/benefits" />
        <Redirect from="/mortgate.html" to="/mortgage" />
        <Redirect from="/refinance.html" to="/refinance" />
        <Redirect from="/home-improvement.html" to="/home-improvement" />
        <Redirect from="/resources.html" to="/resources" />
        <Redirect from="/contact-us.html" to="/contact-us" />
        <Redirect from="/faq.html" to="/faq" />
        <Redirect from="/licenses.html" to="/licenses" />
        <Redirect from="/complaints.html" to="/complaints" />
        <Redirect
          from="/do-not-sell-my-inforrmation.html"
          to="/do-not-sell-my-information"
        />
        <Redirect from="/calculator.html" to="/calculator" />
        <Redirect from="/terms.html" to="/terms" />
        <Redirect from="/privacy.html" to="/privacy" />
        <Redirect
          from="/privacy-policy-california.html"
          to="/privacy-policy-california"
        />
        <Redirect from="/bugs.html" to="/bugs" />
        <Redirect from="/written-consent.html" to="/written-consent" />
        <Redirect from="/esign-consent.html" to="/esign-consent" />
        <Redirect from="/trek.html" to="/givepower-trek" />
        <Redirect
          from="/accessibility-statement.html"
          to="/accessibility-statement"
        />
        <Redirect from="/jobs.html" to="/jobs" />
        <Redirect from="/store.html" to="/store" />
        <Redirect from="/covid-19.html" to="/covid-19" />
        <Redirect from="/direct-pay.html" to="/direct-pay" />
        <Redirect from="/warriors-of-light.html" to="/warriors-of-light" />
        <Redirect from="/wol.html" to="/wol" />

        <Route
          path="/about-us"
          component={About}
        />
        <Route
          path="/"
          exact
          component={Home}
        />
        <Route
          path="/green-energy"
          component={Solar}
        />
        <Route
          path="/testimonials"
          component={Testimonials}
        />
        <Route
          path="/benefits"
          component={Benefits}
        />
        <Route
          path="/mortgage"
          component={Mortgage}
        />
        <Route
          path="/refinance"
          component={Refinance}
        />
        <Route
          path="/home-improvement"
          component={HomeImprovement}
        />
        <Route
          path="/resources"
          component={Resources}
        />
        <Route
          path="/contact-us"
          component={ContactUs}
        />
        <Route
          path="/faq"
          component={Faq}
        />
        <Route
          path="/licenses"
          component={Licenses}
        />
        <Route
          path="/complaints"
          component={Complaints}
        />
        <Route
          path="/do-not-sell-my-information"
          component={NotSellInfo}
        />
        <Route
          path="/calculator"
          component={Calculator}
        />
        <Route
          path="/terms"
          component={Terms}
        />
        <Route
          path="/privacy"
          component={Privacy}
        />
        <Route
          path="/privacy-policy-california"
          component={PrivacyCA}
        />
        <Route
          path="/bugs"
          component={Bugs}
        />
        <Route
          path="/written-consent"
          component={WrittenConsent}
        />
        <Route
          path="/esign-consent"
          component={() => {
            //window.location.href = "https://goodleap.com/esign-consent/";
            return ESignConsent;
          }}
        />
        <Route
          path="/givepower-trek"
          component={GivePowerTrek}
        />
        <Route
          path="/accessibility-statement"
          component={AccessibilityStatement}
        />
        <Route
          path="/covid-19"
          component={Covid19}
        />
        <Route
          path="/direct-pay"
          component={DirectPay}
        />
        <Route
          path="/warriors-of-light"
          component={WarriorsOfLight}
        />
        <Route
          path="/wol"
          component={() => {
            window.location.href = "https://goodleap.com/wol/";
            return null;
          }}
        />
        <Route
          path="/jobs"
          component={() => {
            window.location.href = "https://jobs.jobvite.com/loanpal";
            return null;
          }}
        />
        <Route
          path="/store"
          component={() => {
            window.location.href = "https://www.loanpalstore.com";
            return null;
          }}
        />
        <Route component={NoMatchPage} />
      </Switch>
    </div>
  );
};
Router.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
export default withRouter(Router);
