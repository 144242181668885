import React from 'react';

const PartnersVideo = ({...props}) => {  
    console.log('Partners Video', props);
    const { bannerImage } = props;
    return (
      <section className="section-green-energy-banner">
        <a
          className="no-border"
          href={'https://res.cloudinary.com/loanpal/video/upload/q_auto,vc_auto/v1/loanpal.com/videos/Solar_partners_180921_CCd_MIXed_FINAL_smaller.mp4'}
          data-fancybox="true"
          data-width="960"
          data-height="540"
        >
          <div  className={`${bannerImage}`} />
        </a>
      </section>
    );  
}

export default PartnersVideo;