import React from 'react';

const WrittenConsentContent = () => {    
        return(            
            <section className="section-footer-pages">
                <div className="row mt-20 mb-20">
                    <h3 className="brandon-bold mb-20">Express Written Consent to Communicate or Call Via Cell Phone or Other Means</h3>				
                    <p>We take your privacy seriously.  By signing this document, you are providing express written consent for Loanpal, LLC or companies working on our behalf to call you (including through automated means: e.g. autodialing, text, and pre-recorded messaging) via telephone, mobile device or cell phone (including SMS and MMS) and/or via email, even if your telephone number is currently listed on any state, federal, or national Do-Not-Call (DNC) list. In addition, with this consent you also agree to our Privacy Policy provided as a part of your loan documents.</p>  
                    <p>Consent is not required to conduct business with Loanpal and this consent can be withdrawn at any time by calling               (877) 290-9991 or requesting by mail at Loanpal: 8781 Sierra College Blvd, Roseville, CA 95661.</p>
                    <p>If you withdraw your consent you will be put on Loanpal’s internal Do-Not-Call list.</p>
                    <p>For NV residents only:  We are providing this notice under state law.  You may be placed on our internal Do-Not-Call list by calling (877) 290-9991.  Nevada asks us to provide their contact information.  Office Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington St. Suite 3900, Las Vegas, NV 89101.  Phone: (702) 486-3132; Email: <a href="mailto:bcinfo@ag.state.nv.us">bcinfo@ag.state.nv.us</a>.</p>
                    <p>For CA residents only:  Under CA law we will not share information we collect about you with companies outside of Loanpal, LLC unless the law allows.  </p>
                </div>
            </section>            
        )    
}

export default WrittenConsentContent;