import React from 'react';
import Modal from '../../../shared/modal-form';
import useModal from '../../../shared/modal-form/useModal';

const BannerStrip = (props) => {        
      const { bannerImage, button = false, form } = props;   
      const { pathname } = window.location;
      const { isShowing, toggle } = useModal(); 
      return (
          <>
            {!button && (
                 <section className="section-green-energy-banner">
                    <div
                    className={`${bannerImage} img-container-green-energy-slideshow`}                          
                    />                
                </section>
            )}
            {button && (
                <section className="section-green-energy-banner">
                    <div
                    className={`${bannerImage} img-container-green-energy-slideshow`}          
                    onClick={toggle}
                    />                   
                </section>
            )}
            <Modal           
              form={form}       
              pathname={pathname}
              isShowing={isShowing}
              hide={toggle}       
            />  
          </>  
        )      
}

export default BannerStrip;
