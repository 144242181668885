import React from 'react';

const ContactUsHeader = () => {    
        return (
            <section className="contactus contactus-header">                
                <div className="row">                
                    <div className="container">                        
                        <div className="grid-item a">
                            <div>
                                <h1 className="brandon-black">PHONE</h1>   
                                <h2 className="brandon-bold">CUSTOMER SUPPORT</h2>
                                <h3 className="brandon-medium">1.844.LOANPAL</h3>
                                <h2 className="brandon-bold mt-10">MORTGAGE LOAN SERVICING</h2>
                                <h3 className="brandon-medium">1.888.288.1791</h3> 
                                <h2 className="brandon-bold mt-10">SOLAR &amp; HOME IMPROVEMENT</h2>
                                <h3 className="brandon-medium">1.844.910.0111 (TOLL FREE)</h3>                                 
                            </div>
                        </div>
                        <div className="grid-item b">                        
                            <div>
                                <h1 className="brandon-black">EMAIL</h1>   
                                <h2 className="brandon-bold">CUSTOMER SUPPORT</h2>
                                <h3 className="brandon-medium"><a href="mailto:CustomerCare@loanpal.com">CustomerCare@loanpal.com</a></h3>  
                                <h2 className="brandon-bold mt-10">SOLAR &amp; HOME IMPROVEMENT</h2>
                                <h3 className="brandon-medium"><a href="mailto:SolarInfo@loanpal.com">SolarInfo@loanpal.com</a></h3>
                                <h2 className="mt-10">&nbsp;</h2>      
                                <h3>&nbsp;</h3>                          
                            </div>  
                        </div>
                    </div>                                    
                </div>
            </section>                       
        )    
}

export default ContactUsHeader;

