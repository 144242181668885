import React from 'react';
import ShowMoreText from 'react-show-more-text';

function createMarkup(html) {
    return {__html: html};
  }


const Article = (props) => {        
    const { id, imageUrl, header, subHeader, article, alt } = props;
    return (
        <div className="row mb-10">
            <div className="outside-box">
                <div id={`article${id}`} className="col span-4-of-4 box-right text-left articles">
                    <img src={imageUrl} alt={alt} />
                    <h3 className="brandon-black">{header}</h3>
                    <p className="mb-10 brandon-bold">{subHeader}</p>
                    <article className="text-90">
                        <ShowMoreText 
                        lines={5}
                        more="Show more"
                        less="Show less"
                        expanded={false}
                        >
                            <div dangerouslySetInnerHTML={createMarkup(article)} />
                        </ShowMoreText>                        
                    </article>
                </div>
            </div>                        
        </div>
    );
}

export default Article;