import React from 'react';
import SectionOne from './section-one';
import ApplyToday from './apply-today';
import SectionTwo from './section-two';
import HowItWorks from './section-how-it-works';
import SectionFour from './section-four';
import SectionFive from './section-five';
import Partners from './section-partners';
// import environment from 'environment';

// import BannerStrip from './banner-strip';

const DirectPayContent = () => {
    return(
        <>
        <SectionOne />
        <ApplyToday />
        {/* <BannerStrip
              button={true}
              form="inquiry.partner"
              email={environment.communications.partnerInquiry}
              alt="Direct Pay"
              class=""
            /> */}
        <SectionTwo />
        <HowItWorks />
        <SectionFour />
        <SectionFive />
        <Partners />
        </>
    );
}

export default DirectPayContent;
