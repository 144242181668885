import React from 'react';

import Bios from './bios/components/desktop-view';
import BiosMobile from '../components/bios/components/mobile-view';
import { useMediaQuery } from 'react-responsive';
import data from './bios/data';

const Leadership = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
      // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' });
      // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
      const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
      })
      // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
      // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });      
      return (        
        <section className="leadership">
          <div className="row leadership-intro mb-30">
            <div className="row mt30">
              <h1 className="brandon-black">about us</h1>
            </div>
            <p style={{maxWidth: '750px', margin: '0 auto'}}>We provide friendly financing options for those who dream of living a more sustainable lifestyle and want to save money using modern technology.</p>
            <br />
            <p style={{ maxWidth: '750px', margin: '0 auto' }}>Our collective mission is to make a positive impact on the planet, build lasting relationships with our valued partners and customers, and deliver a tech-enabled lending experience that is simple, fast, and frictionless.</p>
            <br />
            <p style={{ fontSize: '115%' }} className="brandon-medium">
              We make finance friendly. Let us show you how.
            </p>
          </div>
          <div className="row">
            <hr className="line style-eight" />
          </div>
          <div className="row mt-30">
            <h1 className="brandon-black">leadership team</h1>
          </div>
          {isDesktopOrLaptop && <>
            <Bios data={data} />
            </>
          }
          {isTabletOrMobileDevice && <>
            <BiosMobile data={data} />
            </>
          }                      
        </section>      
      )
}

export default Leadership;
