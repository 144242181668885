import React from 'react';
import Friends from './components/friends-grid';
import Head from '../../shared/head';
class TestimonialsContainer extends React.Component {
    render () {
        return(    
            <>
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />        
            <section className="testimonials">                
                <div className="row">
                    <h1 className="brandon-black">meet some of our friends,</h1>
                    <h2>& hear their stories.</h2>
                </div>         
                <Friends />
                <div className="clearfix"></div>       
            </section>            
            </>
        )
    }
}

export default TestimonialsContainer;