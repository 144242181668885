import React from 'react';

const Covi19Contents = () =>  {    
    return(        
        <section className="section-footer-pages">
            <div className="covid-content row mt-20 mb-40 text-110">
                <section>
                    <div className="row">
                        <p>During this unprecedented time, nothing is more important to us than the health, safety, and continued success of our employees, customers, partners and communities.</p>
                    </div>    
                </section>    
                <div className="row">
                    <div className="col span-1-of-2 pr-20">
                        <h3 className="text-center">We’re here for you</h3>
                        <p>To protect our employees, we’ve seamlessly transitioned to a virtual workspace, and are operating from within the safety of our homes. But despite this transition, you can expect the same great service on which we pride ourselves.</p>
                        <br />    
                        <p className="brandon-medium">Solar Loan Customers – Payments & Loan Servicing</p>
                        <p>800-345-9372</p>
                        <p><a href="mailto:Customerservice@loanpalsupport.com">Customerservice@loanpalsupport.com</a></p>
                        <br />
                        <p className="brandon-medium">Solar Loan Customers/Intallers – Loans in process</p>
                        <p>844-910-0111</p>
                        <p><a href="mailto:loanpalsolarops@loanpal.com">loanpalsolarops@loanpal.com</a></p>
                        <br />    
                        <p className="brandon-medium">Solar Partner Inquiries</p>    
                        <p><a href="mailto:solarbd@loanpal.com">solarbd@loanpal.com</a></p>
                        <br />
                        <p className="brandon-medium">Mortgage Customers</p>
                        <p>844-562-6725</p>
                        <p><a href="mailto:customercare@loanpal.com">customercare@loanpal.com</a></p>
                    </div>
                    <div className="col span-1-of-2 covid-vendor-guidelines">
                        <h3 className="text-center">Our Vendor Guidelines</h3>  
                        <p>While Loanpal is working virtually, cases arise where one of our vendors need to visit your home to keep your transaction moving forward.  Rest assured, when this occurs, we are taking all of the necessary precautions we can to protect you and your family.  Precautions include:</p>
                        <ul className="ml-40 mb-20 mt-20">
                            <li>Maintaining CDC recommended social distancing practices</li>
                            <li>Ensuring all vendor employees go through health screening protocols prior to visiting your home</li>
                            <li>Requiring all vendor representatives to wear masks and gloves during their visit</li>
                        </ul>
                        <p>If you think you, or someone in your household, may be sick or exposed to someone that was infected with COVID-19, please let the vendor representative know.  We’ll find alternate arrangements as their safety is important to us as well</p>
                        <p className="my-20">Should you have any questions about the precautions that are being taken, or are uncomfortable with this process, please reach out to your Loanpal representative.</p>
                    </div>               
                </div>                    
            </div>
        </section>        
    )    
}

export default Covi19Contents;