import React from 'react';

const WhatOurCustomersSay = () => {    
    return(        
        <section className="section-refinance-three">
            <div className="title mt-20 brandon-black text-teal">
                    what our customers say
            </div>
            <div className="row">                    
                <img className="claims hide-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/3claims.svg" alt="Fastest Friendliest Easiest" />
                <img className="claims show-mobile-720" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/3claims-720.svg" alt="Fastest Friendliest Easiest" />
                <img className="claims show-mobile-480" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mortgage/3claims-480.svg" alt="Fastest Friendliest Easiest" />
            </div>            
        </section>        
    )    
}

export default WhatOurCustomersSay;