import React from 'react';

class TogetherMoreThanEver extends React.Component {
  render() {
    return (      
        <section className="section-together">            
            <figure className="together-video">
                <div className="title-250 text-gray brandon-black">              
                together more than ever
                </div>
                <a className="no-border" href={'https://youtu.be/9SivhJd3Rvg?autoplay=1'} data-width="960" data-height="540" data-fancybox="true">                          
                    <img className="wp-video" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/together-more-than-ever.png"  alt="Loanpal together more than ever" />          
                </a>                    
                <a className="no-border" href={'https://youtu.be/9SivhJd3Rvg?autoplay=1'} data-width="960" data-height="540" data-fancybox="true">
                    <img className="wp-video-mobile" src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/mobile/together-more-than-ever-mobile.png" alt="Loanpal together more than ever" />          
                </a>
            </figure>           
        </section>              
    );
  }
}

export default TogetherMoreThanEver;