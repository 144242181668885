import React from 'react';
import NotSaleInforForm from '../../../../shared/inquiry-form-customer';
import environment from 'environment';

const NotSaleInfoContent = () => {    
    return(        
        <section className="section-home-improvement-form">            
            <div className="row paragraph">                     
                <p className="title-customers brandon-bold text-120 mt-40">Please complete the form below. </p>
            </div>   
            <div className="inquiry-form row mt-20 mb-20">                    
                <NotSaleInforForm     
                    form="inquiry.consumer.doNotSellInfo"                                       
                    email={environment.communications.doNotSellMyInformation}                            
                    paragraph="none" 
                    disclaimerTextColor="text-white"
                    closeButton="false"                       
                />                              
            </div>                
        </section>        
    )    
}

export default NotSaleInfoContent;
