import React from 'react';
import GivePartner from './components/givepartner';

const GivePower = () => {
    return (
        <>            
            <GivePartner />
        </>
    )
}

export default GivePower;