import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import smiley from 'images/smiley-text.svg';
import backgroundImage from 'images/direct-pay/solar-houses.png';
import backgroundImageM from 'images/direct-pay/solar-housesM@2x.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '110px',
        fontFamily: 'brandon-grotesque',
        [theme.breakpoints.down('sm')]: {
            marginTop: '65px'
        }
    },
    image: {
        // backgroundImage: `url(${backgroundImage})`,
        background: `linear-gradient(rgba(20,20,20, .3), rgba(20,20,20, .3)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
        height: '345px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `linear-gradient(rgba(20,20,20, .3), rgba(20,20,20, .3)), url(${backgroundImageM})`,
            height: '170px',
            marginBottom: 0
        },
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `linear-gradient(rgba(20,20,20, .3), rgba(20,20,20, .3)), url(${backgroundImageM})`,
            height: '345px',
            marginBottom: 0
        },
        [theme.breakpoints.up('md')]: {
            background: `linear-gradient(rgba(20,20,20, .3), rgba(20,20,20, .3)), url(${backgroundImage})`,
            height: '345px',
            marginBottom: '32px'
        }
    },
    intro: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    borderLeft: {
        width: '20%',
        borderBottom: '3px solid #ffffff',
        position: 'relative',
        top: '-19px',
        left: '22px',
        [theme.breakpoints.down('sm')]: {
            width: '0%',
            borderBottom: '1px solid transparent'
        }
    },
    borderRight: {
        width: '20%',
        borderBottom: '3px solid #ffffff',
        position: 'relative',
        top: '-19px',
        left: '-22px',
        [theme.breakpoints.down('sm')]: {
            width: '0%',
            borderBottom: '1px solid transparent'
        }
    },
    text: {
        fontSize: '130%',
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    introText: {
        font: 'normal normal 300 45px/40px brandon-grotesque',
        wordSpacing: '2px',
        textAlign: 'center',
        letterSpacing: '.5px',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal normal 20px/29px brandon-grotesque',
            borderBottom: '1px solid #ffffff',
            marginBottom: '10px'
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal normal 32px/36px brandon-grotesque',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal normal 45px/40px brandon-grotesque',
        }
    },
    smileyText: {
        height: 'auto',
        width: '70px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50px',
        },
        [theme.breakpoints.up('md')]: {
            width: '50px',
        }
    },
    space: {
        color: '#ffffff',
        font: 'normal normal normal 70px/80px brandon-grotesque',
        padding: '15px',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 42px/40px brandon-grotesque',
            padding: 0
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 70px/80px brandon-grotesque',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal normal 70px/80px brandon-grotesque',
        }
    },
    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));


const UnderHeader = ({...props}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.image}>
                <div className={classes.text}>
                    <div className={classes.intro}><div className={classes.borderLeft}>&nbsp;</div><div className={classes.introText}>INTRODUCING THE</div><div className={classes.borderRight}>&nbsp;</div></div>
                    <div className={classes.mobile}>
                        <div className={classes.space}>DIRECT PAY</div>
                        <div className={classes.space}>PR<span className="smiley"><img src={smiley} onError={(e) => e.target.style.display='none' } alt="O" className={classes.smileyText}/></span>GRAM</div>
                    </div>
                    <div className={classes.desktop}>
                        <h1 className={classes.space}>DIRECT PAY PR<span className="smiley"><img src={smiley} onError={(e) => e.target.style.display='none' } alt="O" className={classes.smileyText}/></span>GRAM</h1>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    )
}

export default UnderHeader;