import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/shared/header";
import Footer from "./components/shared/footer";
import Views from "./components/views";
import MobileMenu from "./components/shared/mobile-menu";
import CacheBuster from "./cache-buster";
import Sidebar from "react-sidebar";

import ScrollToTop from "./components/shared/utils/scroll-to-top";

// Global Styles
import "./styles/vendors/css/normalize.css";
import "./styles/vendors/css/grid.css";
import "./styles/vendors/css/ionicons.min.css";
import "./styles/vendors/css/animate.css";
import "./styles/vendors/css/better-simple-slideshow.css";
import "./styles/style.css";
import "./styles/queries.css";
import "./styles/queries-home.css";
import "./styles/new-strips-mobile.css";
import "./styles/queries-green-energy.css";
import "./styles/bios.css";
import "./styles/trek.css";

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  componentDidMount() {
    console.log("After mounting component");
  }
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Router>
              <ScrollToTop />
              <Sidebar
                sidebar={
                  <MobileMenu
                    closeSidebar={() => this.onSetSidebarOpen(false)}
                  />
                }
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                rootClassName="rootClass"
                contentClassName="contentClass"
                sidebarClassName="sidebarClass"
                overlayClassName="overlayClass"
                rootId="rootOfSidebar"
                styles={{
                  // root: {
                  //   maxWidth: '1920px',
                  //   margin: '0 auto'
                  // },
                  content: {
                    // overflowX: 'hidden',
                    // borderRight: '1px solid #cccccc',
                    // borderLeft: '1px solid #cccccc'
                    zIndex: 1,
                  },
                  sidebar: {
                    background: "#4F4F50",
                    zIndex: "1001",
                  },
                  overlay: {
                    zIndex: "1000",
                  },
                }}
                pullRight={true}
              >
                <Header openSidebar={() => this.onSetSidebarOpen(true)} />
                <Views {...{}} />
                <Footer />
              </Sidebar>
            </Router>
          );
        }}
      </CacheBuster>
    );
  }
}
export default AppRouter;
