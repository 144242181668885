import React from "react";
import numeral from "numeral";

class RateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: props.rate,
    };
    this.handleChangeRate = this.handleChangeRate.bind(this);
    this.sendRateBack = this.sendRateBack.bind(this);
  }
  componentDidMount() {
    this.setState({
      rate: numeral(this.state.rate).format("0.00%"),
    });
  }
  componentDidUp(nextProps) {
    if (this.props.rate !== nextProps.rate) {
      this.setState(
        {
          rate: numeral(nextProps.rate).format("0.00%"),
        },
        () => this.props.calculatePayment()
      );
    }
  }
  handleChangeRate(e) {
    this.setState(
      {
        rate: e.target.value,
      },
      () => this.sendRateBack()
    );
  }
  sendRateBack() {
    if (!isNaN(numeral(this.state.rate).value())) {
      this.props.onRateChange(numeral(this.state.rate).value());
    }
  }
  render() {
    return (
      <input
        id={this.props.id}
        className="input-box brandon-medium"
        type="text"
        value={this.state.rate}
        onChange={(e) => this.handleChangeRate(e)}
      />
    );
  }
}

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1000,
      max: 1000000,
      min: 0,
      loanAmount: 225000,
      homeValue: 250000,
      rate: 0.04,
      loanType: "30 years fixed",
      propertyTaxRate: 0.012,
      propertyTaxes: 0,
      pmi: 0,
      hoi: 800,
      pandi: 0,
      payment: 0,
      mortgageInsuranceRate: 0.0043733,
      chartData: {},
    };
    this.handleChangeLoanAmount = this.handleChangeLoanAmount.bind(this);
    this.handleChangeHomeValue = this.handleChangeHomeValue.bind(this);
    this.handleChangeRate = this.handleChangeRate.bind(this);
    this.handleChangePropertyTax = this.handleChangePropertyTax.bind(this);
    this.handleChangeHoi = this.handleChangeHoi.bind(this);
    this.handleCloseSliiider = this.handleCloseSliiider.bind(this);
    this.calculatePayment = this.calculatePayment.bind(this);
  }
  componentDidMount() {
    this.calculatePayment();
    //    $("input[type='text']").click(function () {
    //     $(this).select();
    //  });
  }
  drawChart(pandi, pt, hoi, pmi) {
    var data = {
      labels: ["Principal & Interest", "PMI", "HOI", "Property Taxes"],
      datasets: [
        {
          label: "Monthly Payment",
          data: [pandi, pmi, hoi, pt],
          backgroundColor: [
            "rgba(255, 119, 3, 1)",
            "rgba(160, 63, 54, 1)",
            "rgba(0, 126, 139, 1)",
            "rgba(246, 170, 46, 1)",
          ],
        },
      ],
    };
    this.setState({
      chartData: data,
    });
  }
  handleChangeLoanAmount(e) {
    e.preventDefault();
    var number = numeral(e.target.value);
    console.log("Loan Amount: ", number);
    this.setState(
      {
        loanAmount: number.value(),
      },
      () => this.calculatePayment()
    );
    console.log("New state DIRECTLY after setState:", this.state.loanAmount);
  }
  handleChangeHomeValue(e) {
    e.preventDefault();
    var number = numeral(e.target.value);
    this.setState(
      {
        homeValue: number.value(),
      },
      () => this.calculatePayment()
    );
  }
  handleChangeRate(value) {
    this.setState(
      {
        rate: value,
      },
      () => this.calculatePayment()
    );
  }
  handleChangePropertyTax(value) {
    this.setState(
      {
        propertyTaxRate: value,
      },
      () => this.calculatePayment()
    );
  }
  handleChangeHoi(e) {
    e.preventDefault();
    var number = numeral(e.target.value);
    this.setState(
      {
        hoi: number.value(),
      },
      () => this.calculatePayment()
    );
  }
  handleCloseSliiider() {
    // setTimeout(function () {
    //     $('body').css('top', -(document.documentElement.scrollTop) + 'px')
    //     .removeClass('noscroll');
    //   }, 500
    // );
  }
  calculatePayment() {
    // Payment =  Loan amount [rate(1+rate) term)]/[(1+rate) term-1]
    // var payment =  (this.state.loanAmount * (this.state.rate * (1 + this.state.rate)  * 30)) / (( 1 + this.state.rate) * (30-1));
    /*
            var princ = document.calc.loan.value;
            var term  = document.calc.months.value;
            var intr   = document.calc.rate.value / 1200;
            document.calc.pay.value = princ * intr / (1 - (Math.pow(1/(1 + intr), term)));
        */
    console.log("New state in ASYNC callback:", this.state.loanAmount);
    var ir = this.state.rate / 12;
    var pt = Math.round(
      (this.state.homeValue * this.state.propertyTaxRate) / 12
    );
    console.log("pt: ", pt);
    var pandi = Math.round(
      (this.state.loanAmount * ir) / (1 - Math.pow(1 / (1 + ir), 360))
    );
    console.log("loan amount: ", this.state.loanAmount);
    console.log("pandi: ", pandi);
    var hoi = Math.round(this.state.hoi / 12);
    console.log("hoi: ", hoi);
    // Mortgage insurance rate ?????
    var pmi = Math.round(
      (this.state.loanAmount * this.state.mortgageInsuranceRate) / 12
    );
    console.log("pmi: ", pmi);
    this.setState({
      pandi: Math.round(pandi),
      pt: Math.round(pt),
      payment: pandi + pt + hoi + pmi,
    });
    //console.log("CalculatePayment:state: ", this.state);
    //this.drawChart(pandi, pt, hoi, pmi);
  }
  render() {
    return (
      <div id="calculator" className="calculator">
        <div className="row">
          <h2 className="text-green mt-20 text-lowercase brandon-black">
            mortgage calculator
          </h2>
          <h3 className="text-grey mt-10 text-lowercase brandon-medium">
            want an estimate on what your monthly payments will be ?
          </h3>
          <div className="row slider-row">
            <div className="col span-2-of-3">
              <div className="form-group">
                <label
                  htmlFor="loanAmount"
                  className="brandon-medium text-lowercase"
                >
                  select loan amount:
                </label>
                <input
                  className="input-box brandon-medium"
                  type="text"
                  onChange={this.handleChangeLoanAmount}
                  value={numeral(this.state.loanAmount).format("$ 0,0[.]00")}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="homeValue"
                  className="brandon-medium text-lowercase"
                >
                  estimated home value:
                </label>
                <input
                  className="input-box brandon-medium"
                  type="text"
                  onChange={this.handleChangeHomeValue}
                  value={numeral(this.state.homeValue).format("$ 0,0[.]00")}
                />
              </div>
            </div>
            <div className="col span-1-of-3">
              <p className="payment-title">your monthly payment estimate is:</p>
              <p className="payment">
                {numeral(this.state.payment).format("$ 0,0")}
              </p>
            </div>
          </div>
          <div className="assumptions">
            <h3 className="brandon-medium mt-20 ml-20 text-left text-lowercase">
              assumptions
            </h3>
            <div className="row">
              <div className="col span-1-of-2">
                <label htmlFor="input1" className="form-label">
                  mortgage rate
                </label>
                <RateInput
                  id="input1"
                  rate={this.state.rate}
                  onRateChange={() => this.handleChangeRate()}
                />
              </div>
              <div className="col span-1-of-2">
                <label htmlFor="input4" className="form-label">
                  loan type
                </label>
                <input
                  id="input4"
                  className="input-box brandon-medium disable"
                  type="text"
                  value={"30 yr fixed"}
                  disabled={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col span-1-of-2">
                <label htmlFor="input2" className="form-label">
                  property tax
                </label>
                <RateInput
                  id="input2"
                  rate={this.state.propertyTaxRate}
                  onRateChange={this.handleChangePropertyTax}
                />
              </div>
              <div className="col span-1-of-2">
                <label htmlFor="input3" className="form-label">
                  homeowners insurance (annual)
                </label>
                <input
                  id="input3"
                  className="input-box brandon-medium"
                  type="text"
                  value={numeral(this.state.hoi).format("$ 0,0[.]00")}
                  onChange={this.handleChangeHoi}
                />
              </div>
            </div>
          </div>
        </div>
        <span
          onClick={this.handleCloseSliiider}
          className="sliiider-exit-calc exit-calc top-exit"
        >
          <i className="ion-close-round"></i>
        </span>
      </div>
    );
  }
}

export default Calculator;
