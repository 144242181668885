import chapter1Img from "images/warriors-of-light/page/wolchapter1.png";
import chapter2Img from "images/warriors-of-light/page/wolchapter2.png";
import chapter3Img from "images/warriors-of-light/page/prueba.png";
import chapter4Img from "images/warriors-of-light/page/wol-chapter4-image.jpg";

const data = {
  chapters: [
    {
      id: "1",
      title: "Game On",
      description:
        "On the frontlines of the solar revolution are some of the most driven individuals, dedicated to the cause of caring for the planet by helping people convert to a sustainable lifestyle.",
      picture: chapter1Img,
      pictureAlt:
        "On the frontlines of the solar revolution are some of the most driven individuals, dedicated to the cause of caring for the planet by helping people convert to a sustainable lifestyle.",
      videoLink: "https://www.youtube.com/watch?v=9SBW_qtJ6BQ",
    },
    {
      id: "2",
      title: "2 Kinds of People",
      description:
        "There are two kinds of people—those who can, and those who won’t. The climate crisis poses a real threat to all life on earth, but “can do” warriors are rising to the challenge.",
      picture: chapter2Img,
      pictureAlt:
        "There are two kinds of people—those who can, and those who won’t. The climate crisis poses a real threat to all life on earth, but “can do” warriors are rising to the challenge.",
      videoLink: "https://www.youtube.com/watch?v=qEOsnr9Tc8c",
    },
    {
      id: "3",
      title: "Rise",
      description:
        "Featuring some of the most inspirational women on the frontlines of the solar revolution. They lead by example, rise above convention, and remain motivated by the purpose of their hard work.",
      picture: chapter3Img,
      pictureAlt:
        "Featuring some of the most inspirational women on the frontlines of the solar revolution. They lead by example, rise above convention, and remain motivated by the purpose of their hard work.",
      videoLink: "https://www.youtube.com/watch?v=Fnnf_gBeLUo&t=1s",
    },
    {
      id: "4",
      title: "Drive",
      description:
        "Honoring the extraordinary salespeople throughout the solar industry—enthusiastic men and women driven by a higher purpose to push the clean energy movement forward.",
      picture: chapter4Img,
      pictureAlt:
        "Honoring the extraordinary salespeople throughout the solar industry—enthusiastic men and women driven by a higher purpose to push the clean energy movement forward.",
      videoLink: "https://www.youtube.com/watch?v=VsBmrKmGvw4",
    },
  ],
};

export default data;
