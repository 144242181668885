import React from 'react';

import HeaderContactUs from './components/header';
import Addresses from './components/addresses';
import Head from '../../shared/head';

const ContactUs = () => {                     
    return(
        <>        
            <Head title={`Loanpal ${window.location.pathname.split('/')[1]}`} />    
            <HeaderContactUs />  
            <Addresses />                   
        </>                
    )
}

export default ContactUs;