import React from 'react';
import Modal from '../../shared/modal-form';
import useModal from '../../shared/modal-form/useModal.js'
import environment from 'environment';

const DIYSlideshow = (props) => {
    const { isShowing, toggle } = useModal();     
    return (      
    <>    
        <div className="bss-slides diy-slideshow" onClick={toggle}>
                <figure>                             
                <div className={`slide img-container-solar-power-superheroes img-container-green-energy-slideshow ${props.border} ${props.bgColor}`}>	                    
                    <div className="clearfix"></div>
                </div>                            
            </figure>             

            <figure>            
                <div className={`slide img-container-transparency img-container-green-energy-slideshow ${props.border} ${props.bgColor}`}>	                     
                    <div className="clearfix"></div>
                </div>            
            </figure> 
            
            <figure>            
                <div className={`slide img-container-its-time-to-fly img-container-green-energy-slideshow ${props.border} ${props.bgColor}`}>
                    <div className="clearfix"></div>
                </div>            
            </figure>

            <figure>            
                <div className={`slide img-container-red-tape-underwriting img-container-green-energy-slideshow ${props.border} ${props.bgColor}`}>
                    <div className="clearfix"></div>
                </div>            
            </figure>            

            <figure>            
                <div className={`slide img-container-keep-your-sales-team-happy img-container-green-energy-slideshow ${props.border} ${props.bgColor}`}>
                    <div className="clearfix"></div>
                </div>            
            </figure>        
        </div>
        <Modal                   
            form="inquiry.partner"
            email={environment.communications.partnerInquiry}
            isShowing={isShowing}
            hide={toggle}            
            />  
    </>
    )
}


export default DIYSlideshow;