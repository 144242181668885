import React from 'react';
import data from './data';
import Article from './article';

const Articles = () => {
    const { articles } = data;   
    return (
        <section className="section-boxes">
            <Article {...articles[0]}  />
            <Article {...articles[1]}  />
            <Article {...articles[2]}  />
            <Article {...articles[3]}  />
            <Article {...articles[4]}  />
            <Article {...articles[5]}  />
            <Article {...articles[6]}  />
        </section>
    );
}

export default Articles;