import React, { useState } from 'react';
import useModal from '../../../shared/modal-form/useModal';
import Modal from '../../../shared/modal-form';

const PartnersAndCustomers = () => {    
    const { isShowing, toggle } = useModal(); 
    const [ form, setForm] = useState('inquiry.partner');
    const { pathname } = window.location;

    const handleToggle = (formType) => {
        setForm(formType);
        toggle();
    }    

    return(            
        <section className="section-green-energy">            
            <div className="row">                
                <div className="card text-center">
                    <div className="card-body a">                        
                        <div className="card-text">
                            <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/green-energy/handshakeIcon.png" alt="Solar Providers" />
                            <div className="card-title mt-10 mb-10">SOLAR PROVIDERS</div>
                            <p className="card-text-description text-left mt-10">come see how Loanpal is making solar financing faster, simpler, and friendlier than ever. grow your business quickly with our lending options.</p>                                                        
                            <div className="row mt-10 mb-10">
                                <button className="btn btn-full uppercase" onClick={() => handleToggle('inquiry.partner')}>Become a partner</button>                
                            </div>                         
                        </div>    
                    </div>
                    <div className="card-body b">                            
                            <div className="card-text">
                                <img src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/loanpal.com/green-energy/solarHouse.png" alt="Solar Customers" />
                                <div className="card-title mt-10 mb-10">SOLAR CUSTOMERS</div>
                                <p className="card-text-description text-left mt-10">put $0 down on a new solar system from a top-rated solar company. apply in minutes, get approved in 10 sec­onds or less!</p>                                                                
                                <div className="row mt-10 mb-10">
                                    <button className="btn btn-full uppercase" onClick={() => handleToggle('inquiry.solar')}>Get Started</button>                
                                </div>                                
                            </div>    
                        </div>
                </div>                                    
            </div>    
            <div className="clearfix"></div>
            <Modal           
                form={form}       
                pathname={pathname}
                isShowing={isShowing}
                hide={toggle}       
            />
        </section>        
    )    
}

export default PartnersAndCustomers;
