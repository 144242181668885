import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import messages_en from './lib/messages.en';
import messages_es from './lib/messages.es';


const tagManagerArgs = {
  gtmId: 'UA-113302285-1'
}
TagManager.initialize(tagManagerArgs)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00838F',
    },
    secondary: {
      main: '#707070',
    },
    text: {
      primary: '#717271',
      secondary: '#ffffff'
    }
  },
  typography: {
    fontFamiy: 'brandon-grotesque, sans-serif'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1440,
      lg:  1640,
      xl: 1920,
    },
  },
});

const loadTranslation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = urlParams.has("locale")
      ? urlParams.get("locale")
      : navigator.language;
    const messages = {
        'en-US': messages_en,
        'es-ES': messages_es
    };

    return { locale, messages: messages[locale] };
  };

  const { locale, messages } = loadTranslation();

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <IntlProvider locale={locale} messages={messages}>
          <App />
      </IntlProvider>
    </ThemeProvider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
